import './App.css';
import Home from './views/Home.js'
import Individual from './views/Individual.js'
import '@fontsource/roboto';
import Setup from './views/Setup'
import Feed from './views/Feed'

import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";


function App() {
  return (
    <Router>
      <Switch>
        <Route path={`${process.env.PUBLIC_URL}/scoreboard/:id/:phisical_id/:r`} >
          <Setup t={"scoreboard"}/>
        </Route>
        <Route path={`${process.env.PUBLIC_URL}/feed/:id/:r/:p`} >
          <Feed/>
        </Route>
        <Route path={`${process.env.PUBLIC_URL}/control/:id/:r/:p`} >
          <Feed control={true}/>
        </Route>
        <Route path={`${process.env.PUBLIC_URL}/bestpoint/:id/:r/:p`} >
          <Feed control={true} best_pont={true}/>
        </Route>
        <Route exact path={`${process.env.PUBLIC_URL}`}>
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
