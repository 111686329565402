

import { Collapse, Zoom, Fade, Grow, Slide } from '@material-ui/core';
import { ReactComponent as TShirt } from '../../statics/images/t-shirt.svg';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { makeStyles } from '@material-ui/core/styles';

import ExtendedNameFild from './ExtendedNameFild';


function Type2Scoreboard(props) {
    const localClasses = useStyles(props.config.theme);

    let cardBackgroundColor = {
        0: "",
        1: "#FFC300",
        2: "#ff0000",
        3: "#ff0000",
    }
    let cardText = {
        0: "",
        1: "Y",
        2: "1",
        3: "2",
    }

    return (
        <>
            <Zoom in={(!props.config.innactive) && (props.config.gameStatusVisible || props.config.teamStatusVisible) && props.config.gameStatus !==  null} >
                <div className={props.config.styleClass.container}> 
                    <div className={localClasses.dataContainer}> 
                        {props.config.teamStatusVisible && props.config.gameStatusVisible &&
                            <Slide direction="right" in={props.config.teamStatusVisible }>
                                <div className={localClasses.rowContainer} > 
                                    <div className={localClasses.teamDataDiv} >
                                        <div className={localClasses.teamNameDiv + ' ' + localClasses.teamNameLeft} style={{
                                            borderTopLeftRadius: props.config.theme.borderRadius
                                            }}>
                                            <ExtendedNameFild name={props.config.teamAName} fontSize={props.config.theme.fontSize}/>
                                        </div> 
                                        <div className={localClasses.teamResultDiv} >
                                            {props.config.teamPointA}
                                        </div> 
                                    </div>
                                    <div className={localClasses.teamDataDiv} >
                                        <div className={localClasses.teamResultDiv} style={{marginLeft:4}}>
                                            {props.config.teamPointB}
                                        </div> 
                                        <div className={localClasses.teamNameDiv + ' ' + localClasses.teamNameRight} style={{
                                            borderTopRightRadius: props.config.theme.borderRadius
                                            }}>
                                            <ExtendedNameFild name={props.config.teamBName} fontSize={props.config.theme.fontSize}/>
                                        </div> 
                                    </div>
                                </div>
                            </Slide>
                        }
                        {props.config.gameStatusVisible &&
                            <div className={props.config.styleClass.nameContainer}>  
                                {props.config.scoreboardBackVisible && 
                                    <div className={props.config.styleClass.columnContainer}> 
                                        <object data={props.config.backImageUrl} className={props.config.styleClass.scoreboardBackImg} />
                                    </div>
                                }
                                {props.config.showTshirt && 
                                    <div className={props.config.styleClass.columnContainer}> 
                                        <div className={props.config.styleClass.nameItemDiv}>
                                            <div className={props.config.styleClass.tshirtDiv} style={{backgroundColor: props.config.teamAColor}}></div>
                                        </div>
                                        <div className={props.config.styleClass.nameItemDiv}>  
                                            <div className={props.config.styleClass.tshirtDiv} style={{backgroundColor: props.config.teamBColor}}></div>
                                        </div> 
                                    </div>
                                }

                                <div className={props.config.styleClass.columnContainer} style={{flexGrow: 1}}> 
                                    <div className={props.config.styleClass.nameItemDiv}>
                                        <div className={props.config.styleClass.oddNameDiv + ' ' + (props.config.teamStatusVisible?localClasses.playerNameWithTeamDiv:localClasses.playerNameDiv)} >
                                            <ExtendedNameFild name={props.config.playerA} fontSize={props.config.theme.fontSize}/>
                                        </div> 
                                    </div>
                                    <div className={props.config.styleClass.nameItemDiv}>  
                                        <div className={props.config.styleClass.oddNameDiv + ' ' + (props.config.teamStatusVisible?localClasses.playerNameWithTeamDiv:localClasses.playerNameDiv)} >
                                            <ExtendedNameFild name={props.config.playerB} fontSize={props.config.theme.fontSize}/>
                                        </div> 
                                    </div> 
                                </div>
                                {props.config.showServe &&
                                    <Slide direction="up" in={props.config.showServe }>
                                        <div className={props.config.styleClass.columnContainer} > 
                                            <div className={props.config.styleClass.nameItemDiv}>
                                                <div className={localClasses.serveDiv} >
                                                    {props.config.playerAServe? <ArrowLeftIcon fontSize='inherit'/>: ' '}
                                                </div> 
                                            </div>
                                            <div className={props.config.styleClass.nameItemDiv}>
                                                <div className={localClasses.serveDiv} >
                                                    {(!props.config.playerAServe)? <ArrowLeftIcon fontSize='inherit'/>: " "}
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                }
                                <div className={props.config.styleClass.columnContainer} > 
                                    <div className={props.config.styleClass.nameItemDiv}>
                                        <div className={props.config.styleClass.evenNameDiv + ' ' + localClasses.pointResultDiv}  >
                                            {props.config.gamePointA}
                                        </div> 
                                    </div>
                                    <div className={props.config.styleClass.nameItemDiv}>  
                                        <div className={props.config.styleClass.evenNameDiv + ' ' + localClasses.pointResultDiv} >
                                            {props.config.gamePointB}
                                        </div> 
                                    </div> 
                                </div>
                                <div className={props.config.styleClass.columnContainer} > 
                                    <div className={props.config.styleClass.nameItemDiv}>
                                        <div className={props.config.styleClass.oddNameDiv + ' ' + localClasses.pointResultDiv} >
                                            {props.config.pointA}
                                        </div> 
                                    </div>
                                    <div className={props.config.styleClass.nameItemDiv}>  
                                        <div className={props.config.styleClass.oddNameDiv + ' ' + localClasses.pointResultDiv} >
                                            {props.config.pointB}
                                        </div> 
                                    </div> 
                                </div>
                                <div className={props.config.styleClass.columnContainer} > 
                                    <div className={props.config.styleClass.nameItemDiv} style={{display:"flex", flexDirection:"row"}}>
                                        {props.config.playerATimeout && 
                                            <Zoom in={props.config.playerATimeout }>
                                                <div className={localClasses.timeoutDiv} >
                                                    {"T"}
                                                </div>
                                            </Zoom>
                                        } 
                                        {props.config.playerACards>0 && 
                                            <Zoom in={props.config.playerACards>0 }>
                                                <div className={localClasses.timeoutDiv} 
                                                    style={{backgroundColor:cardBackgroundColor[props.config.playerACards]}}>
                                                    {cardText[props.config.playerACards]}
                                                </div>
                                            </Zoom>
                                        } 
                                    </div>
                                    <div className={props.config.styleClass.nameItemDiv} style={{display:"flex", flexDirection:"row"}}>  
                                        {props.config.playerBTimeout && 
                                            <Zoom in={props.config.playerBTimeout }>
                                                <div className={localClasses.timeoutDiv} >
                                                    {"T"}
                                                </div>
                                            </Zoom>
                                        }
                                        {props.config.playerBCards>0 && 
                                            <Zoom in={props.config.playerBCards>0 }>
                                                <div className={localClasses.timeoutDiv} 
                                                    style={{backgroundColor:cardBackgroundColor[props.config.playerBCards]}}>
                                                    {cardText[props.config.playerBCards]}
                                                </div>
                                            </Zoom>
                                        } 
                                    </div> 
                                </div>
                            </div>
                        }
                        {!props.config.gameStatusVisible && props.config.teamStatusVisible &&
                            <div className={props.config.styleClass.nameContainer}>  
                                {props.config.scoreboardBackVisible && 
                                    <div className={props.config.styleClass.columnContainer}> 
                                        <object data={props.config.backImageUrl} className={props.config.styleClass.scoreboardBackImg} />
                                    </div>
                                }
                                {props.config.showTshirt && 
                                    <div className={props.config.styleClass.columnContainer}> 
                                        <div className={props.config.styleClass.nameItemDiv}>
                                            <div className={props.config.styleClass.tshirtDiv} style={{backgroundColor: props.config.teamAColor}}></div>
                                        </div>
                                        <div className={props.config.styleClass.nameItemDiv}>  
                                            <div className={props.config.styleClass.tshirtDiv} style={{backgroundColor: props.config.teamBColor}}></div>
                                        </div> 
                                    </div>
                                }

                                <div className={props.config.styleClass.columnContainer} style={{flexGrow: 1}}> 
                                    <div className={props.config.styleClass.nameItemDiv}>
                                        <div className={props.config.styleClass.oddNameDiv} style={{paddingLeft: "20px", paddingRight: "20px"}}>
                                            <ExtendedNameFild name={props.config.teamAName} fontSize={props.config.theme.fontSize}/>
                                        </div> 
                                    </div>
                                    <div className={props.config.styleClass.nameItemDiv}>  
                                        <div className={props.config.styleClass.oddNameDiv} style={{paddingLeft: "20px", paddingRight: "20px"}}>
                                            <ExtendedNameFild name={props.config.teamBName} fontSize={props.config.theme.fontSize}/>
                                        </div> 
                                    </div> 
                                </div>
                                <div className={props.config.styleClass.columnContainer} > 
                                    <div className={props.config.styleClass.nameItemDiv}>
                                        <div className={props.config.styleClass.evenNameDiv + ' ' + localClasses.pointResultDiv}  >
                                            {props.config.teamPointA}
                                        </div> 
                                    </div>
                                    <div className={props.config.styleClass.nameItemDiv}>  
                                        <div className={props.config.styleClass.evenNameDiv + ' ' + localClasses.pointResultDiv} >
                                            {props.config.teamPointB}
                                        </div> 
                                    </div> 
                                </div>
                            </div>
                        }
                    </div>
                    {(props.config.competitionStageVisible || props.config.specialMomentVisible) &&
                        <div className={localClasses.stageContainer} >    
                            {props.config.competitionStageVisible && props.config.stageStatus!=="" &&
                                <div className={props.config.styleClass.stageDiv}
                                    style={{
                                        marginLeft: props.config.showTshirt?props.config.theme.textFontSize-props.config.theme.borderRadius:0,
                                    }}
                                > 
                                    {props.config.stageStatus}
                                </div>
                            }
                            {props.config.specialMomentVisible && props.config.specialMoment!=="" &&
                                <Zoom in={props.config.specialMomentVisible && props.config.specialMoment!==""}>
                                    <div className={props.config.styleClass.stageDiv}
                                        style={{
                                            marginLeft: props.config.theme.textFontSize,
                                        }}
                                    > 
                                        {props.config.specialMoment}
                                    </div>
                                </Zoom>
                            }
                        </div>
                    }
                </div>
            </Zoom>
        </>
    )
}

export default Type2Scoreboard;

const useStyles = makeStyles((theme) => ({
    dataContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    rowContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        marginRight: (props) => props.borderRadius,
    },

    teamDataDiv: {
        display: "flex",
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "0",
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "stretch",
       
    },

    teamNameDiv: {
        color: (props) => props.primaryColor, //'#111111', //'#003f72',
        backgroundColor: (props) => props.secondaryColor, //'#bbbbbb', //'#dae5ed',

        flexGrow: 1,
        //minWidth: (props) => props.textFontSize*9,
        maxWidth:(props) => props.textFontSize*9, 

        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        
        textTransform: 'uppercase',
        fontSize: (props) => props.textFontSize * 0.8,
        marginTop: (props) => props.verticalMargin*3,
        paddingLeft: (props) => props.textFontSize/2,
        paddingRight: (props) => props.textFontSize/2,
        paddingTop: (props) => props.textFontSize/4,
        paddingBottom: (props) => props.textFontSize/6,
    },
    teamNameLeft: {
        textAlign: 'right',
    },
    teamNameRight: {
        textAlign: 'left',
    },
    teamResultDiv: {
        backgroundColor: (props) => props.primaryColor, //'#111111', //'#003f72',
        color: (props) => props.secondaryColor, //'#bbbbbb', //'#dae5ed',

        textTransform: 'uppercase',
        fontSize: (props) => props.textFontSize,
        fontWeight: 'bold',
        
        borderTopLeftRadius: (props) => props.borderRadius,
        borderTopRightRadius: (props) => props.borderRadius,
        paddingLeft: (props) => props.textFontSize/2,
        paddingRight: (props) => props.textFontSize/2,
        paddingTop: (props) => props.textFontSize/4,
        paddingBottom: (props) => props.textFontSize/4,
        marginTop: (props) => props.verticalMargin,
    },
    pointResultDiv: {
        width: (props) => props.textFontSize*2,
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: (props) => props.borderRadius,
        textAlign: 'center',
    },
    serveDiv: {
        backgroundColor: (props) => props.primaryColor, //'#111111', //'#003f72',
        color: (props) => props.secondaryColor, //'#bbbbbb', //'#dae5ed',
        fontSize: (props) => props.textFontSize*1.75,
        fontWeight: 'bold',
        paddingTop: (props) => props.textFontSize/32,
        paddingBottom: (props) => props.textFontSize/4-props.textFontSize/32,
        width: (props) => props.textFontSize*1.25,
        height: (props) => props.textFontSize*1.75-props.textFontSize/4,
        marginRight: 0, 
    },
    playerNameWithTeamDiv:{
        minWidth: (props) => props.textFontSize*16,
        paddingLeft: (props) => props.textFontSize/2  + props.borderRadius,
        paddingRight: (props) => props.textFontSize/2 + props.borderRadius, 
    },
    playerNameDiv:{
        paddingLeft: (props) => props.textFontSize/2  + props.borderRadius,
        paddingRight: (props) => props.textFontSize/2  + props.borderRadius, 
    },
    timeoutDiv: {
        backgroundColor: (props) => props.secondaryColor, //'#bbbbbb', //'#dae5ed',
        color: (props) => (props) => props.primaryColor, //'#111111', //'#003f72',
        fontSize: (props) => props.textFontSize*0.8,
        height: (props) => props.textFontSize*1.1,
        width: (props) => props.textFontSize*1.2,
        paddingTop: (props) => props.textFontSize/16,
        paddingBottom: (props) => props.textFontSize/16,
        textAlign: 'center',
        marginLeft: (props) => props.borderRadius,
    },

    stageContainer:{
        display: "flex",
        flexDirection: "row",
        alignItems : "flex-start",
        float: 'left',
        justifyContent: 'space-between',
    }
    
}))
