import React, { useContext } from 'react';
import '@fontsource/roboto';
import { GameContext } from '../entities/GameContext'
import TeamScoreboardOngoing from './TeamScoreboardOngoing';
import TeamScoreboardSetup from './TeamScoreboardSetup';

function TeamScoreboard() {
    const { started } = useContext(GameContext);

    console.log("Team game started:", started)
    
    if (started) {
        return (<TeamScoreboardOngoing />);
    }else{
        return (<TeamScoreboardSetup />);
    }
    
}

export default TeamScoreboard;
