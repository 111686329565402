import React, {useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Chip, Avatar, Button} from '@material-ui/core';


import '@fontsource/roboto';
import PlayerNames from './PlayerNames';
import ExtendedNameFild from './ExtendedNameFild';
import PlayerStats from './PlayerStats';

function computeOnePoint(pointTracking, lastPoint, windowSize) {
    let pIndex = lastPoint

    let lastA = 0
    let lastCount = 0
    let winInServeA = 0
    let winInServeB = 0
    let lostInServeA = 0
    let lostInServeB = 0

    let i = 0

    while(pIndex >= 0) {
        let p = pointTracking[pIndex]

        lastA += (p.playerAWin?1:0)
        lastCount ++

        if (p.serving) {
            if (p.playerAWin) {
                winInServeA += 1
            }else{
                winInServeB += 1
            }
        }else{
            if (p.playerAWin) {
                lostInServeB += 1
            }else{
                lostInServeA += 1
            }
        }

        pIndex--
        i++
        if (i>= windowSize) {
            break
        }
    }

    if (lastCount > 0) {
        lastA = lastA * 100.0 / lastCount
    }
    if ((winInServeA + lostInServeA) > 0) {
        winInServeA = winInServeA * 100.0 / (winInServeA + lostInServeA)
    }
    if ((winInServeB + lostInServeB) > 0) { 
        winInServeB = winInServeB * 100.0 / (winInServeB + lostInServeB)
    }
    return {
            lastA: lastCount>0?lastA.toFixed(1):0, 
            lastB: lastCount>0?(100.0-lastA).toFixed(1):0,
            winInServeA: winInServeA==0?0:winInServeA.toFixed(1), 
            winInServeB: winInServeB==0?0:winInServeB.toFixed(1)
        }

    
}


function computeEvolutionStats(pointTracking) {
    let i = 0
    var evolution = []
    let match = -1
    
    console.log("Compute evolution stats:", pointTracking)
    while (i < pointTracking.length) {
        let lastPoint = pointTracking.length - 1 - i
        if (match < 1) {
            match = pointTracking[lastPoint].match
        }else{
            if (match != pointTracking[lastPoint].match) {
                break
            }
        }
        let window = 12
        let p = computeOnePoint(pointTracking, lastPoint, window)
        evolution.unshift({
            track: pointTracking[lastPoint],
            stats: p
        })
        i++
        if (i>11){
            break
        }

    }
    return evolution
}

function ControlPlayerStatsView(props) {
    let fullWidth = 1920
    let fullHeight = 1080

    console.log("config:", props.config)

    var theme = {
        "width": fullWidth,
        "height": fullHeight,
        "teamMarginLeft": parseInt(fullWidth / 14 * 4),
        "teamMarginTop": parseInt(fullHeight/16*8.25),
        "teamContentWidth": parseInt(fullWidth / 14 * 6),
        "columnWidth": parseInt(fullWidth / 14),
        "topPlayerMarginTop": parseInt(fullHeight/16*3),
        "contentMarginTop": fullHeight/16*4.5,
        "contentMarginLeft": parseInt(fullWidth / 14 * 2.5),
        "contentWidth": parseInt(fullWidth / 14 * 9),
        "contentHeight": fullHeight/16*8,
        "rowHeight": fullHeight/16,
        "playerAvatarRowHeight": fullHeight/16*6,
        "playerAvatarRowWidth": parseInt(fullWidth / 14 * 12),
        "playerResultRowHeight": fullHeight/16*7/props.matchNumber,
        "borderDebug": props.config["border"]?"1px solid #000000":"0px solid #000000",
        "config": props.config,
        "backgroundColor": props.config["backgroundColor"],
        "resultColor": props.config["resultColor"],
    }
    const classes = useStyles(theme);

    var gameId = props.currentMatch * 10 + props.currentGame

    console.log("PlayerView props:", props)

    if (Object.keys(props.playerAName).length === 0){
        return (
            <div>

            </div>
        )
    }

    let evolutionStats = computeEvolutionStats(props.status.pointTracking || [])

    let playerAUrl = props.imageUrl+ "/player_a_logo_"+props.currentMatch+"?random="+props.seed
    let playerBUrl = props.imageUrl+ "/player_b_logo_"+props.currentMatch+"?random="+props.seed

    return (
        <div className={classes.pageContent}>  
            {true && <div className={classes.backGround}>
                <img className={classes.backgroundImg} src={props.imageUrl+ "/background_image?random="+props.seed}/>
            </div>}  

            <div className={classes.playerGeneralResultRow}>
                <div className={classes.topTitle}>
                    The last 12-points statistics
                </div>
            </div>

            <div className={classes.teamResultRow}>
                <div className={classes.teamAvatarDiv}>
                    <Button
                        color="secondary"
                        component="label"
                        >
                        <Avatar
                            alt={props.teamAName}
                            src={props.imageUrl+ "/team_a_logo?random="+props.seed}
                            className={classes.teamAvatar}
                        />
                        <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "team_a_logo"); e.target.value=''} } />
                    </Button>  
                </div> 
                <div className={classes.teamAvatarDiv}>
                    <Button
                        color="secondary"
                        component="label"
                        >
                        <Avatar
                            alt={props.teamBName}
                            src={props.imageUrl+ "/team_b_logo?random="+props.seed}
                            className={classes.teamAvatar}
                        />
                        <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "team_b_logo"); e.target.value=''} } />
                    </Button>  
                </div> 
            </div>

            <div className={classes.playerAvatarRow} >
                <div className={classes.playerBigAvatarAndName} style={{justifyContent: "center"}}>
                    <div className={classes.topPlayerAName}>
                        <ExtendedNameFild name={props.playerAName[props.currentMatch]} fontSize={theme.rowHeight * 0.5}/>
                    </div>
                    <div className={classes.playerBigAvatarDiv}>
                        <Button
                            color="secondary"
                            component="label"
                            >
                            <Avatar
                                alt={props.playerAName[props.currentMatch]}
                                src={playerAUrl}
                                className={classes.playerBigAvatar}
                            />
                            <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "player_a_logo_"+props.currentMatch); e.target.value=''} } />
                        </Button>  
                    </div> 
                    <div className={classes.bottomPlayerResult}>
                        {"" + props.matchSummary[props.currentMatch][0]  }
                    </div>
                </div>
                <div className={classes.playerBigAvatarAndName} style={{justifyContent: "center"}}>
                    <div className={classes.topPlayerBName}>
                        <ExtendedNameFild name={props.playerBName[props.currentMatch]} fontSize={theme.rowHeight * 0.5}/>
                    </div>
                    <div className={classes.playerBigAvatarDiv}>
                        <Button
                            color="secondary"
                            component="label"
                            >
                            <Avatar
                                alt={props.playerBName[props.currentMatch]}
                                src={playerBUrl}
                                className={classes.playerBigAvatar}
                            />
                            <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "player_b_logo_"+props.currentMatch); e.target.value=''} } />
                        </Button>  
                    </div>
                    <div className={classes.bottomPlayerResult}>
                        {"" + props.matchSummary[props.currentMatch][1] }
                    </div>
                </div>
            </div>


            <div className={classes.resultContent}>
                <PlayerStats  
                    data={props.matches}
                    evolutionStats={evolutionStats}
                    width={theme.contentWidth*2/3}
                    centerWidth={theme.columnWidth}
                    height={theme.contentHeight}/>
            </div>
        </div>
    )
}

export default ControlPlayerStatsView;


const useStyles = makeStyles({
    pageContent: props => ({
        //margin: "0px",
        position: "relative",
        //backgroundSize: 'contain',
        //backgroundImage: 'url("https://api.kandolab.com/api/v2/cameras/6dee49ec-4b10-4029-97a4-7da47a21e67b/layer/1?preview=true&filename=c9l0t1665854829_all_patrocinadores.gif")',
        width:  props.width,
        height: props.height, 
    }),
    backGround: props => ({
        position: "absolute",
        left: 0,
        top: 0,
        width: props.width,
        height: props.height 
    }),
    backgroundImg: props => ({
        width: props.width,
        height: props.height,
        display: props.config["backgroundImage"]?"block":"none",
    }),
    playerAvatarRow: props => ({
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        left: (props.width/2 - props.playerAvatarRowWidth/2),
        top: (props.height/2 - props.playerAvatarRowHeight/2),
        width: props.playerAvatarRowWidth,
        height: props.playerAvatarRowHeight,
        border: props.borderDebug,
        justifyContent: "space-between",
        alignItems: "center",
        //backgroundColor: props.config["backgroundFilled"]?props.backgroundColor:"transparent"
    }),
    resultContent: props => ({
        position: "absolute",
        left: props.contentMarginLeft,
        top: props.contentMarginTop,
        width: props.contentWidth,
        height: props.contentHeight,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor: props.config["backgroundFilled"]?props.backgroundColor:"transparent"
    }),
    resultContentRow: props => ({
        display: "flex",
        flexDirection: "row",
        height: props.playerResultRowHeight,
        border: props.borderDebug,
        alignItems: "center",

        backgroundColor: props.config["backgroundFilled"]?props.backgroundColor:"transparent"
    }),
    playerAvatarDiv: props => ({
        width: props.rowHeight,
        height: props.rowHeight,
        marginRight: props.rowHeight/5,
        marginLeft: props.rowHeight/5,
        display: props.config["playerPhoto"]?"block":"none",
    }),
    playerAvatar: props => ({
        width: props.rowHeight*5/6,
        height: props.rowHeight*5/6,
    }),
    playerAvatarAndName: props => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: props.columnWidth * 3.5,
    }),
    playerName: props => ({
        fontFamily: "Arial, Helvetica, sans-serif",
        marginLeft: props.rowHeight/5,
        fontSize: props.rowHeight * 0.4,
        width: props.columnWidth * 2.5,
        fontWeight: "bold",
        textTransform: 'uppercase',
        color: "#ffffff",
        display: props.config["playerName"]?"block":"none",
    }),
    playerResult: props => ({
        width: props.columnWidth*2,
    }),
    playerResultHighline: props => ({
        textAlign: "center",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontWeight: "bold",
        fontSize: props.rowHeight * 0.5,
        width: props.columnWidth,
        marginLeft: props.columnWidth/2,
        marginRight: props.columnWidth/2,
        backgroundColor: props.config["backgroundFilled"]?props.resultColor:"transparent"
    }),
    playerGeneralResultRow: props => ({
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        left: props.contentMarginLeft,
        top: props.topPlayerMarginTop,
        textAlign: "center",
        
        justifyContent: "center",
        alignItems: "center",
        height: props.rowHeight * 1.0,
        width: props.contentWidth,
        border: props.borderDebug,
    }),


    topTitle: props => ({
        textAlign: "center",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontWeight: "bold",
        fontSize: props.rowHeight * 0.6,
        //width: props.columnWidth*6,
        paddingLeft: props.columnWidth/2,
        paddingRight: props.columnWidth/2,
        paddingTop: props.rowHeight * 0.1,
        paddingBottom: props.rowHeight * 0.1,
        backgroundColor: props.config["backgroundFilled"]?props.resultColor:"transparent"
    }),
    
    playerBigAvatarAndName: props => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: props.columnWidth * 4,
        height: props.rowHeight * 1.5,
    }),
    topPlayerAName: props => ({
        textAlign: "left",
        fontFamily: "Arial, Helvetica, sans-serif",
        marginLeft: props.rowHeight/5,
        fontSize: props.rowHeight * 0.40,
        fontWeight: "bold",
        textTransform: 'uppercase',
        padding: "0.25em",
        color: "#000000",
        backgroundColor: "#ffffff",
        display: props.config["playerName"]?"block":"none",
    }),
    topPlayerBName: props => ({
        textAlign: "right",
        fontFamily: "Arial, Helvetica, sans-serif",
        marginRight: props.rowHeight/5,
        marginRight: props.rowHeight/10,
        fontSize: props.rowHeight * 0.4,
        fontWeight: "bold",
        color: "#000000",
        padding: "0.25em",
        backgroundColor: "#ffffff",
        textTransform: 'uppercase',
        display: props.config["playerName"]?"block":"none",
    }),
    playerBigAvatarDiv: props => ({
        width: props.rowHeight*6,
        height: props.rowHeight*6,
    }),
    playerBigAvatar: props => ({
        width: props.rowHeight*6*5/6,
        height: props.rowHeight*6*5/6,
        marginTop: "3px",
        backgroundColor: "#ffffff",
    }),

    teamResultRow: props => ({
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        left: props.teamMarginLeft,
        top: props.teamMarginTop,
        textAlign: "center",
        
        justifyContent: "space-between",
        alignItems: "center",
        height: props.rowHeight * 1.5,
        width: props.teamContentWidth,
        border: props.borderDebug,
    }),

    teamAvatarDiv: props => ({
        width: props.rowHeight*2,
        height: props.rowHeight*2,
        display: props.config["teamLogo"]?"block":"none",
    }),
    teamAvatar: props => ({
        width: props.rowHeight*2*5/6,
        height: props.rowHeight*2*5/6,
        marginTop: "3px",
        backgroundColor: "#ffffff",
    }),

    bottomPlayerResult: props => ({
        textAlign: "center",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontWeight: "bold",
        fontSize: props.rowHeight * 0.7,
        padding: "0.15em",
        marginTop: "8px",
        width: props.columnWidth/2,
        marginLeft: props.columnWidth/2,
        marginRight: props.columnWidth/2,
        backgroundColor: props.config["backgroundFilled"]?props.resultColor:"transparent"
    }),
    setResultStyle: props => ({
        fontFamily: "Arial, Helvetica, sans-serif",
        fontWeight: "",
        color: "#ffffff", 
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: -5,
        paddingTop: 0,
        fontSize: props.rowHeight * 0.2
    }),

    setResultItemStyle: {
        backgroundColor: "#333",
        width: 40,
        padding: 4,
        borderRadius: "30px",
        marginLeft: "1px",
        marginRight: "1px"
    }

});