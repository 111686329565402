import React, { useRef } from 'react';

function Type6Scoreboard(props) {
    return (
        <div>

        </div>
    )
}

export default Type6Scoreboard;
