import React, { useContext } from 'react';
import '@fontsource/roboto';
import { GameContext } from '../entities/GameContext'
import IndividualSetup from './IndividualSetup';
import GameOngoing from './GameOngoing';

function Individual() {
    const { started } = useContext(GameContext);

    console.log("Game started:", started)
    
    if (started) {
        return (<GameOngoing />);
    }else{
        return (<IndividualSetup />);
    }
    
}

export default Individual;
