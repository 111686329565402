import React, { useRef, useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Button,
    MenuItem,
    MenuList,
    Popper,
    Paper,
    Grow,
    ClickAwayListener,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';

import '@fontsource/roboto';
import Grid from '@material-ui/core/Grid';

import GridOn from '@material-ui/icons/GridOn';
import PanoramaIcon from '@material-ui/icons/Panorama';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RefreshIcon from '@material-ui/icons/Refresh';
import PersonIcon from '@material-ui/icons/Person';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SecurityIcon from '@material-ui/icons/Security';

import ControlGeneralStatus from './components/ControlGeneralStatus';
import ControlPhotoView from './components/ControlPhotoView';
import ControlPlayerView from './components/ControlPlayerView';
import ControlPlayerStatsView from './components/ControlPlayerStatsView';
import ControlRefereeView from './components/ControlRefereeView';
import PlayerNames from './components/PlayerNames'
import html2canvas from "html2canvas";
import exportAsImage from '../utils/exportAsImage';
import exportAsImageAndUpload from '../utils/exportAsImageAndUpload'
import {uploadAssetImage} from '../services/backendapi'
import DetailsIcon from '@material-ui/icons/Details';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';

import {useSessionStorage} from "react-use-storage";

function ControlDashboard(props) {

    const defaultConfig = {
        "playerName": true,
        "playerPhoto": true,
        "teamName": true,
        "teamLogo": true,
        "backgroundFilled": true,
        "border": false,
        "backgroundColor": "#ffffff",
        "resultColor": "#F1C40F",
        "gameDetail": false,
        "backgroundImage": true,
        "backgroundImageInPlayerV": false,
        "verticalView": false,
        "umpire": true,
        "referee": true,
        "assist_umpire": true,
    }


    var theme = {
        "width": 1920,
        "height": 1080,
    }
    const classes = useStyles(theme);

    const exportRef = useRef();

    const [ dashboardType, setDashboardType ] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false)
    const [config, setConfig] = useSessionStorage('control_view_config', defaultConfig)

    const [ random, setRandom ] = useState(Math.floor(Math.random() * 100000000) + 1)


    var status = props.status || {}
    var matches = status["matches"] || []
    var controls = status["control"] || {}

    var currentMatch =  status["currentMatch"] || 0
    var currentGame = status["currentGame"] || 0
    var gameId = currentMatch * 10 + currentGame

    var teamAName = status["teamA"] || ""
    var teamBName = status["teamB"] || ""
    var teamAColour = status['teamAColour'] || "#ffffff"
    var matchNumber = controls["matchNumber"] || 7
    var setNumber = controls["setNumber"] || 5
    var playerAName = {}
    var playerBName = {}

    var teamBColour = status['teamBColour'] || "#ffffff"
    var matchSummary = {}
    var totalSummary = {}

    var teamAResult = 0
    var teamBResult = 0

    matches.forEach((m,i) => {
        let p = m["points"] || []
        let A = 0
        let B = 0
        p.forEach((p, j) => {
            let a = p["playerA"] || 0
            let b = p["playerB"] || 0
            if ((a >= 11 || b >= 11) && (Math.abs(a-b)>=2)){
                if (a>b){
                    A += 1
                }else{
                    B += 1
                }
            }
        })
        matchSummary[i] = [A, B]
        if (i<=currentMatch) {
            if (A > setNumber/2 || B > setNumber/2){
                if (A > B) {
                    teamAResult += 1
                }else{
                    teamBResult += 1
                }
            }
        }
        totalSummary[i] = [teamAResult,teamBResult]
        playerAName[i] =  m["playerA"] || ""
        playerBName[i] =  m["playerB"] || ""
    })

    const handleRefresh = () => {
        console.log("refresh")
        setRandom(Math.floor(Math.random() * 100000000) + 1)
    }

    const handleUpload = (e, name) => {
        if (props.cameraId === "") return

        console.log("Upload files")
        if (!e.target.files) {
            console.log("File not exist in target")
            return;
        }
        const file = e.target.files[0];
        async function scopedAsyncFunc(url, file){
            try{
                await uploadAssetImage(url, file)
                handleRefresh()
            }catch(e) {
                console.log("Error happend:", e)
            }
        }
        scopedAsyncFunc(props.baseUrl + "assets/" + props.cameraId + "/" + name, file);
    }

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget)
        setOpenMenu(true)
    }

    const handleCloseMenu = (event) => {
        setOpenMenu(false)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
          event.preventDefault()
          setOpenMenu(false)
        }
    }

    const handleUploadToLayer1 = (element) => {
        if (props.cameraId === "") return

        async function scopedAsyncFunc(element, url){
            try{
                await exportAsImageAndUpload(element, url)
            }catch(e) {
                console.log("Error happend:", e)
            }
        }
        let url = props.baseUrl + props.cameraId + "/layer/external/1"
        console.log("Push to  ", url)
        scopedAsyncFunc(element, url);
    }

    console.log("Control DashBoard: ", props.status, currentMatch, currentGame, matchNumber, setNumber)

    return (
        <Grid container className={classes.root}>
            <Popper
                open={openMenu}
                anchorEl={anchorEl}
                role={undefined}
                transition
                disablePortal
                placement="bottom-end"
                style={{ zIndex: 2500 }}
            >
                {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps}>
                    <Paper className={classes.paperMenu}>
                    <ClickAwayListener onClickAway={handleCloseMenu}>
                        <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            {dashboardType === 1 && <MenuItem className={classes.menuItem} divider={false}
                                onClick={() => { setConfig({...config,"teamName":!config["teamName"]}); handleCloseMenu()}}>
                                <Checkbox checked={config["teamName"]}/>&nbsp;&nbsp;Team Name
                            </MenuItem>}

                            {(dashboardType === 1 || dashboardType === 2 || dashboardType === 3) && <MenuItem className={classes.menuItem} divider={false}
                                onClick={() => { setConfig({...config,"teamLogo":!config["teamLogo"]}); handleCloseMenu()}}>
                                <Checkbox checked={config["teamLogo"]}/>&nbsp;&nbsp;Team Logo
                            </MenuItem>}

                            {dashboardType !== 0 && dashboardType !== 4 && <MenuItem className={classes.menuItem} divider={false}
                                onClick={() => { setConfig({...config,"playerName":!config["playerName"]}); handleCloseMenu()}}>
                                <Checkbox checked={config["playerName"]}/>&nbsp;&nbsp;Player Name
                            </MenuItem>}

                            {dashboardType === 3 && <MenuItem className={classes.menuItem} divider={false}
                                onClick={() => { setConfig({...config,"backgroundImageInPlayerV":!config["backgroundImageInPlayerV"]}); handleCloseMenu()}}>
                                <Checkbox checked={config["backgroundImageInPlayerV"]}/>&nbsp;&nbsp;Background Image
                            </MenuItem>}

                            {(dashboardType === 3 || dashboardType === 4) && <MenuItem className={classes.menuItem} divider={false}
                                onClick={() => { setConfig({...config,"verticalView":!config["verticalView"]}); handleCloseMenu()}}>
                                <Checkbox checked={config["verticalView"]}/>&nbsp;&nbsp;Vertical View
                            </MenuItem>}

                            {dashboardType === 1 && <MenuItem className={classes.menuItem} divider={true}
                                onClick={() => { setConfig({...config,"playerPhoto":!config["playerPhoto"]}); handleCloseMenu()}}>
                                <Checkbox checked={config["playerPhoto"]}/>&nbsp;&nbsp;Player Photo
                            </MenuItem>}

                            {dashboardType === 1 && <MenuItem className={classes.menuItem} divider={true}
                                onClick={() => { setConfig({...config,"gameDetail":!config["gameDetail"]}); handleCloseMenu()}}>
                                <Checkbox checked={config["gameDetail"]}/>&nbsp;&nbsp;Game Detail
                            </MenuItem>}

                            {dashboardType === 1 && <MenuItem className={classes.menuItem} divider={false}
                                onClick={() => { setConfig({...config,"backgroundFilled":!config["backgroundFilled"]}); handleCloseMenu()}}>
                                <Checkbox checked={config["backgroundFilled"]}/>&nbsp;&nbsp;Background Filled
                            </MenuItem>}

                            {dashboardType === 2 && <MenuItem className={classes.menuItem} divider={false}
                                onClick={() => { setConfig({...config,"backgroundImage":!config["backgroundImage"]}); handleCloseMenu()}}>
                                <Checkbox checked={config["backgroundImage"]}/>&nbsp;&nbsp;Background Image
                            </MenuItem>}


                            {dashboardType === 1 && <MenuItem className={classes.menuItem} divider={false}
                                onClick={() => { setConfig({...config,"border":!config["border"]}); handleCloseMenu()}}>
                                <Checkbox checked={config["border"]}/>&nbsp;&nbsp;Border
                            </MenuItem>}


                            {dashboardType === 4 && <MenuItem className={classes.menuItem} divider={false}
                                onClick={() => { setConfig({...config,"umpire":!config["umpire"]}); handleCloseMenu()}}>
                                <Checkbox checked={config["umpire"]}/>&nbsp;&nbsp;Umpire
                            </MenuItem>}
                            {dashboardType === 4 && <MenuItem className={classes.menuItem} divider={false}
                                onClick={() => { setConfig({...config,"referee":!config["referee"]}); handleCloseMenu()}}>
                                <Checkbox checked={config["referee"]}/>&nbsp;&nbsp;Referee
                            </MenuItem>}
                            {dashboardType === 4 && <MenuItem className={classes.menuItem} divider={false}
                                onClick={() => { setConfig({...config,"assist_umpire":!config["assist_umpire"]}); handleCloseMenu()}}>
                                <Checkbox checked={config["assist_umpire"]}/>&nbsp;&nbsp;Assist Umpire
                            </MenuItem>}
                        </MenuList>
                    </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>
            <Grid container xs={12} className={classes.header} direction="row" justifyContent="space-between" alignItems="center">
                <div>
                    <Button
                        variant="contained"
                        color={dashboardType===0?"secondary":"disabled"}
                        onClick={()=>{setDashboardType(0)}} 
                        className={classes.actionButton}
                        endIcon={<GridOn />}>
                        Default View
                    </Button> 
                    <Button
                        variant="contained"
                        color={dashboardType===1?"secondary":"disabled"}
                        onClick={()=>{setDashboardType(1)}} 
                        className={classes.actionButton}
                        endIcon={<PanoramaIcon />}>
                        Photo View
                    </Button> 
                    <Button
                        variant="contained"
                        color={dashboardType===2?"secondary":"disabled"}
                        onClick={()=>{setDashboardType(2)}} 
                        className={classes.actionButton}
                        endIcon={<EqualizerIcon />}>
                        Stats View
                    </Button> 
                    <Button
                        variant="contained"
                        color={dashboardType===3?"secondary":"disabled"}
                        onClick={()=>{setDashboardType(3)}} 
                        className={classes.actionButton}
                        endIcon={<PersonIcon />}>
                        Player View
                    </Button> 
                    <Button
                        variant="contained"
                        color={dashboardType===4?"secondary":"disabled"}
                        onClick={()=>{setDashboardType(4)}} 
                        className={classes.actionButton}
                        endIcon={<SecurityIcon />}>
                        Referee View
                    </Button> 
                </div>
                <div>
                    {dashboardType !== 0 &&
                        <Button
                            variant="contained"
                            color={"success"}
                            onClick={(event)=>{handleOpenMenu(event)}}
                            className={classes.actionButton}
                            endIcon={<SettingsInputComponentIcon/>}>
                            Config
                        </Button> }
                    {dashboardType !== 0 &&
                        <Button
                            variant="contained"
                            color={ "success" }
                            onClick={()=>{handleRefresh()}} 
                            className={classes.actionButton}
                            endIcon={<RefreshIcon />}>
                            Refresh Image
                        </Button> }
                    
                    <Button
                        variant="contained"
                        color="success"
                        onClick={()=>{exportAsImage(exportRef.current, "view_image")}} 
                        className={classes.actionButton}
                        endIcon={<CloudDownloadIcon />}>
                        Download
                    </Button> 
                    {dashboardType !== 0 &&
                        <Button
                            variant="contained"
                            color="secondary"
                            component="label"
                            className={classes.actionButton} 
                            endIcon={<CloudUploadIcon />}>
                            Upload <input type="file" accept="image/*" hidden onChange={(e) => {handleUpload(e, "background_image"); e.target.value=''} } />
                        </Button>
                    }
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={()=>{handleUploadToLayer1(exportRef.current)}} 
                        className={classes.actionButton} 
                        endIcon={<CloudUploadIcon />}>
                        Push To Layer1
                    </Button>
                </div>
            </Grid>
            <Grid container xs={12} className={classes.exportableContent} justify="center" direction="row">
                <div ref={exportRef} className={classes.exportable}>
                    {dashboardType === 0 &&
                        <Grid container xs={12} className={classes.pageContent} spacing={0} justify="center" >
                            <Grid container xs={12} className={classes.pageContent} spacing={0} justify="center" >  
                                <PlayerNames 
                                        leftPlayerName={teamAName}
                                        leftInputLabel="Team ABC"
                                        rightPlayerName={teamBName}
                                        rightInputLabel="Team XYZ"
                                        leftPlayerColour={teamAColour}
                                        rightPlayerColour={teamBColour}
                                        nameOnly={false}
                                        leftPlayerGamePoint={teamAResult}
                                        rightPlayerGamePoint={teamBResult}
                                    />   
                            </Grid>

                            <ControlGeneralStatus 
                                matchNumber={matchNumber}
                                setNumber={setNumber}
                                matches={matches} 
                                gameId={gameId} 
                                currentMatch={currentMatch} 
                                currentGame={currentGame} 
                                totalSummary={totalSummary} 
                                matchSummary={matchSummary} />
                        </Grid> }
                    {dashboardType === 1 && <div>
                        <ControlPhotoView
                            imageUrl={props.baseUrl + "assets/" + props.cameraId}
                            seed={random}
                            uploadAsset={handleUpload}
                            matchNumber={matchNumber}
                            setNumber={setNumber}
                            matches={matches} 
                            gameId={gameId} 
                            config={config}
                            currentMatch={currentMatch} 
                            currentGame={currentGame} 
                            totalSummary={totalSummary} 
                            matchSummary={matchSummary}
                            teamAResult={teamAResult}
                            teamBResult={teamBResult}
                            teamAName = {teamAName}
                            teamBName = {teamBName}
                            playerAName = {playerAName}
                            playerBName = {playerBName}
                        />
                    </div> }
                    {dashboardType === 2 && <div>
                        <ControlPlayerStatsView
                            imageUrl={props.baseUrl + "assets/" + props.cameraId}
                            seed={random}
                            uploadAsset={handleUpload}
                            matchNumber={matchNumber}
                            setNumber={setNumber}
                            matches={matches} 
                            gameId={gameId} 
                            config={config}
                            currentMatch={currentMatch} 
                            currentGame={currentGame} 
                            totalSummary={totalSummary} 
                            matchSummary={matchSummary}
                            teamAResult={teamAResult}
                            teamBResult={teamBResult}
                            teamAName = {teamAName}
                            teamBName = {teamBName}
                            playerAName = {playerAName}
                            playerBName = {playerBName}
                            status={status}
                        />
                    </div> }
                    {dashboardType === 3 && <div>
                        <ControlPlayerView
                            imageUrl={props.baseUrl + "assets/" + props.cameraId}
                            seed={random}
                            uploadAsset={handleUpload}
                            matchNumber={matchNumber}
                            setNumber={setNumber}
                            matches={matches} 
                            gameId={gameId} 
                            config={config}
                            currentMatch={currentMatch} 
                            currentGame={currentGame} 
                            totalSummary={totalSummary} 
                            matchSummary={matchSummary}
                            teamAResult={teamAResult}
                            teamBResult={teamBResult}
                            teamAName = {teamAName}
                            teamBName = {teamBName}
                            playerAName = {playerAName}
                            playerBName = {playerBName}
                            status={status}
                        />
                    </div> }
                    {dashboardType === 4 && <div>
                        <ControlRefereeView
                            imageUrl={props.baseUrl + "assets/" + props.cameraId}
                            seed={random}
                            uploadAsset={handleUpload}
                            matchNumber={matchNumber}
                            setNumber={setNumber}
                            matches={matches} 
                            gameId={gameId} 
                            config={config}
                            currentMatch={currentMatch} 
                            currentGame={currentGame} 
                            totalSummary={totalSummary} 
                            matchSummary={matchSummary}
                            teamAResult={teamAResult}
                            teamBResult={teamBResult}
                            teamAName = {teamAName}
                            teamBName = {teamBName}
                            playerAName = {playerAName}
                            playerBName = {playerBName}
                            status={status}
                        />
                    </div> }
                </div>
            </Grid>
            <Grid container xs={12} className={classes.content} justify="center" direction="column">
                <Grid container className={classes.content} spacing={0} justify="center">
                    <Grid item xs={12}>
                        <Grid container justify="center"  className={classes.copyright}>
                        &#xa9; 2023 KandoLab.com
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default ControlDashboard;

const useStyles = makeStyles((theme) => ({
   

    root: {
        flexGrow: 1,
        marginTop: "0px",
    },

    header: {
        textAlign:"center",
        padding: "20px",
        background: '#444', 
        margin:"0px"
    },
    
    pageContent:{
        //margin: "0px",
        marginTop: "20px",
    },

    exportableContent: {
        width: "100%",

        textAlign: "center",
        marginTop: "10px",
    },

    exportable: {
        margin: 0,
        padding: 0, 
        width: ( props ) => props.width,
        height: ( props ) => props.height, 
    },

    content: {
        marginRight: "0px",
        marginLeft: "0px",
        textAlign:"center",
        marginTop: "0px"
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    copyright: {
        color: "#BBBBBB",
        paddingTop: "10px"
    },
    setNumberGrid:{
        paddingTop: "20px",
    },
    startButtonGrid: {
        paddingTop: "20px"
    },
    startButton: {
        borderRadius: "20px",
        paddingLeft:"30px",
        paddingRight:"30px",
    },
    playerTextField: {
        heigh: "15px",
    },
    logoImg:{
        filter: "invert(1)",
        paddingRight:"5px"
    },
    appbar: {
        background : '#000000',
    },
    formControlLabel: {
        fontSize:'12px'
    },
    actionButton: {
        //height: "12vw",
        //width: "40vw",
        paddingLeft: "5px",
        paddingRight: "5px",
        borderRadius: "20px",
        fontWeight: "bold",
        width: "210px",
        height: "40px",
        marginLeft: "5px",
        marginRight: "5px",
        marginBottom: "5px",
    },
    menuItem: {
        fontSize: 16,
        padding: 8,
        marginLeft: 16,
        marginRight: 16,
    },
    paperMenu: {
        backgroundColor: "#ffffff",
        color:"#000000"
    },
  }));