import React, { useContext,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as TShirt } from '../statics/images/t-shirt.svg';

import { Modal, Fade, Backdrop, Hidden } from '@material-ui/core';

import '@fontsource/roboto';

import CloseIcon from '@material-ui/icons/Close';

import Grid from '@material-ui/core/Grid';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CheckOutlined from '@material-ui/icons/CheckOutlined';

import logo from '../statics/images/kandolab-logo_v1.png';
import { GameContext } from '../entities/GameContext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Snackbar from '@material-ui/core/Snackbar';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import PlayerNames from './components/PlayerNames';
import Alert from './components/Alert';

import TextField from '@material-ui/core/TextField';
import TshirtColorSelector from './components/TshirtColorSelector';

function GroupSetup() {
    const {
        setGameType,setServeA,
        setStarted,setSetNumber,
        setGroupStarted,
        playerAName, setPlayerAName,
        playerBName, setPlayerBName,
        setPlayerAPoint,setPlayerBPoint,
        setCurrentGame,
        teamAName, setTeamAName, 
        teamBName, setTeamBName, 
        teamAColour, setTeamAColour, 
        teamBColour, setTeamBColour, 
        doubleInitialConfig, doubleMatchNumber,
        setCurrentMatch, 
        setPlayerATimeout,
        setPlayerBTimeout,
        setPlayerACards,
        setPlayerBCards,
        groupTeamConfig,
        gameTypeLabel,
        stageStatus,setStageStatus,
        setPointTracking,
        token,
    } = useContext(GameContext);

    const [ message, setMessage ] = useState(null)
    const [ colourSelector, setColourSelector ] = useState(false)
    const [ colourForA, setColourForA ] = useState(true)
    
    const classes = useStyles();

    const handleStart = (event) => {
        event.preventDefault();

        for (var i=0; i<groupTeamConfig.length ; i++){
            if (!(groupTeamConfig[i][2][0] in playerAName) || playerAName[groupTeamConfig[i][2][0]].length == 0) {
                setMessage("Missing player "+groupTeamConfig[i][0])
                return
            }
            if (!(groupTeamConfig[i][3][0] in playerBName) || playerBName[groupTeamConfig[i][3][0]].length == 0) {
                setMessage("Missing player "+groupTeamConfig[i][1])
                return
            }
        }
        if (teamAName.length == 0){
            setMessage("Name for Team ABC is missing")
            return
        }
        if (teamBName.length == 0){
            setMessage("Name for Team XYZ is missing")
            return
        }
        console.log("Let configure doubles:", doubleMatchNumber, doubleInitialConfig, teamAName, teamBName)
        if (doubleMatchNumber>=0 && doubleInitialConfig!=null) {
            let name = playerAName[doubleInitialConfig['teamA'][0]] + " | " + playerAName[doubleInitialConfig['teamA'][1]]
            setPlayerAName({
                ...playerAName, 
                [doubleMatchNumber]: name,
            })
            name = playerBName[doubleInitialConfig['teamB'][0]] + " | " + playerBName[doubleInitialConfig['teamB'][1]]
            setPlayerBName({
                ...playerBName, 
                [doubleMatchNumber]: name,
            })
        }

        setTeamAColour(teamAColour)
        setTeamBColour(teamBColour)

        setPlayerAPoint({0:{0:0}});
        setPlayerBPoint({0:{0:0}});
        setServeA({0:true});
        setCurrentGame(0);
        setCurrentMatch(0)

        setPlayerATimeout({0:false});
        setPlayerBTimeout({0:false});
        setPlayerACards({0:0});
        setPlayerBCards({0:0});
        setPointTracking([])

        setSetNumber(5);

        setGroupStarted(true);
        setStarted(false)
    };

    const handleContinue = () =>{
        setGroupStarted(true);
        setStarted(false)
    }

    const handleSelect = (event) => {
        setGameType(-1)
    };
    const handleErrorBarClose = () => {
        setMessage(null)
    }

    const stageChangeInputHandle = (stage) => {
        setStageStatus(stage)
    }
    const handlePlayerAName = (matches, name) => {
        const [match, match1] = matches
        console.log("handlePlayerAName", match, match1, name)
        if (match != match1){
            setPlayerAName({
                ...playerAName, 
                [match]: name,
                [match1]: name
            })
        }else{
            setPlayerAName({
                ...playerAName, 
                [match]: name,
            })
        }
    };
    const handlePlayerBName = (matches, name) => {
        const [match, match1] = matches
        console.log("handlePlayerBName", match, match1, name)
        if (match != match1){
            setPlayerBName({
                ...playerBName, 
                [match]: name,
                [match1]: name
            })
        }else{
            setPlayerBName({
                ...playerBName, 
                [match]: name,
            })
        }
    };
    const handleColourSelectA = () => {
        setColourForA(true)
        setColourSelector(true)
    }
    const handleColourSelectB = () => {
        setColourForA(false)
        setColourSelector(true)
    }
    const handleCloseColourSelector = () =>{
        setColourSelector(false)
    }

    const handleColourSelected = (colour) =>{
        if (colourForA){
            setTeamAColour(colour)
        }else{
            setTeamBColour(colour)
        }
        setColourSelector(false)
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar} >
                <Toolbar>
                    <IconButton edge="start" 
                        className={classes.menuButton} 
                        color="inherit" 
                        aria-label="menu"
                        onClick={handleSelect} >
                        <ArrowBackIosIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                    {gameTypeLabel}
                    </Typography>
                    <img className={classes.logoImg} src={logo} width="30px"/>
                </Toolbar>
            </AppBar>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={colourSelector}
                onClose={handleCloseColourSelector}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 1000,
                }}
            >
                <Fade in={colourSelector}>
                    <div className={classes.colourDialog}>
                        <h2 style={{marginBottom: "0px"}}>Color de Camisa</h2>

                        <TshirtColorSelector setColor={handleColourSelected} />
                        <Button 
                            variant="contained" color="secondary" onClick={handleCloseColourSelector}
                            startIcon={<CloseIcon />}>
                             Close
                        </Button>
                    </div>
                </Fade>
            </Modal>

            <form noValidate autoComplete="off" onSubmit={handleStart}>
                <Grid container xs={12} className={classes.pageContent} spacing={0} justify="center" >     
                    <Grid container xs={12} sm={12} className={classes.teamContent} spacing={0} justify="center" direction="column" >     
                        <PlayerNames 
                            leftPlayerName={teamAName}
                            leftInputLabel="Team ABC"
                            rightPlayerName={teamBName}
                            rightInputLabel="Team XYZ"
                            nameOnly={true}
                            hideTShirt={true}
                            leftInputHandle={(n)=>{console.log("setTeamAName to " + n); setTeamAName(n)}}
                            rightInputHandle={(n)=>{console.log("setTeamBName to " + n); setTeamBName(n)}}
                            token={token}
                            isPlayer={false}
                        />
                    </Grid>
                    <Hidden xsDown>
                        <TShirt width='45px' fill={teamAColour} stroke="black" onClick={()=>{handleColourSelectA()}}  />
                    </Hidden>
                    <Grid container xs={12} sm={8} className={classes.content} spacing={0} justify="center" direction="column" >     
                        {groupTeamConfig.map((value) => (
                                        <Grid item>
                                            <PlayerNames 
                                                leftInputLabel={value[0]}
                                                leftPlayerName={playerAName[value[2][0]] || ""}
                                                rightInputLabel={value[1]}
                                                rightPlayerName={playerBName[value[3][0]] || ""}
                                                nameOnly={true}
                                                hideTShirt={true}
                                                leftInputHandle={(n)=>{handlePlayerAName(value[2],n)}}
                                                rightInputHandle={(n)=>{handlePlayerBName(value[3],n)}}
                                                token={token}
                                                isPlayer={true}
                                                />
                                        </Grid>
                        ))}
                    </Grid>
                    <Hidden xsDown>
                        <TShirt width='45px' fill={teamBColour} stroke="black" onClick={()=>{handleColourSelectB()}}  />
                    </Hidden>
                </Grid>
                <Hidden smUp>
                    <Grid container xs={12} className={classes.content} justify="space-around" direction="row" spacing={1}>
                        <TShirt width='45px' fill={teamAColour} stroke="black" onClick={()=>{handleColourSelectA()}}  />
                        <TShirt width='45px' fill={teamBColour} stroke="black" onClick={()=>{handleColourSelectB()}}  />
                    </Grid>
                </Hidden>
                <Grid container xs={12} className={classes.content} justify="center" direction="column">
                    <Grid container className={classes.content} spacing={0} justify="center">
                        <Grid item xs={12} className={classes.stageContent} > 
                                <TextField 
                                    size="small" 
                                    label="Competition Stage"
                                    id="input-game-stage" 
                                    value={stageStatus}
                                    variant="outlined"
                                    onChange={(e)=>{stageChangeInputHandle(e.target.value)}}
                                    inputProps={{
                                        style: {textAlign:"right", color:"black", textTransform: 'uppercase'}
                                    }}
                                    />
                        </Grid> 
                        <Grid item xs={12} sm={12}>
                            <Grid container justify="center" direction="row" spacing={1} className={classes.startButtonGrid}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        type="submit"
                                        className={classes.startButton}
                                        endIcon={<PlayCircleOutlineIcon />}>
                                        Start
                                    </Button> 
                                </Grid>              
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="center"  className={classes.copyright}>
                            &#xa9; 2023 KandoLab.com
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <Snackbar open={message != null} 
                bodyStyle={{width: '100%'}} 
                autoHideDuration={3000} 
                onClose={handleErrorBarClose}>
                <Alert onClose={handleErrorBarClose} saverity="error" >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default GroupSetup;

const useStyles = makeStyles((theme) => ({
    pageContent:{
        //margin: "0px",
        marginTop: "20px",
    },
    appBar: { 
        background: '#ff50c2',
        color: 'white' ,
    },
    content: {
        marginRight: "0px",
        marginLeft: "0px",
        textAlign:"center",
        marginTop: "0px"
    },
    stageContent: {
        marginTop: "10px",
    },
    teamContent:{
        marginRight: "0px",
        marginLeft: "0px",
        textAlign:"center",
        marginTop: "0px",
        marginBottom: "10px"
    },
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    copyright: {
        color: "#BBBBBB",
        paddingTop: "20px"
    },
    setNumberGrid:{
        paddingTop: "20px",
    },
    startButtonGrid: {
        paddingTop: "10px"
    },
    startButton: {
        borderRadius: "20px",
        paddingLeft:"30px",
        paddingRight:"30px",
    },
    playerTextField: {
        heigh: "15px",
    },
    logoImg:{
        paddingRight:"5px"
    },
    appbar: {
        backgroundColor : '#ff50c2',
    },
    formControlLabel: {
        fontSize:'12px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tShirt: {
        marginLeft: "2px",
        marginRight: "2px",
        width: '45px',
    },
    tShirtArray:{
        marginTop: "10px",
        marginBottom: "15px"
    },
    colourDialog: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #999',
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 7, 3),
        textAlign: "center"
    }
  }));