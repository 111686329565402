import React, { useContext,useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as TShirt } from '../statics/images/t-shirt.svg';

import { Modal, Fade, Backdrop } from '@material-ui/core';

import '@fontsource/roboto';

import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import logo from '../statics/images/kandolab-logo_v1.png';
import { GameContext } from '../entities/GameContext';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from './components/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import TshirtColorSelector from './components/TshirtColorSelector';

function IndividualSetup() {
    const {
        setGameType, setSetNumber,
        serveA, setServeA,setCurrentMatch,
        setStarted,
        playerAName, setPlayerAName,
        playerBName, setPlayerBName,
        setPlayerAPoint,setPlayerBPoint,
        setCurrentGame,
        teamAColour, setTeamAColour,
        teamBColour, setTeamBColour, 
        setTeamAName, setTeamBName,
        playerATimeout, setPlayerATimeout,
        playerBTimeout, setPlayerBTimeout,
        gameTypeLabel,
        stageStatus, setStageStatus,
        setPlayerACards,
        setPlayerBCards,
        setPointTracking,
        token,
    } = useContext(GameContext);
    
    const classes = useStyles();

    const [ message, setMessage ] = useState(null)
    const [ colourSelector, setColourSelector ] = useState(false)
    const [ colourForA, setColourForA ] = useState(true)
    const [ open, setOpen ] = useState(-1)
    const [ options, setOptions] = useState([]);
    const [ fetching, setFetching ] = useState(false)
    const loading = open && fetching;

    useEffect(() => {
        let active = true;
    
        if (!loading) {
            return undefined;
        }
    
        if (token == null){
            return undefined;
        }
        (async () => {
            const response = sessionStorage.getItem('players');
            const storedTime = sessionStorage.getItem('playersTime');
            const currentTime = new Date().getTime();
            const validMaxTime = 60 * 1000;

            if (response && storedTime && currentTime - storedTime < validMaxTime) {
                const players = JSON.parse(response);
                if (active) {
                    setOptions(players);
                    setFetching(false);
                }
            } else {
                const fetchPlayers = async () => {
                    try{
                        const response = await fetch('https://api.kandolab.com/api/v2/cameras/assets/' + token.id + '/players.json');
                        if (response.ok) {
                            const players = await response.json();
                            if (active) {
                                sessionStorage.setItem('players', JSON.stringify(players));
                                sessionStorage.setItem('playersTime', currentTime);
                                setOptions(players);
                                setFetching(false);
                            }
                        }
                    }catch(e){
                        if (active) {
                            sessionStorage.setItem('players', JSON.stringify([]));
                            sessionStorage.setItem('playersTime', currentTime);
                            setOptions([]);
                            setFetching(false);
                        }
                    }
                };
                fetchPlayers();
            }
        })();
    
        return () => {
          active = false;
        };
      }, [loading]);
    
    useEffect(() => {
        if (!open) {
          setOptions([]);
        }else{
          setFetching(true);
        }
      }, [open]);

    const handleStart = (n) => {
        if (!(0 in playerAName)  || (playerAName[0].length == 0)) {
            setMessage("Player A is missing")
            return
        }
        if (!(0 in playerBName)  || (playerBName[0].length == 0)) {
            setMessage("Player B is missing")
            return
        }
        setTeamAColour(teamAColour)
        setTeamBColour(teamBColour)

        setTeamAName("")
        setTeamBName("")
        
        setPlayerAPoint({0:{0:0}})
        setPlayerBPoint({0:{0:0}})
        setServeA({0:true});
        setCurrentGame(0);
        setCurrentMatch(0);
        setStarted(true);
        setSetNumber(n);
        setPlayerATimeout({0:false});
        setPlayerBTimeout({0:false});
        setPlayerACards({0:0});
        setPlayerBCards({0:0});
        setPointTracking([])
    };

    const handleSelect = (event) => {
        setGameType(-1)
    };

    const handlePlayerAName = (event) => {
        setPlayerAName({
            0: event.target.value
        })
    };
    const handlePlayerBName = (event) => {
        setPlayerBName({
            0: event.target.value
        })
    };

    const handleErrorBarClose = () => {
        setMessage(null)
    }

    const stageChangeInputHandle = (stage) => {
        setStageStatus(stage)
    }


    const handleCloseColourSelector = () =>{
        setColourSelector(false)
    }
    
    const handleColourSelectA = () => {
        setColourForA(true)
        setColourSelector(true)
    }
    const handleColourSelectB = () => {
        setColourForA(false)
        setColourSelector(true)
    }

    const handleColourSelected = (colour) =>{
        if (colourForA){
            setTeamAColour(colour)
        }else{
            setTeamBColour(colour)
        }
        setColourSelector(false)
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar} >
                <Toolbar>
                    <IconButton edge="start" 
                        className={classes.menuButton} 
                        color="inherit" 
                        aria-label="menu"
                        onClick={handleSelect} >
                        <ArrowBackIosIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                    {gameTypeLabel}
                    </Typography>
                    <img className={classes.logoImg} src={logo} width="30px"/>
                </Toolbar>
            </AppBar>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={colourSelector}
                onClose={handleCloseColourSelector}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 1000,
                }}
            >
                <Fade in={colourSelector}>
                    <div className={classes.colourDialog}>
                        <h2 style={{marginBottom: "0px"}}>T-Shirt Color</h2>
                        <TshirtColorSelector setColor={handleColourSelected} />
                        <Button 
                            variant="contained" color="secondary" onClick={handleCloseColourSelector}
                            startIcon={<CloseIcon />}>
                             Close
                        </Button>
                    </div>
                </Fade>
            </Modal>

            <Grid container xs={12} className={classes.pageContent} spacing={0} justify="center" direction="row" >     
                <Grid container xs={12} className={classes.content} justify="center" direction="column">
                    <form className={classes.contentPlayerName} noValidate autoComplete="off" >
                        <Grid container className={classes.contentPlayerName} justify="center" direction="column">
                            <Grid container justify="center" >
                                <Grid item>
                                    <Autocomplete
                                        id="player-a-name"
                                        className={classes.playerNameField}
                                        required 
                                        freeSolo
                                        autoSelect={true}
                                        size="small" 
                                        style={{ width: 250 }}
                                        open={open==1}
                                        onOpen={() => {
                                            setOpen(1);
                                        }}
                                        onClose={() => {
                                            setOpen(-1);
                                        }}
                                        getOptionSelected={(option, value) => option.name === value.name}                                        
                                        getOptionLabel={(option) => {
                                            if (typeof option === 'string'){
                                                return option
                                            }else{
                                                return option?(option.name + " (" + option.club + ")"):""
                                            }
                                        }}
                                        options={options}
                                        loading={loading}
                                        value = {playerAName[0] || ""}
                                        onChange={(event, newValue, reason) => {
                                            let name = newValue
                                            if (reason == "select-option" || reason == "blue"){
                                                name = newValue.name
                                            }else if (reason == "clear"){
                                                name = ""
                                            }
                                            if (typeof name === 'object' && name !== null) {
                                                if (name.hasOwnProperty('name')) {
                                                    name = name.name
                                                }
                                            }
                                            console.log("OnChange A: ", newValue, reason, name)
                                            setPlayerAName({
                                                0: name
                                            })
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                            {option.name}-{option.club}-{option.level}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            label="Player A"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                style: {textTransform: 'uppercase'},
                                                endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                                ),
                                            }}
                                            />
                                        )}
                                        />
                                </Grid>
                                <Grid item>
                                    <TShirt width='45px' fill={teamAColour} stroke="black" onClick={()=>{handleColourSelectA()}}  />
                                </Grid>
                            </Grid>
                            <Grid container justify="center" >
                                <Grid item>
                                <Autocomplete
                                        id="player-a-name"
                                        className={classes.playerNameField}
                                        required 
                                        freeSolo
                                        autoSelect={true}
                                        size="small" 
                                        style={{ width: 250 }}
                                        open={open==2}
                                        onOpen={() => {
                                            setOpen(2);
                                        }}
                                        onClose={() => {
                                            setOpen(-1);
                                        }}
                                        getOptionSelected={(option, value) => option.name === value.name}
                                        getOptionLabel={(option) => {
                                            if (typeof option === 'string'){
                                                return option
                                            }else{
                                                return option?(option.name + " (" + option.club + ")"):""
                                            }
                                        }}
                                        options={options}
                                        loading={loading}
                                        value = {playerBName[0] || ""}
                                        onChange={(event, newValue, reason) => {
                                            let name = newValue
                                            if (reason == "select-option" || reason == "blue"){
                                                name = newValue.name
                                            }else if (reason == "clear"){
                                                name = ""
                                            }
                                            if (typeof name === 'object' && name !== null) {
                                                if (name.hasOwnProperty('name')) {
                                                    name = name.name
                                                }
                                            }
                                            console.log("OnChange B: ", newValue, reason, name)
                                            setPlayerBName({
                                                0: name
                                            })
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                            {option.name}-{option.club}-{option.level}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            label="Player B"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                style: {textTransform: 'uppercase'},
                                                endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                                ),
                                            }}
                                            />
                                        )}
                                        />
                                </Grid>
                                <Grid item>
                                    <TShirt width='45px' fill={teamBColour} stroke="black" onClick={()=>{handleColourSelectB()}}  />
                                </Grid>

                            </Grid>
                            <Grid container justify="center" className={classes.actionDiv}>
                                <Grid item xs={12} sm={4} > 
                                    <TextField 
                                        size="small" 
                                        label="Competition Stage"
                                        id="input-game-stage" 
                                        value={stageStatus}
                                        variant="outlined"
                                        autoComplete="on"
                                        onChange={(e)=>{stageChangeInputHandle(e.target.value)}}
                                        inputProps={{
                                            style: {textAlign:"right", color:"black", textTransform: 'uppercase'}
                                        }}
                                        />
                                </Grid> 
                            </Grid>
                            <Grid container justify="center" className={classes.actionDiv}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        className={classes.startButton}
                                        onClick={()=>{handleStart(7)}} 
                                        endIcon={<PlayCircleOutlineIcon />}
                                    >
                                        Start (7 Sets)
                                    </Button>
                                </Grid>
                                
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.startButton}
                                        onClick={()=>{handleStart(5)}} 
                                        endIcon={<PlayCircleOutlineIcon />}
                                    >
                                        Start (5 Sets)
                                    </Button>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    </form>

                    <Snackbar open={message != null} autoHideDuration={3000} onClose={handleErrorBarClose}>
                        <Alert onClose={handleErrorBarClose} saverity="error" >
                            {message}
                        </Alert>
                    </Snackbar>

                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center"  className={classes.copyright}>
                    &#xa9; 2023 KandoLab.com
                    </Grid>
                </Grid>
            </Grid>
            
        </div>
    );
}

export default IndividualSetup;

const useStyles = makeStyles((theme) => ({
    pageContent:{
        //margin: "0px",
        marginTop: "20px",
    },
    actionDiv: {
        marginTop: "15px",
    },
    appBar: { 
        background: '#ff50c2',
        color: 'black' ,
    },
    content: {
        marginRight: "0px",
        marginLeft: "0px",
        textAlign:"center",
    },
    contentPlayerName: {
        marginRight: "0px",
        marginLeft: "0px",
        marginTop: "0px", 
        textAlign:"center",
    },
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    copyright: {
        color: "#BBBBBB",
        paddingTop: "50px"
    },
    setNumberGrid:{
        paddingTop: "20px",
    },
    startButtonGrid: {
        paddingTop: "20px"
    },
    startButton: {
        borderRadius: "20px",
        marginTop: "10px",
        marginLeft: "20px",
        marginRight: "20px",
        paddingLeft:"30px",
        paddingRight:"30px",
        fontSize: "12px",
    },
    playerNameField: {
        marginTop: "10px",
        marginLeft: "10px",
        marginRight: "10px",
    },
    logoImg:{
        paddingRight:"5px"
    },
    appbar: {
        background : '#000000',
    },
    formControlLabel: {
        fontSize:'12px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tShirt: {
        marginLeft: "2px",
        marginRight: "2px",
        width: '45px',
    },
    tShirtArray:{
        marginTop: "10px",
        marginBottom: "15px"
    },
    colourDialog: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #999',
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 7, 3),
        textAlign: "center"
    }
  }));
