import React, {useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Chip, Avatar, Button} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import TextField from '@material-ui/core/TextField';
import '@fontsource/roboto';

import ExtendedNameFild from './ExtendedNameFild';
import {useSessionStorage} from "react-use-storage";

function ControlRefereeView(props) {
    const [refereeName, setRefereeName] = useSessionStorage("referee_name", "");
    const [umpireName, setUmpireName] = useSessionStorage("umpire_name", "");
    const [assistUmpireName, setAssistUmpireName] = useSessionStorage("assist_umpire_name", "");

    const [editing, setEditing] = useState(false);
    
    let fullWidth = 1920
    let fullHeight = 1080


    console.log("config:", props.config)

    const handleSave = () => {
        setEditing(false)
    }

    const handleEdit = () => {
        setEditing(true)
    }

    var theme = {
        "width": fullWidth,
        "height": fullHeight,
        "teamMarginLeft": parseInt(fullWidth / 14 * 4.2),
        "teamMarginTop": parseInt(fullHeight/16*8.25),
        "teamContentWidth": parseInt(fullWidth / 14 * 5.6),
        "columnWidth": parseInt(fullWidth / 14),
        "topRefereeMarginTop": parseInt(fullHeight/16*3),
        "contentMarginTop": fullHeight/16*4.5,
        "contentMarginLeft": parseInt(fullWidth / 14 * 2.5),
        "contentWidth": parseInt(fullWidth / 14 * 9),
        "contentHeight": fullHeight/16*8,
        "rowHeight": fullHeight/16,
        "refereeAvatarRowHeight": fullHeight/16*1,
        "refereeAvatarRowWidth": parseInt(fullWidth / 14 * 10),
        "refereeAvatarRowHeightInCol": fullHeight/16*11,
        "refereeAvatarRowWidthInCol": parseInt(fullWidth / 14 * 12),
        "refereeResultRowHeight": fullHeight/16*7/props.matchNumber,
        "borderDebug": props.config["border"]?"1px solid #000000":"0px solid #000000",
        "config": props.config,
        "backgroundColor": props.config["backgroundColor"],
        "resultColor": props.config["resultColor"],
    }
    const classes = useStyles(theme);

    var gameId = props.currentMatch * 10 + props.currentGame

    console.log("RefereeView props:", props)

    let elements = [
        {
            "label": "Umpire",
            "name": umpireName,
            "visible": props.config["umpire"],
            "big": false,
            "setFunction": setUmpireName,
            "photo_url": "umpire_photo",
        },
        {
            "label": "Referee",
            "name": refereeName,
            "visible": props.config["referee"],
            "big": true,
            "setFunction": setRefereeName,
            "photo_url": "referee_photo",
        },
        {
            "label": "Assistant Umpire",
            "name": assistUmpireName,
            "visible": props.config["assist_umpire"],
            "big": false,
            "setFunction": setAssistUmpireName,
            "photo_url": "assist_umpire_photo",
        }
    ]

    return (
        <div className={classes.pageContent}>  
            <div className={classes.backGround}>
                <img className={classes.backgroundImg} src={props.imageUrl+ "/background_image?random="+props.seed}/>
            </div> 

            {!props.config["verticalView"] && <div className={classes.refereeAvatarRow} >
                {elements.map((element, index) => {
                    let url = props.imageUrl+ "/"+element.photo_url+"?random="+props.seed
                    if (element.visible){
                        return (
                            <div className={classes.refereeBigAvatarAndName} style={{justifyContent: "center"}}>
                                <div className={classes.topRefereeName} onClick={()=> {handleEdit()}}>
                                    {!editing  && <ExtendedNameFild name={element.name} fontSize={theme.rowHeight * 0.5}/>}
                                    {editing && <TextField 
                                                    size="large" 
                                                    id={"name"+index}
                                                    value={element.name}
                                                    variant="outlined"
                                                    onChange={(e)=>{element.setFunction(e.target.value)}}
                                                    inputProps={{
                                                        style: {textAlign:"center", color:"black", textTransform: 'uppercase'}
                                                    }}/>}
                                </div>
                                <div className={element.big?classes.refereeBigAvatarDivPlus:classes.refereeBigAvatarDiv}>
                                    <Button
                                        color="secondary"
                                        component="label"
                                        >
                                        <Avatar
                                            alt={element.name}
                                            src={url}
                                            className={element.big?classes.refereeBigAvatarPlus:classes.refereeBigAvatar}
                                        />
                                        <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, element.photo_url); e.target.value=''} } />
                                    </Button>  
                                </div> 
                                <div className={classes.bottomLabel} onClick={()=> {handleEdit()}}>
                                    {element.label}
                                </div>
                            </div>
                        )
                    }else{
                        return <></>
                    }
                })}
                
            </div>}
            {editing &&<div className={classes.saveDiv}>
                    <Button 
                        variant="contained" color="primary" onClick={handleSave}
                        startIcon={<CloseIcon />}>
                            Save
                    </Button> 
                </div>
            }
        </div>
    )
}

export default ControlRefereeView;


const useStyles = makeStyles({
    pageContent: props => ({
        //margin: "0px",
        position: "relative",
        //backgroundSize: 'contain',
        //backgroundImage: 'url("https://api.kandolab.com/api/v2/cameras/6dee49ec-4b10-4029-97a4-7da47a21e67b/layer/1?preview=true&filename=c9l0t1665854829_all_patrocinadores.gif")',
        width:  props.width,
        height: props.height, 
    }),
    backGround: props => ({
        position: "absolute",
        left: 0,
        top: 0,
        width: props.width,
        height: props.height 
    }),
    backgroundImg: props => ({
        width: props.width,
        height: props.height,
        display: props.config["backgroundImageInRefereeV"]?"block":"none",
    }),
    refereeAvatarRow: props => ({
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        left: (props.width/2 - props.refereeAvatarRowWidth/2),
        top: (props.height*3/4 - props.refereeAvatarRowHeight),
        width: props.refereeAvatarRowWidth,
        height: props.refereeAvatarRowHeight,
        border: props.borderDebug,
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor: props.config["backgroundFilled"]?props.backgroundColor:"transparent"
    }),
    saveDiv: props => ({
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        top: (props.height - 30),
        width: props.width,
        border: props.borderDebug,
        justifyContent: "center",
        alignItems: "center",
    }),
    refereeBigAvatarAndName: props => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: props.columnWidth * 4,
        height: props.rowHeight * 1.5,
    }),
    topRefereeName: props => ({
        textAlign: "left",
        fontFamily: "Arial, Helvetica, sans-serif",
        marginLeft: props.rowHeight/5,
        fontSize: props.rowHeight * 0.5,
        fontWeight: "bold",
        textTransform: 'uppercase',
        padding: "0.25em",
        color: "#000000",
        backgroundColor: "#ffffff",
    }),
    bottomLabel: props => ({
        textAlign: "center",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontWeight: "bold",
        fontSize: props.rowHeight * 0.4,
        padding: "0.25em",
        marginTop: "8px",
        backgroundColor: props.config["backgroundFilled"]?props.resultColor:"transparent"
    }),

    refereeBigAvatarDiv: props => ({
        width: props.rowHeight*3.5,
        height: props.rowHeight*3.5,
    }),
    refereeBigAvatar: props => ({
        width: props.rowHeight*3.5*5/6,
        height: props.rowHeight*3.5*5/6,
        marginTop: "3px",
        backgroundColor: "#ffffff",
    }),
    refereeBigAvatarDivPlus: props => ({
        width: props.rowHeight*5.5,
        height: props.rowHeight*5.5,
    }),
    refereeBigAvatarPlus: props => ({
        width: props.rowHeight*5.5*5/6,
        height: props.rowHeight*5.5*5/6,
        marginTop: "3px",
        backgroundColor: "#ffffff",
    }),

    teamResultRow: props => ({
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        left: props.teamMarginLeft,
        top: props.teamMarginTop,
        textAlign: "center",
        
        justifyContent: "space-between",
        alignItems: "center",
        height: props.rowHeight * 1.5,
        width: props.teamContentWidth,
        border: props.borderDebug,
    }),

    teamAvatarDiv: props => ({
        width: props.rowHeight*2.5,
        height: props.rowHeight*2.5,
    }),
    teamAvatar: props => ({
        width: props.rowHeight*2.5*5/6,
        height: props.rowHeight*2.5*5/6,
        marginTop: "3px",
        backgroundColor: "#ffffff",
    }),



    // Vertical view
    refereeAvatarCol: props => ({
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: (props.width/2 - props.refereeAvatarRowWidthInCol/2),
        top: (props.height/2 - props.refereeAvatarRowHeightInCol/2),
        width: props.refereeAvatarRowWidthInCol,
        height: props.refereeAvatarRowHeightInCol,
        border: props.borderDebug,
        justifyContent: "space-between",
        alignItems: "center",
        //backgroundColor: props.config["backgroundFilled"]?props.backgroundColor:"transparent"
    }),
    refereeBigAvatarAndNameInCol: props => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: props.columnWidth * 10,
        height: props.rowHeight * 1.5,
    }),
    topRefereeNameInCol: props => ({
        textAlign: "left",
        fontFamily: "Arial, Helvetica, sans-serif",
        marginLeft: props.rowHeight/5,
        fontSize: props.rowHeight * 0.5,
        fontWeight: "bold",
        textTransform: 'uppercase',
        padding: "0.25em",
        color: "#000000",
        backgroundColor: "#ffffff",
        display: props.config["refereeName"]?"block":"none",
    }),
    topRefereeBNameInCol: props => ({
        textAlign: "right",
        fontFamily: "Arial, Helvetica, sans-serif",
        marginRight: props.rowHeight/5,
        fontSize: props.rowHeight * 0.6,
        fontWeight: "bold",
        color: "#000000",
        padding: "0.25em",
        backgroundColor: "#ffffff",
        textTransform: 'uppercase',
        display: props.config["refereeName"]?"block":"none",
    }),
    refereeBigAvatarDivInCol: props => ({
        width: props.rowHeight*3,
        height: props.rowHeight*3,
    }),
    refereeBigAvatarInCol: props => ({
        width: props.rowHeight*3*5/6,
        height: props.rowHeight*3*5/6,
        marginTop: "3px",
        backgroundColor: "#ffffff",
    }),
    refereeBigAvatarDivInColLeft: props => ({
        width: props.rowHeight*3.5,
        height: props.rowHeight*3.5,
    }),
    refereeBigAvatarInColLeft: props => ({
        width: props.rowHeight*3.5*5/6,
        height: props.rowHeight*3.5*5/6,
        marginTop: "3px",
        backgroundColor: "#ffffff",
    }),
    //

});