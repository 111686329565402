

import React, {useEffect, useRef} from 'react';
import * as d3 from "d3";

function PlayerStats ({ data, evolutionStats, width, height, centerWidth }) {
    const chartRef = useRef();
  
    useEffect(() => {
        const barData = [12, 5, 6, 6, 9, 10];
        const len = Math.max(evolutionStats.length, 12);
        const barHeigthPadding = 5;
        const barHeight = height / len - barHeigthPadding*2;
        const barWidthPerPercent = (width-barHeight*5-centerWidth) / 2.0 / 100.0

        console.log("data: ", data, "stats:", evolutionStats)

        const svg = d3.select(chartRef.current)

        svg.selectAll("*").remove();

        svg.attr("width", width)
            .attr("height", height);

        const playerB = svg.append("g");

        playerB.selectAll("rect")
            .data(evolutionStats)
            .enter()
            .append("rect")
            .attr("x", (d, i) => width / 2.0 + centerWidth/2)
            .attr("y", (d, i) => i * (barHeight + barHeigthPadding*2))
            .attr("width", (d, i) => d.stats.lastB * barWidthPerPercent)
            .attr("height",  (d, i) => {
                if (i==evolutionStats.length-1) {
                    return barHeight*1.25
                }else{
                    return barHeight
                }
            })
            .attr("stroke", (d, i) => {
                if (i==evolutionStats.length-1) {
                    return "#F1C40F"
                }else{
                    return "none"
                }
            })
            .attr("stroke-width", 3)
            .attr("fill", "#0b84a8");

        const playerA = svg.append("g");
        playerA.selectAll("rect")
            .data(evolutionStats)
            .enter()
            .append("rect")
            .attr("x", (d, i) => width / 2.0 - d.stats.lastA * barWidthPerPercent - centerWidth/2)
            .attr("y", (d, i) => i * (barHeight + barHeigthPadding*2))
            .attr("width", (d, i) => d.stats.lastA * barWidthPerPercent)
            .attr("height",  (d, i) => {
                if (i==evolutionStats.length-1) {
                    return barHeight*1.25
                }else{
                    return barHeight
                }
            })
            .attr("stroke", (d, i) => {
                if (i==evolutionStats.length-1) {
                    return "#F1C40F"
                }else{
                    return "none"
                }
            })
            .attr("stroke-width", 3)
            .attr("fill", "#ffffff");
        
        const scoreBack = svg.append("g");
        scoreBack
            .selectAll("rect")
            .data(evolutionStats)
            .enter()
            .append("rect")
            .attr("x", (d, i) => width / 2.0 - centerWidth/10*4)
            .attr("y", (d, i) => i * (barHeight + barHeigthPadding*2))
            .attr("width", (d, i) => centerWidth/10*8)
            .attr("height",  (d, i) => {
                if (i==evolutionStats.length-1) {
                    return barHeight*1.25
                }else{
                    return barHeight
                }
            })
            .attr("stroke", (d, i) => {
                if (i==evolutionStats.length-1) {
                    return "#F1C40F"
                }else{
                    return "none"
                }
            })
            .attr("stroke-width", 3)
            .attr("fill",  (d, i) => {
                if (i==evolutionStats.length-1) {
                    return "#F1C40F"
                }else{
                    return "#CD6155"
                }
            }) // "rgb(241, 196, 15)");

        const scoreWinBack = svg.append("g");
        scoreWinBack
            .selectAll("rect")
            .data(evolutionStats)
            .enter()
            .append("rect")
            .attr("x", (d, i) => {
                if (d.track.playerAWin){
                    return width / 2.0 - centerWidth/10*4
                }else{
                    return width / 2.0 + centerWidth/10*0
                }
            })
            .attr("y", (d, i) => i * (barHeight + barHeigthPadding*2))
            .attr("width", (d, i) => centerWidth/10*4)
            .attr("height",  (d, i) => {
                if (i==evolutionStats.length-1) {
                    return barHeight*1.25
                }else{
                    return barHeight
                }
            })
            .attr("stroke", (d, i) => {
                if (i==evolutionStats.length-1) {
                    return "#F1C40F"
                }else{
                    return "none"
                }
            })
            .attr("stroke-width", 3)
            .attr("fill", "#2ECC71")// "#ff4092");

        const score = svg.append("g");
        score
            .selectAll("text")
            .data(evolutionStats)
            .enter()
            .append("text")
            .attr("x", (d) => width / 2)
            .attr("y", (d,i) => (i+0.5) * (barHeight + barHeigthPadding*2))
            .attr("dy", (d, i) => {
                if (i==evolutionStats.length-1) {
                    return "0.3em"
                }else{
                    return "0.1em"
                }
            })
            .attr("text-anchor", "middle")
            .attr("fill", "black")
            .attr("font-weight", (d, i) => {
                if (i==evolutionStats.length-1) {
                    return "bold"
                }else{
                    return "normal"
                }
            })
            .style("font-size", barHeight*0.6)
            .text((d) => ""+d.track.pointA+" - "+d.track.pointB);

        const playerBValue = svg.append("g");
        playerBValue
            .selectAll("text")
            .data(evolutionStats)
            .enter()
            .append("text")
            .attr("x", (d, i) => width / 2.0 + centerWidth/2 + d.stats.lastB * barWidthPerPercent + barHeight/4)
            .attr("y", (d, i) => (i+0.5) * (barHeight + barHeigthPadding*2))
            .attr("dy", "0.25em")
            .attr("text-anchor", "start")
            .attr("fill", "white")
            .style("font-size", barHeight*0.6)
            .text((d,i) => {if (i%2==1) {
                return ""+d.stats.lastB + "%"
            }else{
                return ""
            }});

        const playerAValue = svg.append("g");
        playerAValue
            .selectAll("text")
            .data(evolutionStats)
            .enter()
            .append("text")
            .attr("x", (d, i) => width / 2.0 - centerWidth/2 - d.stats.lastA * barWidthPerPercent - barHeight/4)
            .attr("y", (d, i) => (i+0.5) * (barHeight + barHeigthPadding*2))
            .attr("dy", "0.25em")
            .attr("text-anchor", "end")
            .attr("fill", "white")
            .style("font-size", barHeight*0.6)
            .text((d,i) => {if (i%2==1) {
                return ""+d.stats.lastA + "%"
            }else{
                return ""
            }});
        const serve = svg.append("g");
        serve
            .selectAll("circle")
            .data(evolutionStats)
            .enter()
            .append("circle")
            .attr("cx", (d) => {
                if ((d.track.playerAWin && d.track.serving) || 
                    (!d.track.playerAWin && !d.track.serving)) {
                    return width / 2.0 - centerWidth/10*3
                }else{
                    return width / 2.0 + centerWidth/10*3
                }
            })
            .attr("cy", (d,i) => (i+0.35) * (barHeight + barHeigthPadding*2))
            .attr("r", centerWidth/10*0.5)
            .attr("fill", (d) => "#FFFFFF");

    }, [data, evolutionStats, width, height]);
  
    return (
        <>
            <svg ref={chartRef}></svg>
        </>
    );
}

export default PlayerStats
