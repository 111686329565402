import React, {useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Chip, Avatar, Button} from '@material-ui/core';


import '@fontsource/roboto';
import PlayerNames from './PlayerNames';
import ExtendedNameFild from './ExtendedNameFild';
import PlayerStats from './PlayerStats';

function ControlPlayerView(props) {
    let fullWidth = 1920
    let fullHeight = 1080

    console.log("config:", props.config)

    var theme = {
        "width": fullWidth,
        "height": fullHeight,
        "teamMarginLeft": parseInt(fullWidth / 14 * 4.2),
        "teamMarginTop": parseInt(fullHeight/16*8.25),
        "teamContentWidth": parseInt(fullWidth / 14 * 5.6),
        "columnWidth": parseInt(fullWidth / 14),
        "topPlayerMarginTop": parseInt(fullHeight/16*3),
        "contentMarginTop": fullHeight/16*4.5,
        "contentMarginLeft": parseInt(fullWidth / 14 * 2.5),
        "contentWidth": parseInt(fullWidth / 14 * 9),
        "contentHeight": fullHeight/16*8,
        "rowHeight": fullHeight/16,
        "playerAvatarRowHeight": fullHeight/16*6,
        "playerAvatarRowWidth": parseInt(fullWidth / 14 * 12),
        "playerAvatarRowHeightInCol": fullHeight/16*11,
        "playerAvatarRowWidthInCol": parseInt(fullWidth / 14 * 12),
        "playerResultRowHeight": fullHeight/16*7/props.matchNumber,
        "borderDebug": props.config["border"]?"1px solid #000000":"0px solid #000000",
        "config": props.config,
        "backgroundColor": props.config["backgroundColor"],
        "resultColor": props.config["resultColor"],
    }
    const classes = useStyles(theme);

    var gameId = props.currentMatch * 10 + props.currentGame

    console.log("PlayerView props:", props)

    if (Object.keys(props.playerAName).length === 0){
        return (
            <div>

            </div>
        )
    }


    var swapped = props.status["swapped"] || false
    
    let playerAUrl = props.imageUrl+ (!swapped?"/player_b_logo_":"/player_a_logo_")+props.currentMatch+"?random="+props.seed
    let playerBUrl = props.imageUrl+ (swapped?"/player_b_logo_":"/player_a_logo_")+props.currentMatch+"?random="+props.seed

    return (
        <div className={classes.pageContent}>  
            <div className={classes.backGround}>
                <img className={classes.backgroundImg} src={props.imageUrl+ "/background_image?random="+props.seed}/>
            </div> 

            {!props.config["verticalView"] && <div className={classes.teamResultRow}>
                <div className={classes.teamAvatarDiv}>
                    <Button
                        color="secondary"
                        component="label"
                        >
                        <Avatar
                            alt={props.teamAName}
                            src={props.imageUrl+ (swapped?"/team_b_logo?random=":"/team_a_logo?random=")+props.seed}
                            className={classes.teamAvatar}
                        />
                        <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "team_a_logo"); e.target.value=''} } />
                    </Button>  
                </div> 
                <div className={classes.teamAvatarDiv}>
                    <Button
                        color="secondary"
                        component="label"
                        >
                        <Avatar
                            alt={props.teamBName}
                            src={props.imageUrl+ (swapped?"/team_a_logo?random=":"/team_b_logo?random=")+props.seed}
                            className={classes.teamAvatar}
                        />
                        <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "team_b_logo"); e.target.value=''} } />
                    </Button>  
                </div> 
            </div> }

            {!props.config["verticalView"] && <div className={classes.playerAvatarRow} >
                <div className={classes.playerBigAvatarAndName} style={{justifyContent: "center"}}>
                    <div className={classes.topPlayerAName}>
                        <ExtendedNameFild name={(swapped?props.playerBName[props.currentMatch]:props.playerAName[props.currentMatch])} fontSize={theme.rowHeight * 0.5}/>
                    </div>
                    <div className={classes.playerBigAvatarDiv}>
                        <Button
                            color="secondary"
                            component="label"
                            >
                            <Avatar
                                alt={props.playerAName[props.currentMatch]}
                                src={playerBUrl}
                                className={classes.playerBigAvatar}
                            />
                            <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "player_a_logo_"+props.currentMatch); e.target.value=''} } />
                        </Button>  
                    </div> 
                    {false && <div className={classes.bottomPlayerResult}>
                        {"" + props.matchSummary[props.currentMatch][0]  }
                    </div>}
                </div>
                <div className={classes.playerBigAvatarAndName} style={{justifyContent: "center"}}>
                    <div className={classes.topPlayerBName}>
                        <ExtendedNameFild name={(!swapped?props.playerBName[props.currentMatch]:props.playerAName[props.currentMatch])} fontSize={theme.rowHeight * 0.5}/>
                    </div>
                    <div className={classes.playerBigAvatarDiv}>
                        <Button
                            color="secondary"
                            component="label"
                            >
                            <Avatar
                                alt={props.playerBName[props.currentMatch]}
                                src={playerAUrl}
                                className={classes.playerBigAvatar}
                            />
                            <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "player_b_logo_"+props.currentMatch); e.target.value=''} } />
                        </Button>  
                    </div>
                    {false && <div className={classes.bottomPlayerResult}>
                        {"" + props.matchSummary[props.currentMatch][1] }
                    </div>}
                </div>
            </div>}

            {props.config["verticalView"] && <div className={classes.playerAvatarCol} >
                <div className={classes.playerBigAvatarAndNameInCol} style={{justifyContent: "center"}}>
                    
                    <div className={classes.playerBigAvatarDivInCol}>
                        <Button
                            color="secondary"
                            component="label"
                            >
                            <Avatar
                                alt={props.playerAName[props.currentMatch]}
                                src={playerAUrl}
                                className={classes.playerBigAvatarInCol}
                            />
                            <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "player_a_logo_"+props.currentMatch); e.target.value=''} } />
                        </Button>  
                    </div> 

                    <div className={classes.topPlayerANameInCol}>
                        <ExtendedNameFild name={(!swapped?props.playerBName[props.currentMatch]:props.playerAName[props.currentMatch])} fontSize={theme.rowHeight * 0.5}/>
                    </div>
                    <div className={classes.teamAvatarDiv}>
                        <Button
                            color="secondary"
                            component="label"
                            >
                            <Avatar
                                alt={props.teamAName}
                                src={props.imageUrl+ (swapped?"/team_a_logo?random=":"/team_b_logo?random=")+props.seed}
                                className={classes.teamAvatar}
                            />
                            <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "team_a_logo"); e.target.value=''} } />
                        </Button>  
                    </div> 
                </div>
                <div className={classes.playerBigAvatarAndNameInCol} style={{justifyContent: "center"}}>
                    <div className={classes.playerBigAvatarDivInColLeft}>
                        <Button
                            color="secondary"
                            component="label"
                            >
                            <Avatar
                                alt={props.playerBName[props.currentMatch]}
                                src={playerBUrl}
                                className={classes.playerBigAvatarInColLeft}
                            />
                            <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "player_b_logo_"+props.currentMatch); e.target.value=''} } />
                        </Button>  
                    </div>
                    <div className={classes.topPlayerBNameInCol}>
                        <ExtendedNameFild name={(swapped?props.playerBName[props.currentMatch]:props.playerAName[props.currentMatch])} fontSize={theme.rowHeight * 0.5}/>
                    </div>
                    <div className={classes.teamAvatarDiv}>
                        <Button
                            color="secondary"
                            component="label"
                            >
                            <Avatar
                                alt={props.teamBName}
                                src={props.imageUrl+ (!swapped?"/team_a_logo?random=":"/team_b_logo?random=")+props.seed}
                                className={classes.teamAvatar}
                            />
                            <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "team_b_logo"); e.target.value=''} } />
                        </Button>  
                    </div> 
                </div>
            </div>}
        </div>
    )
}

export default ControlPlayerView;


const useStyles = makeStyles({
    pageContent: props => ({
        //margin: "0px",
        position: "relative",
        //backgroundSize: 'contain',
        //backgroundImage: 'url("https://api.kandolab.com/api/v2/cameras/6dee49ec-4b10-4029-97a4-7da47a21e67b/layer/1?preview=true&filename=c9l0t1665854829_all_patrocinadores.gif")',
        width:  props.width,
        height: props.height, 
    }),
    backGround: props => ({
        position: "absolute",
        left: 0,
        top: 0,
        width: props.width,
        height: props.height 
    }),
    backgroundImg: props => ({
        width: props.width,
        height: props.height,
        display: props.config["backgroundImageInPlayerV"]?"block":"none",
    }),
    playerAvatarRow: props => ({
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        left: (props.width/2 - props.playerAvatarRowWidth/2),
        top: (props.height/2 - props.playerAvatarRowHeight/2),
        width: props.playerAvatarRowWidth,
        height: props.playerAvatarRowHeight,
        border: props.borderDebug,
        justifyContent: "space-between",
        alignItems: "center",
        //backgroundColor: props.config["backgroundFilled"]?props.backgroundColor:"transparent"
    }),
    playerBigAvatarAndName: props => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: props.columnWidth * 4,
        height: props.rowHeight * 1.5,
    }),
    topPlayerAName: props => ({
        textAlign: "left",
        fontFamily: "Arial, Helvetica, sans-serif",
        marginLeft: props.rowHeight/5,
        fontSize: props.rowHeight * 0.5,
        fontWeight: "bold",
        textTransform: 'uppercase',
        padding: "0.25em",
        color: "#000000",
        backgroundColor: "#ffffff",
        display: props.config["playerName"]?"block":"none",
    }),
    topPlayerBName: props => ({
        textAlign: "right",
        fontFamily: "Arial, Helvetica, sans-serif",
        marginRight: props.rowHeight/5,
        fontSize: props.rowHeight * 0.5,
        fontWeight: "bold",
        color: "#000000",
        padding: "0.25em",
        backgroundColor: "#ffffff",
        textTransform: 'uppercase',
        display: props.config["playerName"]?"block":"none",
    }),
    playerBigAvatarDiv: props => ({
        width: props.rowHeight*7,
        height: props.rowHeight*7,
    }),
    playerBigAvatar: props => ({
        width: props.rowHeight*7*5/6,
        height: props.rowHeight*7*5/6,
        marginTop: "3px",
        backgroundColor: "#ffffff",
    }),

    teamResultRow: props => ({
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        left: props.teamMarginLeft,
        top: props.teamMarginTop,
        textAlign: "center",
        
        justifyContent: "space-between",
        alignItems: "center",
        height: props.rowHeight * 1.5,
        width: props.teamContentWidth,
        border: props.borderDebug,
    }),

    teamAvatarDiv: props => ({
        width: props.rowHeight*2.5,
        height: props.rowHeight*2.5,
        display: props.config["teamLogo"]?"block":"none",
    }),
    teamAvatar: props => ({
        width: props.rowHeight*2.5*5/6,
        height: props.rowHeight*2.5*5/6,
        marginTop: "3px",
        backgroundColor: "#ffffff",
    }),

    bottomPlayerResult: props => ({
        textAlign: "center",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontWeight: "bold",
        fontSize: props.rowHeight * 0.7,
        padding: "0.15em",
        marginTop: "8px",
        width: props.columnWidth/2,
        marginLeft: props.columnWidth/2,
        marginRight: props.columnWidth/2,
        backgroundColor: props.config["backgroundFilled"]?props.resultColor:"transparent"
    }),

    // Vertical view
    playerAvatarCol: props => ({
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: (props.width/2 - props.playerAvatarRowWidthInCol/2),
        top: (props.height/2 - props.playerAvatarRowHeightInCol/2),
        width: props.playerAvatarRowWidthInCol,
        height: props.playerAvatarRowHeightInCol,
        border: props.borderDebug,
        justifyContent: "space-between",
        alignItems: "center",
        //backgroundColor: props.config["backgroundFilled"]?props.backgroundColor:"transparent"
    }),
    playerBigAvatarAndNameInCol: props => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: props.columnWidth * 10,
        height: props.rowHeight * 1.5,
    }),
    topPlayerANameInCol: props => ({
        textAlign: "left",
        fontFamily: "Arial, Helvetica, sans-serif",
        marginLeft: props.rowHeight/5,
        fontSize: props.rowHeight * 0.5,
        fontWeight: "bold",
        textTransform: 'uppercase',
        padding: "0.25em",
        color: "#000000",
        backgroundColor: "#ffffff",
        display: props.config["playerName"]?"block":"none",
    }),
    topPlayerBNameInCol: props => ({
        textAlign: "right",
        fontFamily: "Arial, Helvetica, sans-serif",
        marginRight: props.rowHeight/5,
        fontSize: props.rowHeight * 0.6,
        fontWeight: "bold",
        color: "#000000",
        padding: "0.25em",
        backgroundColor: "#ffffff",
        textTransform: 'uppercase',
        display: props.config["playerName"]?"block":"none",
    }),
    playerBigAvatarDivInCol: props => ({
        width: props.rowHeight*3,
        height: props.rowHeight*3,
    }),
    playerBigAvatarInCol: props => ({
        width: props.rowHeight*3*5/6,
        height: props.rowHeight*3*5/6,
        marginTop: "3px",
        backgroundColor: "#ffffff",
    }),
    playerBigAvatarDivInColLeft: props => ({
        width: props.rowHeight*3.5,
        height: props.rowHeight*3.5,
    }),
    playerBigAvatarInColLeft: props => ({
        width: props.rowHeight*3.5*5/6,
        height: props.rowHeight*3.5*5/6,
        marginTop: "3px",
        backgroundColor: "#ffffff",
    }),
    //

});