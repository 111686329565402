import React, { useContext,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as TShirt } from '../statics/images/t-shirt.svg';

import { Modal, Fade, Backdrop } from '@material-ui/core';

import '@fontsource/roboto';

import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import logo from '../statics/images/kandolab-logo_v1.png';
import { GameContext } from '../entities/GameContext';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from './components/Alert';

import TshirtColorSelector from './components/TshirtColorSelector';

function TeamScoreboardSetup() {
    const {
        setGameType, setSetNumber,
        serveA, setServeA,setCurrentMatch,
        setStarted,
        playerAName, setPlayerAName,
        playerBName, setPlayerBName,
        setPlayerAPoint,setPlayerBPoint,
        setCurrentGame,
        setMatchNumber,
        teamAColour, setTeamAColour,
        teamBColour, setTeamBColour, 
        teamAName, teamBName, 
        setTeamAName, setTeamBName,
        playerATimeout, setPlayerATimeout,
        playerBTimeout, setPlayerBTimeout,
        gameTypeLabel,
        stageStatus, setStageStatus,
        setPlayerACards, setPlayerBCards,
        setPointTracking,
    } = useContext(GameContext);
    
    const classes = useStyles();

    const [ message, setMessage ] = useState(null)
    const [ colourSelector, setColourSelector ] = useState(false)
    const [ colourForA, setColourForA ] = useState(true)

    const handleStart = () => {
        if (teamAName.length == 0) {
            setMessage("Team A is missing")
            return
        }
        if (teamBName.length == 0) {
            setMessage("Team B is missing")
            return
        }
        setTeamAColour(teamAColour)
        setTeamBColour(teamBColour)

        setPlayerAName({0:teamAName})
        setPlayerBName({0:teamBName})
        
        setPlayerAPoint({0:{0:0}})
        setPlayerBPoint({0:{0:0}})
        setServeA({0:true});
        setCurrentGame(0);
        setCurrentMatch(0);
        setSetNumber(5);
        setMatchNumber(7)
        setPlayerATimeout({0:false});
        setPlayerBTimeout({0:false});
        setPlayerACards({0:0});
        setPlayerBCards({0:0});
        setPointTracking([])

        setStarted(true);
    };

    const handleSelect = (event) => {
        setGameType(-1)
    };

    const handleTeamAName = (event) => {
        setTeamAName(event.target.value)
    };
    const handleTeamBName = (event) => {
        setTeamBName(event.target.value)
    };

    const handleErrorBarClose = () => {
        setMessage(null)
    }

    const stageChangeInputHandle = (stage) => {
        setStageStatus(stage)
    }

    const handleCloseColourSelector = () =>{
        setColourSelector(false)
    }
    
    const handleColourSelectA = () => {
        setColourForA(true)
        setColourSelector(true)
    }
    const handleColourSelectB = () => {
        setColourForA(false)
        setColourSelector(true)
    }

    const handleColourSelected = (colour) =>{
        if (colourForA){
            setTeamAColour(colour)
        }else{
            setTeamBColour(colour)
        }
        setColourSelector(false)
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar} >
                <Toolbar>
                    <IconButton edge="start" 
                        className={classes.menuButton} 
                        color="inherit" 
                        aria-label="menu"
                        onClick={handleSelect} >
                        <ArrowBackIosIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                    {gameTypeLabel}
                    </Typography>
                    <img className={classes.logoImg} src={logo} width="30px"/>
                </Toolbar>
            </AppBar>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={colourSelector}
                onClose={handleCloseColourSelector}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 1000,
                }}
            >
                <Fade in={colourSelector}>
                    <div className={classes.colourDialog}>
                        <h2 style={{marginBottom: "0px"}}>Color de Camisa</h2>

                        <TshirtColorSelector setColor={handleColourSelected} />
                        <Button 
                            variant="contained" color="secondary" onClick={handleCloseColourSelector}
                            startIcon={<CloseIcon />}>
                             Close
                        </Button>
                    </div>
                </Fade>
            </Modal>

            <Grid container xs={12} className={classes.pageContent} spacing={0} justify="center" direction="row" >     
                <Grid container xs={12} className={classes.content} justify="center" direction="column">
                    <form className={classes.contentPlayerName} noValidate autoComplete="off" >
                        <Grid container className={classes.contentPlayerName} justify="center" direction="column">
                            <Grid container justify="center" >
                                <Grid item>
                                    <TextField 
                                        required 
                                        size="small" 
                                        label="Team A" 
                                        id="input-player-a-name"  
                                        variant="outlined"
                                        value={teamAName || ""}
                                        onChange={handleTeamAName}
                                        className={classes.teamNameField}
                                        inputProps={{style: {textTransform: 'uppercase'}}}
                                        /> 
                                </Grid>
                                <Grid item>
                                    <TShirt width='45px' fill={teamAColour} stroke="black" onClick={()=>{handleColourSelectA()}}  />
                                </Grid>
                            </Grid>
                            <Grid container justify="center" >
                                <Grid item>
                                    <TextField 
                                        required 
                                        size="small" 
                                        id="input-player-b-name" 
                                        label="Team B" 
                                        variant="outlined"
                                        value={teamBName || ""}
                                        className={classes.teamNameField}
                                        inputProps={{style: {textTransform: 'uppercase'}}}
                                        onChange={handleTeamBName}/>
                                </Grid>
                                <Grid item>
                                    <TShirt width='45px' fill={teamBColour} stroke="black" onClick={()=>{handleColourSelectB()}}  />
                                </Grid>

                            </Grid>
                            <Grid container justify="center" className={classes.actionDiv}>
                                <Grid item xs={12} sm={4} > 
                                    <TextField 
                                        size="small" 
                                        label="Competition Stage"
                                        id="input-game-stage" 
                                        value={stageStatus}
                                        variant="outlined"
                                        onChange={(e)=>{stageChangeInputHandle(e.target.value)}}
                                        inputProps={{
                                            style: {textAlign:"right", color:"black", textTransform: 'uppercase'}
                                        }}
                                        />
                                </Grid> 
                            </Grid>
                            <Grid container justify="center" className={classes.actionDiv}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.startButton}
                                        onClick={()=>{handleStart()}} 
                                        endIcon={<PlayCircleOutlineIcon />}
                                    >
                                        Start
                                    </Button>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    </form>

                    <Snackbar open={message != null} autoHideDuration={3000} onClose={handleErrorBarClose}>
                        <Alert onClose={handleErrorBarClose} saverity="error" >
                            {message}
                        </Alert>
                    </Snackbar>

                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center"  className={classes.copyright}>
                    &#xa9; 2023 KandoLab.com
                    </Grid>
                </Grid>
            </Grid>
            
        </div>
    );
}

export default TeamScoreboardSetup;

const useStyles = makeStyles((theme) => ({
    pageContent:{
        //margin: "0px",
        marginTop: "20px",
    },
    actionDiv: {
        marginTop: "30px",
    },
    appBar: { 
        background: '#ff50c2',
        color: 'white' ,
    },
    content: {
        marginRight: "0px",
        marginLeft: "0px",
        textAlign:"center",
    },
    contentPlayerName: {
        marginRight: "0px",
        marginLeft: "0px",
        marginTop: "0px", 
        textAlign:"center",
    },
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    copyright: {
        color: "#BBBBBB",
        paddingTop: "50px"
    },
    setNumberGrid:{
        paddingTop: "20px",
    },
    startButtonGrid: {
        paddingTop: "20px"
    },
    startButton: {
        borderRadius: "20px",
        marginTop: "10px",
        marginLeft: "20%",
        marginRight: "20%",
        paddingLeft:"30px",
        paddingRight:"30px",
    },
    teamNameField: {
        marginTop: "10px",
        marginLeft: "10px",
        marginRight: "10px",
    },
    logoImg:{
        paddingRight:"5px"
    },
    appbar: {
        background : '#000000',
    },
    formControlLabel: {
        fontSize:'12px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tShirt: {
        marginLeft: "2px",
        marginRight: "2px",
        width: '45px',
    },
    tShirtArray:{
        marginTop: "10px",
        marginBottom: "15px"
    },
    colourDialog: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #999',
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 7, 3),
        textAlign: "center"
    }
  }));
