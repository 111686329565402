import React, { useRef } from 'react';
import Flags from "country-flag-icons/react/3x2";
import { hasFlag } from 'country-flag-icons'

import NAV from '../../statics/images/flags/320px-Bandera_de_Navarra.svg.png'
import MAN from '../../statics/images/flags/Flag_of_Castile-La_Mancha.svg.png'
import BAS from '../../statics/images/flags/Flag_of_the_Basque_Country.svg.png'
import AND from '../../statics/images/flags/320px-Flag_of_Andalucia.svg.png'
import LEO from '../../statics/images/flags/Flag_of_Castile_and_Leon.svg.png'
import CAN from '../../statics/images/flags/Flag_of_the_Canary_Islands.svg.png'
import AST from '../../statics/images/flags/320px-Flag_of_Asturias.svg.png'
import CAT from '../../statics/images/flags/Flag_of_Catalonia.svg.png'
import MAD from '../../statics/images/flags/Flag_of_the_Community_of_Madrid.svg.png'
import CANT from '../../statics/images/flags/320px-Flag_of_Cantabria.svg.png'
import EXT from '../../statics/images/flags/Flag_of_Extremadura_Spain.svg.png'
import MUR from '../../statics/images/flags/Flag_of_the_Region_of_Murcia.svg.png'
import GAL from '../../statics/images/flags/320px-Flag_of_Galicia.svg.png'
import RIO from '../../statics/images/flags/Flag_of_La_Rioja_with_coat_of_arms.svg.png'
import VAL from '../../statics/images/flags/Flag_of_the_Valencian_Community_2x3.svg.png'
import ARA from '../../statics/images/flags/Flag_of_Aragon.svg.png'
import BAL from '../../statics/images/flags/Flag_of_the_Balearic_Islands.svg.png'

function ExtendedNameFild (props) {
    const fields = props.name.split(' ')
    const fontSize = props.fontSize || 20

    //console.log(props.name, fields)
    const flagList = {
        "ESNAV": NAV,
        "ESMAN": MAN,
        "ESBAS": BAS,
        "ESAND": AND,
        "ESLEO": LEO,
        "ESCAN": CAN,
        "ESAST": AST,
        "ESCAT": CAT,
        "ESMAD": MAD,
        "ESCANT": CANT,
        "ESEXT": EXT,
        "ESMUR": MUR,
        "ESGAL": GAL,
        "ESRIO": RIO,
        "ESVAL": VAL,
        "ESARA": ARA,
        "ESBAL": BAL,
    }
    
    
    return (
        <div>
            {
                fields.map((field, index) => {
                    if (field.startsWith("#")) {
                        let flag = field.substring(1).toUpperCase()
                        if (hasFlag(flag)) {
                            var FlagA = Flags[flag]
                            return (<FlagA style={{width:fontSize+2, paddingRight:fontSize/4, paddingLeft:fontSize/4}}/>)
                        }else{
                            if (flag in flagList) {
                                return (<img src={flagList[flag]} style={{width:fontSize+2, paddingRight:fontSize/4, paddingLeft:fontSize/4}}/>)
                            }else{
                                return (<strong style={{paddingRight:fontSize/4, paddingLeft:fontSize/4, }}>{flag}</strong>)
                            }
                        }
                    }else{
                        return field + " "
                    }
                })
            }
        </div>
    )
}

export default ExtendedNameFild;
