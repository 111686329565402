import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import '@fontsource/roboto';
import GameSelect from './GameSelect';
import { GameContext } from '../entities/GameContext';
import Individual from './Individual';
import Group from './Group'
import TeamScoreboard from './TeamScoreboard';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
      padding: theme.spacing(3, 2),
      position: "absolut",
      top: "30%",
      transform: "translateY(30%)",
    },
  }));

function Home(props) {
    const { gameType, setCloud } = useContext(GameContext);

    useEffect(() => {
      setCloud(props.isCloud)
    })
    
    //console.log("Home gameType:", gameType)
    if (gameType === -1) {
        return (<GameSelect />);
    }else if (gameType === 0 ) {
        return (<Individual />);
    }else if (gameType === 1 ) {
      return (<Group />);
    }else {
      return (<TeamScoreboard />);
    }
}

export default Home;