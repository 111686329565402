//const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEVEL : (window.API_URL == "BACKEND_API_URL_PROD"? process.env.API_URL: window.API_URL)  
const baseURLV1 = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_V1_URL_DEVEL : process.env.REACT_APP_API_V1_URL
const baseURLV2 = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_V2_URL_DEVEL : process.env.REACT_APP_API_V2_URL

export async function syncStatus(id, body) {
    return fetch(baseURLV1 + '/api/v1/direct/'+id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(response => {
        if (response.ok) {
            return null
        }else{
            throw new Error("Wrong Response Code")
        }
    }).catch((err) => {
        throw err
    })
}

export async function pushTrace(id, label, summary, detail, signal=null) {
    let body = {
        "label": label, 
        "summary": summary,
        "detail": detail
    }

    return fetch(baseURLV2 + '/api/v2/cameras/trace/'+id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
      signal: signal
    }).then(response => {
        if (response.ok) {
            return null
        }else{
            throw new Error("Wrong Response Code")
        }
    }).catch((err) => {
        throw err
    })
}

/**
 * Fetches the scoreboard configuration for a given ID from the backend API.
 * @param {number} id - The ID of the scoreboard configuration to fetch.
 * @returns {Promise<Object>} - A promise that resolves to the scoreboard configuration object.
 * @throws {Error} - If the response code is not OK.
 */
export async function getScoreboardConfig(id) {
  return fetch(baseURLV2 + '/api/v2/cameras/scoreboard_config/'+id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => {
      if (response.ok) {
          return response.json()
      }else{
          throw new Error("Wrong Response Code")
      }
  }).catch((err) => {
      throw err
  })
}

export async function uploadAssetImage(url, file) {
    var data = new FormData()
    data.append('file', file)
  
    return fetch(url, {
      method: 'POST',
      body: data
    }).then((res) => {
        console.log(res)
        if (res.ok && res.status === 200) {
          return res.json()
        }else{
          return null
        }
      }
    ).catch(() => {
      return null
    })
}


export async function getStatus(id) {
    return fetch(baseURLV1 + '/api/v1/direct/'+id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
        if (response.ok) {
            return response.json()
        }else{
            throw new Error("Wrong Response Code")
        }
    }).catch((err) => {
        throw err
    })
}