import React, { useRef, useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Box, IconButton } from '@material-ui/core';
import Alert from './components/Alert';
import Snackbar from '@material-ui/core/Snackbar';


import logo from '../statics/images/kandolab-logo_v1.png';

import '@fontsource/roboto';
import Grid from '@material-ui/core/Grid';

import GridOn from '@material-ui/icons/GridOn';
import PanoramaIcon from '@material-ui/icons/Panorama';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RefreshIcon from '@material-ui/icons/Refresh';

import ControlGeneralStatus from './components/ControlGeneralStatus';
import ControlPhotoView from './components/ControlPhotoView';
import PlayerNames from './components/PlayerNames'
import html2canvas from "html2canvas";
import exportAsImage from '../utils/exportAsImage';
import exportAsImageAndUpload from '../utils/exportAsImageAndUpload'
import {uploadAssetImage} from '../services/backendapi'
import DetailsIcon from '@material-ui/icons/Details';
import { pushTrace } from '../services/backendapi'
import AutorenewIcon from '@material-ui/icons/Autorenew';

import ReactNoSleep from 'react-no-sleep';

import {useLocalStorage} from "react-use-storage";

function BestPointDashboard(props) {
    const classes = useStyles();
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackMessage, setSnackMessage] = useState(null)

    const [pressCounter, setPressCounter] = useState(0);
    const [homeCounter, setHomeCounter] = useLocalStorage("best_point_home_counter", 0);
    const [guestCounter, setGuestCounter] = useLocalStorage("best_point_guest_counter", 0);

    const [timer, setTimer] = useState(null)

    const msgBodyRef = useRef(null);
    const statusToReportRef = useRef(null);

    const [msgBody, setMsgBody] = useState(null)
    const [statusToReport, setStatusToReport] = useState(null)

    const [lastReportTime, setLastReportTime] = useState(null)

    const TIMER_INTERVAL = 5000

    useEffect(() => {
        msgBodyRef.current = msgBody;
    }, [msgBody]);

    useEffect(() => {
        statusToReportRef.current = statusToReport;
    }, [statusToReport]);

    const reset = () => {
        setHomeCounter(0)
        setGuestCounter(0)
        setPressCounter(0)
        setSnackMessage("Reset all counters")
        setOpenSnackbar(true)
    }

    const handleBestPoint = (label) => {
        let id = props.scoreboard.id
        console.log("Best Point", id, label, props.status, props.scoreboard)

        if (!id) return;
        if (id == "") return;

        var counter = 0

        let now = Date.now()
        var finalLabel = label

        if (lastReportTime && (now - lastReportTime) < TIMER_INTERVAL){
            counter = pressCounter + 1
            setPressCounter(pressCounter+1)
            if (label =="HOME"){
                finalLabel = label + " " + homeCounter
            }else{
                finalLabel = label + " " + guestCounter
            }
            if (counter > 7) return;
        }else{
            if ("vibrate" in navigator) {
                navigator.vibrate([80,30,80]);
            }
            if (label =="HOME"){
                finalLabel = label + " " + (homeCounter+1)
                setHomeCounter(homeCounter+1)
            }else{
                finalLabel = label + " " + (guestCounter+1)
                setGuestCounter(guestCounter+1)
            }
            counter = 1
            setPressCounter(1)
            setLastReportTime(now)
        }
        

        let date = new Date()
        var body = null
        if (props.status){
            console.log("Status available: ", props.status)
            let pointA ={}
            let pointB ={}
            pointA[props.status.currentGame] = props.scoreboard.pointA
            pointB[props.status.currentGame] = props.scoreboard.pointB
            body = {
                "playerA": props.scoreboard.playerA || "", 
                "playerB": props.scoreboard.playerB || "", 
                "match": props.status.currentMatch, 
                "game": props.status.currentGame,
                "pointA": pointA, 
                "pointB": pointB,
                "label": finalLabel,
                "home_counter": homeCounter,
                "guest_counter": guestCounter,
                "local_time": date.toISOString(),
                "utc_time": date.toUTCString(),
            }

            setStatusToReport(props.status)
        }else{
            console.log("Status no available: ", label, counter, date)
            let pointA ={}
            let pointB ={}
            pointA[0] = 0
            pointB[0] = 0
            body = {
                "playerA": "A", 
                "playerB": "B", 
                "match": 0, 
                "game": 0,
                "pointA": pointA, 
                "pointB": pointB,
                "label": finalLabel,
                "home_counter": homeCounter,
                "guest_counter": guestCounter,
                "local_time": date.toISOString(),
                "utc_time": date.toUTCString(),
            }
            setStatusToReport({"msg": "No status available"})
        }

        setMsgBody(body)

        // Start a new timer that connects to the REST API after a specified time (e.g., 5000 milliseconds).
        // Using timer to send report can have problems with user that switch off the phone, after report.
        // So we decided to not use this method. Instead we use the lastReportTime to avoid counting home and
        // guest counters. But we send to the backend each time the button is pressed.
        /*
        const newTimer = setTimeout(() => {
                setTimer(null)
            }, TIMER_INTERVAL);
        setTimer(newTimer); 
        scopedAsyncFunc(id, label, statusToReportRef.current, msgBodyRef.current);
        */
        async function scopedAsyncFunc(id, label, statusToReport, msgBody){
            const controller = new AbortController();
            const signal = controller.signal;

            setTimeout(() => controller.abort(), 5000); // Abort after 5 seconds
            console.log("scopedAsyncFunc", id, label, statusToReport, msgBody)
            try{
                await pushTrace(id, "bestpoint", msgBody, statusToReport, signal)
            }catch(e) {
                console.log("Error happend:", e)
            }
        }
        //console.log("Timer", id, label, statusToReport, msgBody)
        scopedAsyncFunc(id, label, statusToReport, msgBody);
    }

    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={()=>{setOpenSnackbar(false)}}>
                <Alert onClose={()=>{setOpenSnackbar(false)}}
                    severity="warning"
                    sx={{ width: '100%' }}>
                {snackMessage}
                </Alert>
            </Snackbar>
            <Grid container xs={12} className={classes.content} spacing={0} alignItems="center" justify="center"> 
                <Grid container xs={12} alignItems="center" justify="center">
                    <img className={classes.logoImg} src={logo} width="50px"/><h3 style={{}}>KandoLAB</h3>
                </Grid>
                <Grid container xs={12} alignItems="center" justify="center">
                    {props.status &&<Grid container xs={12} sm={10} className={classes.row} spacing={0} justify="center" >
                         <PlayerNames
                            leftPlayerName={!props.status.swapped?props.scoreboard.playerA:props.scoreboard.playerB}
                            rightPlayerName={!props.status.swapped?props.scoreboard.playerB:props.scoreboard.playerA}
                            leftPlayerGamePoint={!props.status.swapped?props.scoreboard.pointA:props.scoreboard.pointB}
                            rightPlayerGamePoint={!props.status.swapped?props.scoreboard.pointB:props.scoreboard.pointA}
                            leftPlayerColour={!props.status.swapped?props.scoreboard.teamAColor:props.scoreboard.teamBColor}
                            rightPlayerColour={!props.status.swapped?props.scoreboard.teamBColor:props.scoreboard.teamAColor}
                            leftInputLabel={props.status.gameType==0?"Left":(!props.status.swapped)?props.scoreboard.teamAName:props.scoreboard.teamBName}
                            rightInputLabel={props.status.gameType==0?"Right":props.status.swapped?props.scoreboard.teamAName:props.scoreboard.teamBName}
                            nameOnly={false}
                            />
                    </Grid>}
                    <Grid container xs={12} className={classes.row} justifyContent="center" spacing={2}  alignItems="center">
                        <Grid item xs={12} sm={4} md={4} lg={2} >
                        <   ReactNoSleep>
                                {({ isOn, enable, disable }) => (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        className={classes.button}
                                        onClick={()=>{handleBestPoint("HOME"); if(!isOn){enable();};  }}  >
                                        <Box>
                                            <div style={{marginTop: "20px", color:"#dddddd"}}>Home</div>
                                            <div style={{marginTop: "-30px"}}><h1>{homeCounter}</h1></div>
                                        </Box> 
                                    </Button>)}
                            </ReactNoSleep>
                            
                        </Grid>
                        <Grid item xs={12} sm={4}  md={4} lg={2} >
                            <ReactNoSleep>
                                {({ isOn, enable, disable }) => (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                    onClick={()=>{handleBestPoint("GUEST"); if(!isOn){enable();};  }} >
                                    <Box>
                                        <div style={{marginTop: "20px", color:"#dddddd"}}>Guest</div>
                                        <div style={{marginTop: "-30px"}}><h1>{guestCounter}</h1></div>
                                    </Box>
                                </Button>)}
                            </ReactNoSleep>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className={classes.rowBottom} justifyContent="center" spacing={2}  alignItems="center">
                        <IconButton
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={()=>{reset()}} >
                            <AutorenewIcon fontSize="large" />
                        </IconButton>
                    </Grid>
                    <Grid container xs={12} className={classes.rowBottom} justifyContent="center" spacing={2}  alignItems="center">
                        <Grid container justify="center"  className={classes.copyright}>
                        &#xa9; 2023 KandoLab.com (v1.0)
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default BestPointDashboard;

const useStyles = makeStyles((theme) => ({
    content: {
      marginTop: "0px",
      textAlign:"center",
      height: "85vh",
    },
    row: {
        marginTop: "10px",
        marginBottom: "0px",
        marginLeft: "10px",
        marginRight: "10px",
    },
    rowBottom: {
        marginTop: "0px",
        marginBottom: "0px",
    },
    button: {
        borderRadius: "80px",
        paddingLeft:"0px",
        paddingRight:"0px",
        width: "160px",
        height: "160px",
    },
    copyright: {
        color: "#BBBBBB",
        paddingTop: "20px"
    },
}));