import { NightsStayOutlined, NoEncryptionOutlined } from "@material-ui/icons";
import React, { createContext, useState } from "react"
// import useLocalStorage  from "./hooks/useSessionStorage"
import {useSessionStorage} from "react-use-storage";

export const GameContext = createContext()

export default ({ children }) => {

	const [token, setToken] = useSessionStorage("token", {});
	const [gameType, setGameType] = useSessionStorage("game_type", -1);  // -1: not selected, 0: individual 1: group

    const [setNumber, setSetNumber] = useSessionStorage("set_number", 5);
	const [matchNumber, setMatchNumber] = useSessionStorage("match_number", 6);

	const [teamAName, setTeamAName] = useSessionStorage("team_a_name", "");
	const [teamBName, setTeamBName] = useSessionStorage("team_b_name", "");

	const [doubleMatchNumber, setDoubleMatchNumber] = useSessionStorage("double_match_number", -1);
	const [doubleInitialConfig, setDoubleInitialConfig] = useSessionStorage("double_config", null);

	const [teamAColour, setTeamAColour] = useSessionStorage("team_a_colour", "#ffffff");
	const [teamBColour, setTeamBColour] = useSessionStorage("team_b_colour", "#ffffff");

	const [playerAName, setPlayerAName] = useSessionStorage("player_a_name", {});
	const [playerBName, setPlayerBName] = useSessionStorage("player_b_name", {});
	const [playerATimeout, setPlayerATimeout] = useSessionStorage("player_a_timeout", {});
	const [playerBTimeout, setPlayerBTimeout] = useSessionStorage("player_b_timeout", {});

	const [expediteMode, setExpediteMode] = useSessionStorage("expedite_mode", false);
	const [playerACards, setPlayerACards] = useSessionStorage("player_a_cards", {});
	const [playerBCards, setPlayerBCards] = useSessionStorage("player_b_cards", {});
	const [pointTracking, setPointTracking] = useSessionStorage("point_tracking", []);
	
	const [playerAPoint, setPlayerAPoint] = useSessionStorage("player_a_point", {});
	const [playerBPoint, setPlayerBPoint] = useSessionStorage("player_b_point", {});

	const [currentMatch, setCurrentMatch] = useSessionStorage("current_match", 0);
	const [currentGame, setCurrentGame] = useSessionStorage("current_game", 0);
	
	const [serveA, setServeA] = useSessionStorage("serve_a", true);
    const [started, setStarted] = useSessionStorage("game_started", false);
	const [groupStarted, setGroupStarted] = useSessionStorage("group_started", false);

	const [groupTeamConfig, setGroupTeamConfig] = useSessionStorage('group_team_config', [["A","Y",0,3,4],["B","X",1,5,3],["C","Z",2,4,5]])
	const [playerLabel, setPlayerLabel] = useSessionStorage('player_label',{})
	const [gameTypeLabel, setGameTypeLabel] = useSessionStorage('game_type_label',"")
	const [cloud, setCloud] = useState(false)
	const [uuid, setUuid] = useSessionStorage('uuid',"")

	const [autoMode, setAutoMode] = useSessionStorage('auto_mode',false)
	const [syncPaused, setSyncPaused] = useSessionStorage('sync_paused',false)

	const [swapped, setSwapped] = useSessionStorage("swapped", false)

	const [version, setVersion] = useSessionStorage("version", 0)

	const [stageStatus, setStageStatus] = useSessionStorage("stage_status", 0)

	const toJson = () => {
		var status = {}
        var allMatches = []
        for (let i=0 ; i< 7; i++){
            var match = {}
            match["playerA"] = playerAName[i] || ""
            match["playerB"] = playerBName[i] || ""
            match["playerATimeout"] = playerATimeout[i] || false
            match["playerBTimeout"] = playerBTimeout[i] || false
			match["playerACards"] = playerACards[i] || 0
			match["playerBCards"] = playerBCards[i] || 0
            match["playerAServe"] = (serveA[i]!==null)? serveA[i] : true
            match["points"] = []
            for (let g=0 ; g<5; g++){
                var result = {}
                let matchAP = playerAPoint[i] || {}
                let matchBP = playerBPoint[i] || {}
                result["playerA"] = matchAP[g] || 0
                result["playerB"] = matchBP[g] || 0
                match["points"].push(result)
            }
            allMatches.push(match)
        }
        status["teamA"] = teamAName || ""
        status["teamB"] = teamBName || ""
        status["teamAColour"] = teamAColour || "#ffffff"
        status["teamBColour"] = teamBColour || "#ffffff"
        status["currentGame"] = currentGame
        status["currentMatch"] = currentMatch
        //status["playerA"] = playerAName
        status["swapped"] = swapped
		status["expediteMode"] = expediteMode
		status["pointTracking"] = pointTracking
        status["matches"] = allMatches
		status["version"] = version
		status["uuid"] = uuid

		let control = {}
		control["setNumber"] = setNumber
		control["matchNumber"] = matchNumber
		control["gameType"] = gameType

		control["doubleMatchNumber"] = doubleMatchNumber
		control["doubleInitialConfig"] = doubleInitialConfig
		control["started"] = started
		control["groupStarted"] = groupStarted
		control["groupTeamConfig"] = groupTeamConfig
		control["playerLabel"] = playerLabel
		control["gameTypeLabel"] = gameTypeLabel

		status["control"] = control

		status["stageStatus"] = stageStatus

        return status
	}

	const fromJson = (status) => {

		console.log("Parse from JSON: ", status)

		setTeamAName(status["teamA"] || "")
		setTeamBName(status["teamB"] || "")
		setTeamAColour(status["teamAColour"] || "#ffffff")
		setTeamBColour(status["teamBColour"] || "#ffffff")
		setCurrentGame(status["currentGame"] || 0)
		setCurrentMatch(status["currentMatch"] || 0)

		setSwapped(status["swapped"] || false)

		setExpediteMode(status["expediteMode"] || false)
		setPointTracking(status["pointTracking"] || [])
		
		let matches = status["matches"] || null
		if (matches != null){
			let AName = {}
			let BName = {}
			let serveA = {}
			let ATimeout = {}
			let BTimeout = {}
			let APoints = {}
			let BPoints = {}
			let ACards = {}
			let BCards = {}	
			for (let i=0 ; i<7 ; i++) {
				let m = matches[i] || null
				
				AName[i] = ""
				BName[i] = ""
				serveA[i] = true
				ATimeout[i] = false
				BTimeout[i] = false
				APoints[i] = []
				BPoints[i] = []
				ACards[i] = 0
				BCards[i] = 0

				if (m!=null) {
					AName[i] = m["playerA"] || ""
					BName[i] = m["playerB"] || ""
					serveA[i] = m["playerAServe"] || false
					ATimeout[i] =  m["playerATimeout"] || false
					BTimeout[i] = m["playerBTimeout"] || false
					ACards[i] = m["playerACards"] || 0
					BCards[i] = m["playerBCards"] || 0
					
					let points = m["points"] || null
					if (points != null) {
						for (let g=0 ; g<5 ; g++) {
							let result = points[g] || null
							let matchAP = 0
							let matchBP = 0
							if (result != null)
							{
								matchAP = result["playerA"] || 0
								matchBP = result["playerB"] || 0
							}
							APoints[i][g] = matchAP
							BPoints[i][g] = matchBP
						}
					}
				}
			}
			setPlayerAName(AName)
			setPlayerBName(BName)
			setPlayerATimeout(ATimeout)
			setPlayerBTimeout(BTimeout)
			setPlayerACards(ACards)
			setPlayerBCards(BCards)

			console.log("SERVE array: ===========", serveA)
			setServeA(serveA)
			setPlayerAPoint(APoints)
			setPlayerBPoint(BPoints)
		}
		let control = status["control"] || null
		if (control != null){

			console.log("fromJson control:", control)
			setSetNumber(control["setNumber"] || 5)
			setMatchNumber(control["matchNumber"] || 6)
			setGameType(control["gameType"])

	
			setDoubleMatchNumber(control["doubleMatchNumber"])

			setDoubleInitialConfig(control["doubleInitialConfig"] || null)
			setStarted(control["started"] || false)
			setGroupStarted(control["groupStarted"] || false)
			setGroupTeamConfig(control["groupTeamConfig"] || [["A","Y",0,3,4],["B","X",1,5,3],["C","Z",2,4,5]])
			setPlayerLabel(control["playerLabel"] || {})
			setGameTypeLabel(control["gameTypeLabel"] || "" )
		}
		setVersion(status["version"] || 0)
		setStageStatus(status["stageStatus"] || null)
	}

	return (
		<React.Fragment>
			<GameContext.Provider value={{
				token, setToken,
				matchNumber, setMatchNumber, 
				teamAName, setTeamAName,
				teamBName, setTeamBName,
				teamAColour, setTeamAColour,
				teamBColour, setTeamBColour,
				setNumber, setSetNumber,
				doubleMatchNumber, setDoubleMatchNumber,
				doubleInitialConfig, setDoubleInitialConfig,
                serveA, setServeA,
                started, setStarted,
				groupStarted, setGroupStarted,
				playerAName, setPlayerAName,
				playerBName, setPlayerBName,
				playerAPoint, setPlayerAPoint,
				playerBPoint, setPlayerBPoint,
				currentMatch, setCurrentMatch,
				currentGame, setCurrentGame,
				playerATimeout, setPlayerATimeout,
				playerBTimeout, setPlayerBTimeout,
				gameType, setGameType,
				groupTeamConfig, setGroupTeamConfig,
				playerLabel, setPlayerLabel,
				gameTypeLabel, setGameTypeLabel,
				cloud, setCloud, 
				uuid, setUuid,
				autoMode, setAutoMode,
				syncPaused, setSyncPaused,
				swapped, setSwapped,
				toJson,fromJson,
				version, setVersion,
				stageStatus, setStageStatus,
				expediteMode, setExpediteMode,
				playerACards, setPlayerACards,
				playerBCards, setPlayerBCards,
				pointTracking, setPointTracking
			}}>
				{children}
			</GameContext.Provider>
		</React.Fragment>
	)
}