import React, { useRef,useState,useEffect } from 'react';

import '@fontsource/roboto';
import { Grid,Tooltip,Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Countdown from 'react-countdown';
import ReactNoSleep from 'react-no-sleep';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      //backgroundColor: '#ffbff2',
      //color: 'black',
      maxWidth: 140,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      textAlign: "center",
    },
  }))(Tooltip);

function GameState(props) {
    const classes = useStyles();

    const currentPlayerADiv = useRef(null)
    const currentPlayerBDiv = useRef(null)

    const [currentTimeIndex, setCurrentTimeIndex ] = useState(0)
    const [currentTime, setCurrentTime] = useState(null);

    const leftPlayerGamePoint = props.leftPlayerGamePoint || 0
    const rightPlayerGamePoint = props.rightPlayerGamePoint || 0
    
    const leftPlayerPoint = props.leftPlayerPoint
    const rightPlayerPoint = props.rightPlayerPoint

    const expediteMode = props.expediteMode

    var gameFinished = false
    if (leftPlayerPoint > 10 || rightPlayerPoint > 10) {
        if (Math.abs(leftPlayerPoint - rightPlayerPoint) >= 2){
            gameFinished = true
        }
    }

    var leftServe = false
    if (((leftPlayerPoint < 10 || rightPlayerPoint < 10)) && !expediteMode) {
        if ((leftPlayerPoint + rightPlayerPoint) % 4 == 0 || (leftPlayerPoint + rightPlayerPoint) % 4 == 1) {
            leftServe = props.leftPlayerServeFirstThisGame
        }else{
            leftServe = !props.leftPlayerServeFirstThisGame
        }
    }else{
        if ((leftPlayerPoint + rightPlayerPoint) % 2 == 0) {
            leftServe = props.leftPlayerServeFirstThisGame
        }else{
            leftServe = !props.leftPlayerServeFirstThisGame
        }
    }

    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false)
    };

    const handleOpen = () => {
        setOpen(true)
    };

    useEffect(() => {
        if (true) {
            //setOpen(true)
        }else{
            //setOpen(false)
        }
    })

    const handleLeftPlayerAdd = (e, diff) => {
        if ((e.clientY-currentPlayerADiv.current.offsetTop) > currentPlayerADiv.current.offsetHeight*0.5){
            diff = 0-Math.abs(diff)
        }

        const result = leftPlayerPoint + diff
        if (result < 0) {
            return
        }
        if (diff > 0 && gameFinished) {
            return
        }
        props.setLeftPlayerPoint(result, leftServe);
        setCurrentTimeIndex(currentTimeIndex + 1);
        setCurrentTime(Date.now()+20000);
    };
    const handleRightPlayerAdd = (e, diff) => {
        if ((e.clientY-currentPlayerADiv.current.offsetTop) > currentPlayerADiv.current.offsetHeight*0.5){
            diff = 0-Math.abs(diff)
        }

        const result = rightPlayerPoint + diff
        if (result < 0) {
            return
        }
        if (diff > 0 && gameFinished) {
            return
        }
        props.setRightPlayerPoint(result, !leftServe);
        setCurrentTimeIndex(currentTimeIndex + 1);
        setCurrentTime(Date.now()+20000);
    };

    const handleLeftPlayerTimeout = () => {
        props.setLeftPlayerTimeout();
    };

    const handleRightPlayerTimeout = () => {
        props.setRightPlayerTimeout();
    };

    const handleLeftPlayerCards= () => {
        props.setLeftPlayerCards();
    };

    const handleRightPlayerCards = () => {
        props.setRightPlayerCards();
    };

    const handleRightPlayerServe = () => {
        props.setLeftServeFirst(false)
        setOpen(false)
    }
    const handleLeftPlayerServe = () => {
        props.setLeftServeFirst(true)
        setOpen(false)
    }

    const timeoutRender = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          return <span className={classes.timeoutNumberFormat}>Timeout</span>
        } else {
          // Render a countdown
          return <span className={classes.timeoutNumberFormat}>{minutes.toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false
                        })}:{seconds.toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false
                        })}</span>;
        }
    };

    let cardBackgroundColor = {
        0: "white",
        1: "#FFC300",
        2: "#ff0000",
        3: "#ff0000",
    }
    let cardText = {
        0: "|",
        1: "|",
        2: "1P",
        3: "2P",
    }
    return (
        <div>
            <Grid container spacing={0} justify="center" direction="row">
                <Grid item>
                    <div className={props.leftPlayerTimeout?classes.timeoutCardActive:classes.timeoutCard} onClick={(e)=>{handleLeftPlayerTimeout()}} >
                        T
                    </div>
                    
                    <div className={classes.cardDisabled} 
                        style={{backgroundColor: cardBackgroundColor[props.leftPlayerCards], color: props.leftPlayerCards==1?"#FFC300":"white"}} 
                        onClick={(e)=>{handleLeftPlayerCards()}} >
                        {cardText[props.leftPlayerCards]}
                    </div>

                    <HtmlTooltip open={open && props.leftPlayerPoint == 0 && props.rightPlayerPoint == 0 && leftPlayerGamePoint == 0 && rightPlayerGamePoint == 0} onClose={handleClose} onOpen={handleOpen} 
                        placement="left-start"
                        arrow
                        title={
                            <React.Fragment>
                                <Typography color="inherit">Select who serve first</Typography>
                            </React.Fragment>
                        }>
                        <div className={leftServe?classes.serve:classes.noServe} 
                            onClick={(e)=>{if (props.leftPlayerServeFirstThisGame){
                                handleRightPlayerServe()
                            }else{
                                handleLeftPlayerServe()
                            }}}>{props.leftPlayerServeFirstThisGame && "s"}
                        </div>
                    </HtmlTooltip>


                </Grid>
                <Grid item>
                    <ReactNoSleep>
                        {({ isOn, enable, disable }) => (
                        <div style={{textAlign: "center"}}>
                            <div ref={currentPlayerADiv} 
                                className={classes.gameScore} 
                                onClick={(e)=>{handleLeftPlayerAdd(e,1); if(!isOn){enable()}}}>
                                {leftPlayerPoint}
                            </div>
                        </div>)}
                    </ReactNoSleep>
                </Grid>
                <Grid item spacing={0} justify="center" className={classes.gamePointArea} >
                    <Grid container spacing={0} justify="center" direction="row">
                        <Grid item>
                            <div className={classes.gamePointContainer}>
                                <div className={classes.gamePoint}>
                                    {leftPlayerGamePoint}
                                </div>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className={classes.gamePointContainer}>
                                <div className={classes.gamePoint}>
                                    {rightPlayerGamePoint}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    
                    <div className={classes.timeout}>
                        <Countdown  date={currentTime}
                                    key={currentTimeIndex}
                                    renderer={timeoutRender} />
                    </div>
                </Grid>
                <Grid item>
                    <ReactNoSleep>
                        {({ isOn, enable, disable }) => (
                            <div style={{textAlign: "center"}}>
                                <div ref={currentPlayerBDiv} 
                                    className={classes.gameScore} 
                                    onClick={(e)=>{handleRightPlayerAdd(e,1); if(!isOn){enable()}}}>
                                    {rightPlayerPoint}
                                </div>
                            </div>)}
                    </ReactNoSleep>
                </Grid>
                <Grid item>
                    <div className={props.rightPlayerTimeout?classes.timeoutCardActive:classes.timeoutCard} onClick={(e)=>{handleRightPlayerTimeout()}}>
                        T
                    </div>
                    <div className={classes.cardDisabled} 
                        style={{backgroundColor: cardBackgroundColor[props.rightPlayerCards], color: props.rightPlayerCards==1?"#FFC300":"white"}} 
                        onClick={(e)=>{handleRightPlayerCards()}} >
                        {cardText[props.rightPlayerCards]}
                    </div>
                    <div className={!leftServe?classes.serve:classes.noServe} 
                        onClick={(e)=>{if (props.leftPlayerServeFirstThisGame){
                                handleRightPlayerServe()
                            }else{
                                handleLeftPlayerServe()
                            }}}>{!props.leftPlayerServeFirstThisGame && "s"}</div>
                </Grid>
            </Grid>
        </div>
    );
}

export default GameState;

const useStyles = makeStyles((theme) => ({
    gameScore: {
        background: "linear-gradient(0deg, #ff50c2 50%, #ff4092 50%)", //"#ff4092",
        color: "white",
        width: "28vw",
        fontSize: "20vw",
        fontWeight: "bold",
        textAlign: "center",
        paddingTop: "3vw",
        paddingBottom: "3vw",
        marginLeft: "2px",
        marginRight: "2px",
        [theme.breakpoints.up('sm')]: {
            width: "17vw",
            fontSize: "23vh",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        [theme.breakpoints.up('md')]: {
            width: "240px",
            fontSize: "180px",
            paddingTop: "20px",
            paddingBottom: "20px",
        },
    },
    serve: {
        width: "7vw",
        height: "7vw",
        borderRadius: "4vw",
        borderColor: "#FFA500",
        borderStyle: "solid",
        borderWidth: "1px",
        backgroundColor: "#FFA500",
        textAlign:"center",
        marginLeft: "5px",
        marginRight: "2px",
        marginTop: "60px",
        [theme.breakpoints.up('sm')]: {
            width: "27px",
            height: "27px",

            borderRadius: "14px",
            fontSize: "20px",
            marginTop: "20px"
        },
        [theme.breakpoints.up('md')]: {
            width: "54px",
            height: "54px",
            borderRadius: "28px",
            fontSize: "38px",
            marginTop: "60px"
        },
    },
    noServe: {        
        width: "7vw",
        height: "7vw",
        borderRadius: "4vw",
        borderColor: "#FFA500",
        borderStyle: "solid",
        borderWidth: "1px",
        backgroundColor: "#ffffff",
        textAlign:"center",
        marginLeft: "5px",
        marginRight: "2px",
        marginTop: "60px",
        fontSize: "6vw",
        [theme.breakpoints.up('sm')]: {
            width: "27px",
            height: "27px",

            borderRadius: "14px",
            fontSize: "20px",
            marginTop: "20px"
        },
        [theme.breakpoints.up('md')]: {
            width: "54px",
            height: "54px",
            borderRadius: "28px",
            fontSize: "38px",
            marginTop: "60px"
        },
    },
    serveFirst: {
        backgroundColor: "white",
        borderColor: "#ff4092",
        borderStyle: "solid",
        borderWidth: "1px",
        color: "black",
        width: "7vw",
        height: "8vw",
        fontSize: "6vw",
        fontWeight: "bold",
        textAlign: "center",
        marginLeft: "2px",
        marginRight: "2px",
        marginTop: "30px",
        paddingLeft: "1px",
        paddingRight: "2px",
        [theme.breakpoints.up('sm')]: {
            width: "27px",
            height: "28px",
            fontSize: "20px",
            marginTop: "10px"
        },
        [theme.breakpoints.up('md')]: {
            width: "54px",
            height: "56px",
            fontSize: "40px",
            marginTop: "30px"
        },
    },
    gamePoint:{
        backgroundColor: "black",
        width: "5vw",
        fontSize: "4vw",
        fontWeight: "bold",
        color: "white",
        textAlign: "center",
        paddingTop: "5px",
        paddingBottom: "5px",
        marginLeft: "2px",
        marginRight: "2px",
        [theme.breakpoints.up('sm')]: {
            width: "30px",
            fontSize: "20px",
            paddingTop: "6px",
            paddingBottom: "6px",
        },
        [theme.breakpoints.up('md')]: {
            width: "60px",
            fontSize: "40px",
            paddingTop: "12px",
            paddingBottom: "12px",
        },
    },
    gamePointContainer: {
        textAlign: "center",
    },
    gamePointArea: {
        width: "13vw",
        textAlign: "center",
        [theme.breakpoints.up('sm')]: {
            width: "70px",
        },
        [theme.breakpoints.up('md')]: {
            width: "140px",
        },
    },
    timeoutNumberFormat:{
        
    },
    timeout: {
        fontSize: "80%",
        marginTop: "20px"
    },
    timeoutCardActive: {
        backgroundColor: "#ff4092",
        color: "white",
        width: "8vw",
        fontSize: "6vw",
        fontWeight: "bold",
        textAlign: "center",
        paddingTop: "6px",
        paddingRight: "1px",
        paddingLeft: "2px",
        paddingBottom: "6px",
        marginLeft: "2px",
        marginRight: "2px",
        [theme.breakpoints.up('sm')]: {
            width: "30px",
            fontSize: "20px"
        },
        [theme.breakpoints.up('md')]: {
            width: "60px",
            fontSize: "40px"
        },
    },
    timeoutCard: {
        backgroundColor: "#ff4092",
        color: "#ff4092",
        width: "8vw",
        fontSize: "6vw",
        fontWeight: "bold",
        textAlign: "center",
        paddingTop: "6px",
        paddingBottom: "6px",
        paddingRight: "1px",
        paddingLeft: "2px",
        marginLeft: "2px",
        marginRight: "2px",
        [theme.breakpoints.up('sm')]: {
            width: "30px",
            fontSize: "20px"
        },
        [theme.breakpoints.up('md')]: {
            width: "60px",
            fontSize: "40px"
        },
    },
    cardDisabled: {
        backgroundColor: "white",
        borderColor: "#FFC300",
        borderStyle: "solid",
        borderWidth: "1px",

        width: "8vw",
        fontSize: "6vw",
        fontWeight: "bold",
        textAlign: "center",
        
        paddingTop: "6px",
        paddingBottom: "6px",
        marginTop: "4px",
        marginLeft: "2px",
        marginRight: "2px",
        [theme.breakpoints.up('sm')]: {
            width: "30px",
            fontSize: "20px"
        },
        [theme.breakpoints.up('md')]: {
            width: "60px",
            fontSize: "40px"
        },
    },
    yellowCardActive: {
        backgroundColor: "#FFC300",

    },
  }));

