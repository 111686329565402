import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Fade, Backdrop } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import CloseIcon from '@material-ui/icons/Close';

import '@fontsource/roboto';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box'
import InputAdornment from '@material-ui/core/InputAdornment';
import Hidden from '@material-ui/core/Hidden';

import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ReactComponent as TShirt } from '../../statics/images/t-shirt.svg';

function PlayerNames(props) {
    const classes = useStyles();

    const leftPlayerLabel = props.leftPlayerLabel || null
    const rightPlayerLabel = props.rightPlayerLabel || null
    
    const leftPlayerName = props.leftPlayerName || ""
    const rightPlayerName = props.rightPlayerName || ""

    const leftPlayerColour = props.leftPlayerColour || "#ff0000"
    const rightPlayerColour = props.rightPlayerColour || "#ff0000"

    const leftPlayerGamePoint = props.leftPlayerGamePoint || 0
    const rightPlayerGamePoint = props.rightPlayerGamePoint || 0

    const leftInputLabel = props.leftInputLabel || null
    const rightInputLabel = props.rightInputLabel || null

    const leftInputHandle = props.leftInputHandle || null
    const rightInputHandle = props.rightInputHandle || null

    const leftColorHandle = props.leftColorHandle || null
    const rightColorHandle = props.rightColorHandle || null
    
    const nameOnly = props.nameOnly 

    const hideTShirt = props.hideTShirt || false

    const [ colourSelector, setColourSelector ] = useState(false)
    const [ colourForA, setColourForA ] = useState(true)

    const [ open, setOpen ] = useState(-1)
    const [ options, setOptions] = useState([]);
    const [ fetching, setFetching ] = useState(false)
    const [ leftInputValue, setLeftInputValue ] = useState(false)
    const loading = open && fetching;

    useEffect(() => {
        let active = true;
    
        if (!loading) {
            return undefined;
        }
    
        if (props.token == null){
            return undefined;
        }
        (async () => {
            const response = sessionStorage.getItem('players');
            const storedTime = sessionStorage.getItem('playersTime');
            const currentTime = new Date().getTime();
            const validMaxTime = 60 * 1000;

            if (response && storedTime && currentTime - storedTime < validMaxTime) {
                const players = JSON.parse(response);
                if (active) {
                    setOptions(players);
                    setFetching(false);
                }
            } else {
                const fetchPlayers = async () => {
                    try{
                        const response = await fetch('https://api.kandolab.com/api/v2/cameras/assets/' + props.token.id + '/players.json');
                        if (response.ok) {
                            const players = await response.json();
                            if (active) {
                                sessionStorage.setItem('players', JSON.stringify(players));
                                sessionStorage.setItem('playersTime', currentTime);
                                setOptions(players);
                                setFetching(false);
                            }
                        } else {
                            throw new Error('Network response was not ok.');
                        }
                    }catch(e){
                        if (active) {
                            sessionStorage.setItem('players', JSON.stringify([]));
                            sessionStorage.setItem('playersTime', currentTime);
                            setOptions([]);
                            setFetching(false);
                        }
                    }
                };
                fetchPlayers();
            }
        })();
    
        return () => {
          active = false;
        };
      }, [loading]);
    
    useEffect(() => {
        if (props.token == null) return
        if (!open) {
          setOptions([]);
        }else{
          setFetching(true);
        }
      }, [open]);

    const handleColourSelectA = () => {
        setColourForA(true)
        setColourSelector(true)
    }
    const handleColourSelectB = () => {
        setColourForA(false)
        setColourSelector(true)
    }
    const handleColourSelected = (colour) => {
        if (colourForA) {
            if (leftColorHandle!==null) {
                leftColorHandle(colour)
            }
        } else {
            if (rightColorHandle!==null) {
                rightColorHandle(colour)
            }
        }
        setColourSelector(false)
    }
    const handleCloseColourSelector = () =>{
        setColourSelector(false)
    }

    //console.log("options: ", options)

    return (
        <Grid container spacing={0} justify="center" direction="row">
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={colourSelector}
                onClose={handleCloseColourSelector}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 1000,
                }}
            >
                <Fade in={colourSelector}>
                    <div className={classes.colourDialog}>
                        <h2 style={{marginBottom: "0px"}}>Color de Camisa</h2>

                        <Grid container justify="center" className={classes.tShirtArray}>
                            <TShirt className={classes.tShirt} fill='#000000' stroke="black" onClick={()=>{handleColourSelected("#000000")}} ></TShirt>
                            <TShirt className={classes.tShirt} fill='#C0C0C0' stroke="black" onClick={()=>{handleColourSelected("#C0C0C0")}} ></TShirt>
                            <TShirt className={classes.tShirt} fill='#FFA500' stroke="black" onClick={()=>{handleColourSelected("#FFA500")}} ></TShirt>
                            <TShirt className={classes.tShirt} fill='#FF0000' stroke="black" onClick={()=>{handleColourSelected("#FF0000")}} ></TShirt>
                            <TShirt className={classes.tShirt} fill='#55aa55' stroke="black" onClick={()=>{handleColourSelected("#55aa55")}} ></TShirt>
                            <TShirt className={classes.tShirt} fill='#0000FF' stroke="black" onClick={()=>{handleColourSelected("#0000FF")}} ></TShirt>
                            <TShirt className={classes.tShirt} fill='#FF00FF' stroke="black" onClick={()=>{handleColourSelected("#FF00FF")}} ></TShirt>
                            <TShirt className={classes.tShirt} fill='#00FFFF' stroke="black" onClick={()=>{handleColourSelected("#00FFFF")}} ></TShirt>
                            <TShirt className={classes.tShirt} fill='#FFFF00' stroke="black" onClick={()=>{handleColourSelected("#FFFF00")}} ></TShirt>
                        </Grid>
                        <Button 
                            variant="contained" color="secondary" onClick={handleCloseColourSelector}
                            startIcon={<CloseIcon />}>
                             Close
                        </Button>
                    </div>
                </Fade>
            </Modal>
            <Grid container xs={nameOnly?6:5}  direction="row" justify="flex-end"> 
                <Grid item spacing={0}  xs={nameOnly?11:9} direction='row'>
                    <Grid container direction='row' spacing={0}>
                    {leftPlayerLabel!=null && <Grid item xs={1}>
                                        <p className={classes.label}>{leftPlayerLabel}</p>
                    </Grid>}
                    <Grid item xs={hideTShirt && leftPlayerLabel==null?11:10}>
                        <Autocomplete
                            id="player-a-name"
                            className={classes.playerLeftName}
                            required 
                            freeSolo
                            size="small" 
                            autoSelect={true}
                            disabled={leftInputHandle==null}
                            style={{ minWidth: "100px"}}
                            open={open==1}
                            onOpen={() => {
                                setOpen(1);
                            }}
                            onClose={() => {
                                setOpen(-1);
                            }}
                            getOptionSelected={(option, value) => {
                                if (props.isPlayer){
                                    return option.name === value.name
                                }else{
                                    return option.club === value.club
                                }
                            }}
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') return option
                                if (props.isPlayer){
                                    return option.name
                                }else{
                                    return option.club
                                }
                            }}
                            options={options}
                            loading={loading}
                            value={leftPlayerName}
                            onChange={(event, newValue, reason) => {
                                let name = newValue
                                if (reason == "select-option" || reason == "blue"){
                                    name = (props.isPlayer)?newValue.name:newValue.club
                                }else if (reason == "clear"){
                                    name = ""
                                }
                                if (typeof name === 'object' && name !== null) {
                                    if (props.isPlayer){
                                        if (name.hasOwnProperty('name')) {
                                            name = name.name
                                        }
                                    } else {
                                        if (name.hasOwnProperty('club')) {
                                            name = name.club
                                        }
                                    }
                                }
                                console.log("OnChange Left: ", newValue, reason, name)
                                if (leftInputHandle) leftInputHandle(name)
                            }}
                            renderOption={(option) => (
                                <React.Fragment>
                                {props.isPlayer?option.name:option.club}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label={leftInputLabel}
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    style: {textTransform: 'uppercase'},
                                    endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                />
                            )}
                            />

                    </Grid>
                    {!hideTShirt && <Grid item>
                        <Hidden smDown>
                            <TShirt width='30px' fill={leftPlayerColour} stroke="black"  onClick={()=>{if (leftColorHandle!==null){handleColourSelectA()}}}/> 
                        </Hidden>
                    </Grid>
                    }
                    </Grid>
                </Grid>
                
                {!nameOnly && 
                <Grid item xs={2} spacing={0} justify="center">
                    <div className={classes.gamePointContainer}>
                        <div className={classes.gamePoint}>
                            {leftPlayerGamePoint}
                        </div>
                    </div>
                </Grid>}
            </Grid>
            <Grid container  xs={nameOnly?6:5} spacing={0}  justifyContent='flex-end'>
                {!nameOnly && 
                <Grid item xs={2} spacing={0} justify="center">
                    <div className={classes.gamePointContainer}>
                        <div className={classes.gamePoint}>
                            {rightPlayerGamePoint}
                        </div>
                    </div>
                </Grid>}
                <Grid container xs={nameOnly?11:9} spacing={0} justify="center">
                    <Grid container direction='row' spacing={0}>
                    {!hideTShirt && <Grid item >
                        <Hidden smDown>
                            <TShirt width='30px' fill={rightPlayerColour} stroke="black" onClick={()=>{if (rightColorHandle!==null){handleColourSelectB()}}}/> 
                        </Hidden>
                    </Grid>}
                    <Grid item xs={hideTShirt && rightPlayerLabel==null?11:10}>
                    <Autocomplete
                                id="player-b-name"
                                className={classes.playerRightName}
                                required 
                                freeSolo
                                autoSelect={true}
                                size="small" 
                                disabled={rightInputHandle==null}
                                style={{ minWidth: "100px"}}
                                open={open==2}
                                onOpen={() => {
                                    setOpen(2);
                                }}
                                onClose={() => {
                                    setOpen(-1);
                                }}
                                getOptionSelected={(option, value) => {
                                    if (props.isPlayer){
                                        return option.name === value.name
                                    }else{
                                        return option.club === value.club
                                    }
                                }}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') return option
                                    if (props.isPlayer){
                                        return option.name
                                    }else{
                                        return option.club
                                    }
                                }}
                                options={options}
                                loading={loading}
                                value={rightPlayerName}
                                onChange={(event, newValue, reason) => {
                                    let name = newValue
                                    if (reason == "select-option" || reason == "blue"){
                                        name = (props.isPlayer)?newValue.name:newValue.club
                                    }else if (reason == "clear"){
                                        name = ""
                                    }
                                    if (typeof name === 'object' && name !== null) {
                                        if (props.isPlayer){
                                            if (name.hasOwnProperty('name')) {
                                                name = name.name
                                            }
                                        } else {
                                            if (name.hasOwnProperty('club')) {
                                                name = name.club
                                            }
                                        }
                                    }
                                    console.log("OnChange Right: ", newValue, reason, name)
                                    if (rightInputHandle) rightInputHandle(name)
                                }}
                                renderOption={(option) => (
                                    <React.Fragment>
                                    {props.isPlayer?option.name:option.club}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label={rightInputLabel}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        style: {textTransform: 'uppercase'},
                                        endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                        ),
                                    }}
                                    />
                                )}
                                />
                        
                    </Grid>
                    {rightPlayerLabel!=null &&<Grid item xs={1}><p className={classes.label}>{rightPlayerLabel}</p></Grid>}
                    </Grid>
                </Grid>
                
            </Grid>
        </Grid>
    );

}
export default PlayerNames;

const useStyles = makeStyles((theme) => ({
    gamePointContainer: {
        textAlign: "center",
    },
    gamePoint:{
        backgroundColor: "black",
        border: "1px solid white",
        width: "5vw",
        fontSize: "4vw",
        fontWeight: "bold",
        color: "white",
        textAlign: "center",
        marginTop: "2px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "4px",
        paddingRight: "4px",
        [theme.breakpoints.up('sm')]: {
            width: "20px",
            fontSize: "18px",
            marginLeft: "0px",
            marginRight: "0px",
            paddingTop: "6px",
            marginTop: "2px",
            paddingBottom: "6px",
            paddingLeft: "4px",
            paddingRight: "4px",
        },
    },
    playerLeftName: {
        marginRight: "2px"
    },    
    playerRightName: {
        marginLeft: "2px"
    },
    label:{
        marginTop: "10px",
        marginLeft: "5px",
        marginRight: "5px",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tShirt: {
        marginLeft: "2px",
        marginRight: "2px",
        width: '45px',
    },
    tShirtArray:{
        marginTop: "10px",
        marginBottom: "15px"
    },
    colourDialog: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #999',
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 7, 3),
        textAlign: "center"
    }
}));

/*
                            <TextField 
                                disabled={leftInputHandle==null}
                                size="small" 
                                label={leftInputLabel}
                                id="input-player-a-name" 
                                value={leftPlayerName}
                                variant="outlined"
                                onChange={(e)=>{leftInputHandle(e.target.value)}}
                                inputProps={{
                                    style: {textAlign:"right", color:"black", textTransform: 'uppercase'}
                                }}
                                className={classes.playerLeftName}/> 
                                
                                
                                <TextField 
                            disabled={rightInputHandle==null}
                            size="small" 
                            label={rightInputLabel}
                            id="input-player-b-name" 
                            value={rightPlayerName}
                            variant="outlined"
                            onChange={(e)=>{rightInputHandle(e.target.value)}}
                            inputProps={{style: {textAlign:"left", color:"black", textTransform: 'uppercase'}}}
                            className={classes.playerRightName}/>*/