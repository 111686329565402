import React, { useContext, useEffect, useState, useRef } from 'react';
import {withRouter, Redirect} from 'react-router';

import { makeStyles } from '@material-ui/core/styles';
import '@fontsource/roboto';
import { GameContext } from '../entities/GameContext'
import logo from '../statics/images/kandolab-logo_v1.png';
import { Grid, Button, Paper } from '@material-ui/core';

import { TextField } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Home from './Home'
import Alert from './components/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import md5 from "md5";
import { getStatus } from '../services/backendapi'
const baseReflectorURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_REFLECTOR_URL_DEVEL : process.env.REACT_APP_REFLECTOR_URL

function Setup(props) {
    const { 
            token, setToken, setGameType, setStarted, setGroupStarted, fromJson, uuid, setUuid,
        } = useContext(GameContext);
    const classes = useStyles();

    const [password, setPassword] = useState('');
    const [tokenValid, setTokenValid] = useState(false)
    const [ message, setMessage ] = useState(null)
    const [sync, setSync] = useState(false)
    const [netError, setNetError] = useState(false)

    const [ lastUpdateTime, setLastUpdateTime ] = useState(0)
    
    const ws = useRef(null)
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        let id = props.match.params.id

        if (id === null) {
            return
        }

        if (waitingToReconnect) {
            return;
        }

        if (!tokenValid ) return;
        if (sync) return;

        var wsCurrent = null

        console.log("Let to connect to Reflector: ", id)

        if (!ws.current) {
            var base = ""
            if (baseReflectorURL.substring(0, 8) === "https://"){
                base = "wss://" + baseReflectorURL.substring(8,baseReflectorURL.length);
            }else if(baseReflectorURL.substring(0, 7) === "http://"){
                base = "ws://" + baseReflectorURL.substring(7,baseReflectorURL.length);
            }else{
                base = baseReflectorURL;
            }

            ws.current = new WebSocket(base + "api/v1/reflect/"+id);
            wsCurrent = ws.current

            ws.current.onopen = () => {
                setIsOpen(true)
                console.log("ws opened");
            }
            ws.current.onclose = () => {
                if (ws.current) {
                    console.log("ws closed by server")
                }else{
                    console.log("ws closed by component unmount")
                    return
                }
                if (waitingToReconnect) {
                    return
                }
                setIsOpen(false)
                console.log("ws closed");

                setWaitingToReconnect(true)

                setTimeout(() => setWaitingToReconnect(null), 5000);
            }
        }

        return () => {
            wsCurrent.close();
            ws.current = null;
        };
    }, [waitingToReconnect, sync, tokenValid]);

    useEffect(() => {
        if (!ws.current) return;

        ws.current.onmessage = function (event) {
            const data = JSON.parse(event.data);
            let timeStamp = Date.now() / 1000
            
            if (data.type === 'matchStatus') {
                if ("content" in data) {
                    console.log(">>> New data:", data.content, timeStamp, uuid)
                    if (data.content.uuid !== uuid) {
                        console.log("DATA FROM OTHER, SO LET SYNC -------------")
                        fromJson(data.content)
                    }else{
                        console.log("DATA FROM ME-------------")
                    }
                }else{
                    console.log("Content is not in the data", data, timeStamp, uuid)
                }
            }
            if (data.type === "ping") {
                console.log(">>> PING", timeStamp, uuid)
                try{
                    ws.current.send(JSON.stringify({type: "pong"}))
                }catch(e){
                    console.log("Error happend:", e)
                    ws.current.close()
                }
            }
        };
    }, [isOpen]);

    const handleErrorBarClose = () => {
        setMessage(null)
    }

    const handlePasswordSubmit = (event) => {
        event.preventDefault();
        let t = props.t
        let id   = props.match.params.id
        let r    = props.match.params.r
        let phisicalId = props.match.params.phisical_id
        let hash = md5(id + "@" + phisicalId + "@" + r).substring(1,5)
        if (password == hash)
        {
            setToken({
                timestamp: Math.floor(Date.now() / 1000),
                type: t,
                id: id,
                r: r,
                phisicalId: phisicalId,
            })
            setGameType(-1)
            setGroupStarted(false)
            setStarted(false)
        }else{
            setMessage("Password no es correcto")
        }
    };

    useEffect(() => {
        console.log("Check token: ", props)
        let expireTime = 3600 * 24 * 30
        var timestamp = token["timestamp"] || Date.now()/1000
        if (token.type == props.t &&
            token.id == props.match.params.id &&
            token.phisicalId == props.match.params.phisical_id &&
            token.r == props.match.params.r ) {
            if (timestamp + expireTime > Date.now()/1000){
                setTokenValid(true)
            }
        }else{
            setToken({
                timestamp: Math.floor(Date.now() / 1000),
                type: props.t,
                id: props.match.params.id, 
                r: props.match.params.r, 
                phisicalId: props.match.params.phisical_id,
            })
            setTokenValid(true)
            setSync(true)
            async function scopedAsyncFunc(id){
                try{
                    let status = await getStatus(id)
                    if ("matchStatus" in status){
                        if ("content" in status["matchStatus"]) {
                            fromJson(status["matchStatus"]["content"])
                        }
                    }
                    setNetError(false)
                }catch(e) {
                    console.log("Error happend:", e)
                    setNetError(true)
                }
                setSync(false);
            }
            scopedAsyncFunc(props.match.params.id)
        }
    }, [token])


    if (tokenValid) {
        if (sync){
            return (
                <div className={classes.pageContent}  >
                    <div className={classes.content}>
                        ...loading...
                    </div>
                </div>
            )
        }else{
            return <Home isCloud={true} /> 
        }
        
    }else{
        return (
            <div className={classes.pageContent}  >     
                <div>
                    <Grid item xs={12} className={classes.content}>
                        <h1><img className={classes.logoImg} src={logo} width="40px"/>Kando LAB</h1>
                    </Grid>
                    <Grid container className={classes.content} spacing={0} justify="center">
                        <Grid container spacing={2} justify="center">
                            <form className={classes.root} noValidate autoComplete="off" onSubmit={handlePasswordSubmit}>
                                <Grid item>
                                    <TextField
                                    id="standard-password-input"
                                    label="Password"
                                    type="string"
                                    autoFocus
                                    autoComplete="current-password"
                                    value={password}
                                    onInput={ e=>setPassword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        className={classes.button}
                                        endIcon={<KeyboardArrowRightIcon />}>
                                        Validar
                                    </Button>
                                </Grid>
                            </form>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="center"  className={classes.copyright}>
                            &#xa9; 2023 KandoLab.com
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Snackbar open={message != null} 
                    bodyStyle={{width: '100%'}} 
                    autoHideDuration={3000} 
                    onClose={handleErrorBarClose}>
                    <Alert onClose={handleErrorBarClose} saverity="error" >
                        {message}
                </Alert>
            </Snackbar>
            </div>)
    }
}

export default withRouter(Setup);

const useStyles = makeStyles((theme) => ({
    pageContent:{
        //padding: theme.spacing(0, 2),
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    content: {
        marginRight: "0px",
        marginLeft: "0px",
        textAlign:"center",
    },
    logoImg:{
        paddingRight:"5px"
    },
    copyright: {
        color: "#BBBBBB",
        paddingTop: "30px"
    },
    buttonGrid: {
        paddingTop: "20px"
    },
    button: {
        borderRadius: "20px",
        paddingLeft:"30px",
        paddingRight:"30px",
        marginTop:"20px",
    }
}));