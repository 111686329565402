/*
    If gameStatusVisible, just show Players Name at left with Game score (if it available). 
    In teamStatusVisible, show Teams Name with team Result (if it available).
*/

import { Collapse, Zoom, Fade, Grow, Slide } from '@material-ui/core';
import { ReactComponent as TShirt } from '../../statics/images/t-shirt.svg';

import { makeStyles } from '@material-ui/core/styles';
import ExtendedNameFild from './ExtendedNameFild';

function Type1Scoreboard(props) {
    const localClasses = useStyles(props.config.theme);

    return (
        <>
            <Zoom in={(!props.config.innactive) && (props.config.gameStatusVisible || props.config.teamStatusVisible) && props.config.gameStatus !==  null} >
                <div className={props.config.styleClass.container}> 
                   
                    <div className={props.config.styleClass.nameContainer}> 
                        {props.config.scoreboardBackVisible && 
                                <div className={props.config.styleClass.columnContainer}> 
                                    <object data={props.config.backImageUrl} className={props.config.styleClass.scoreboardBackImg} />
                                </div>
                        }
                        {props.config.showTshirt && 
                            <div className={props.config.styleClass.columnContainer}> 
                                <div className={props.config.styleClass.nameItemDiv}>
                                    <div className={props.config.styleClass.tshirtDiv} style={{backgroundColor: props.config.teamAColor}}></div>
                                </div>
                                <div className={props.config.styleClass.nameItemDiv}>  
                                    <div className={props.config.styleClass.tshirtDiv} style={{backgroundColor: props.config.teamBColor}}></div>
                                </div> 
                            </div>
                        }
                        {props.config.teamStatusVisible && 
                            <div className={props.config.styleClass.columnContainer}> 
                                <div className={props.config.styleClass.nameItemDiv}>
                                    <div className={props.config.styleClass.oddNameDiv + ' ' + localClasses.nameDiv} >
                                        <ExtendedNameFild name={props.config.teamAName} fontSize={props.config.theme.fontSize}/>
                                    </div> 
                                </div>
                                <div className={props.config.styleClass.nameItemDiv}>  
                                    <div className={props.config.styleClass.oddNameDiv + ' ' + localClasses.nameDiv} >
                                        <ExtendedNameFild name={props.config.teamBName} fontSize={props.config.theme.fontSize}/>
                                    </div> 
                                </div> 
                            </div>
                        }
                        {props.config.gameStatusVisible && 
                            <div className={props.config.styleClass.columnContainer}> 
                                <div className={props.config.styleClass.nameItemDiv}>
                                    <div className={(props.config.teamStatusVisible?props.config.styleClass.evenNameDiv:props.config.styleClass.oddNameDiv)  + ' ' + localClasses.nameDiv} >
                                        <ExtendedNameFild name={props.config.playerA} fontSize={props.config.theme.fontSize}/>
                                    </div> 
                                </div>
                                <div className={props.config.styleClass.nameItemDiv}>  
                                    <div className={(props.config.teamStatusVisible?props.config.styleClass.evenNameDiv:props.config.styleClass.oddNameDiv)  + ' ' + localClasses.nameDiv} >
                                        <ExtendedNameFild name={props.config.playerB} fontSize={props.config.theme.fontSize}/>
                                    </div> 
                                </div> 
                            </div>
                        }
                    </div>
                    {props.config.competitionStageVisible && props.config.stageStatus!=="" &&
                        <div className={props.config.styleClass.stageDiv}
                            style={{
                                marginLeft: props.config.showTshirt?props.config.theme.textFontSize-props.config.theme.borderRadius:0,
                            }}
                        > 
                            {props.config.stageStatus}
                        </div>
                    }
                </div>
            </Zoom>
        </>
    )
}

export default Type1Scoreboard;

const useStyles = makeStyles((theme) => ({
    nameDiv: {
        paddingLeft: (props) => props.textFontSize/2 + props.borderRadius,
        paddingRight: (props) => props.textFontSize/2 + props.borderRadius,
    }
}))
