import React, { useContext, useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import '@fontsource/roboto';
import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      paddingLeft: "3px",paddingRight: "3px",
      paddingTop: "2px",paddingBottom: "2px",
    },
    body: {
      fontSize: 12,
      borderWidth: 1, borderColor: '#DDDDDD',borderStyle: 'solid',
      marginLeft: "1px",marginRight: "1px",
      paddingLeft: "3px",paddingRight: "3px",
      paddingTop: "2px",paddingBottom: "2px",
      textTransform: 'uppercase',
    },
  }))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

function ControlGeneralStatus(props) {
    const classes = useStyles();
    let h = {backgroundColor: '#ff50c2', color: 'white'}

    return (
        <Grid container xs={9} className={classes.pageContent} spacing={0} justify="center" >     
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table} size="small" aria-label="customized table">
                    <colgroup>
                        <col width="3%" />
                        <col width="15%" />
                        <col width="3%" />
                        <col width="15%" />
                        <col width="9%" />
                        <col width="9%" />
                        <col width="9%" />
                        <col width="9%" />
                        <col width="9%" />
                        <col width="9%" />
                        <col width="9%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left"></StyledTableCell>
                            <StyledTableCell align="left">Name</StyledTableCell>
                            <StyledTableCell align="right"></StyledTableCell>
                            <StyledTableCell align="left">Name</StyledTableCell>
                            {
                                [...Array(props.setNumber)].map((v,i) => {
                                    return (
                                        <StyledTableCell align="center">{i+1}</StyledTableCell>
                                    )
                                })
                            }
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center">Total</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.matches.map((m, i) => {
                            let gameResult = {}
                            
                            if (i < props.matchNumber) {
                                for (let g=0 ; g<props.setNumber ; g++) {
                                    gameResult[g] = ""
                                    if (i*10+0 <= props.gameId) {
                                        let a = m["points"][g]["playerA"]
                                        let b = m["points"][g]["playerB"]
                                        if (a>0 || b>0){
                                            gameResult[g] = "" + a + "-" + b
                                        }
                                    }
                                }
                                return(
                                    <StyledTableRow key={"label" + i}>
                                        <StyledTableCell align="right"></StyledTableCell>
                                        <StyledTableCell align="left">{m["playerA"]}</StyledTableCell>
                                        <StyledTableCell align="right">{""}</StyledTableCell>
                                        <StyledTableCell align="left">{m["playerB"]}</StyledTableCell>
                                        {[...Array(props.setNumber)].map((v,g) => {
                                            return (
                                                <StyledTableCell align="center" 
                                                    style={( i===props.currentMatch && props.currentGame===g )?h:{}}>
                                                        {gameResult[g]}
                                                </StyledTableCell>
                                            )
                                        })}
                                    
                                        <StyledTableCell align="center">
                                            {i<=props.currentMatch?"" + props.matchSummary[i][0] + "-" + props.matchSummary[i][1] : ""}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {i<props.currentMatch?"" + props.totalSummary[i][0] + "-" + props.totalSummary[i][1] : ""}
                                        </StyledTableCell>
                                    </StyledTableRow> 
                                )
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )

}

export default ControlGeneralStatus;


const useStyles = makeStyles((theme) => ({
    pageContent:{
        //margin: "0px",
        marginTop: "15px",
    },
    appBar: { 
        background: '#000' 
    },
    content: {
        marginRight: "0px",
        marginLeft: "0px",
        textAlign:"center",
        marginTop: "0px"
    },
    root: {
        flexGrow: 1,
        marginTop: "10px",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    copyright: {
        color: "#BBBBBB",
        paddingTop: "10px"
    },
    setNumberGrid:{
        paddingTop: "20px",
    },
    startButtonGrid: {
        paddingTop: "20px"
    },
    startButton: {
        borderRadius: "20px",
        paddingLeft:"30px",
        paddingRight:"30px",
    },
    playerTextField: {
        heigh: "15px",
    },
    logoImg:{
        filter: "invert(1)",
        paddingRight:"5px"
    },
    appbar: {
        background : '#000000',
    },
    formControlLabel: {
        fontSize:'12px'
    },
    actionButton: {
        //height: "12vw",
        //width: "40vw",
        paddingLeft: "5px",
        paddingRight: "5px",
        borderRadius: "20px",
        fontWeight: "bold",
        marginLeft: "1vw",
        marginRight: "1vw",
        [theme.breakpoints.up('sm')]: {
            height: "40px",
            width: "210px",
            boarderRadius: "20px"
        },
    },
    tableContainer : {
        marginLeft: "20px",
        marginRight: "20px",
    },
    finishDialog: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #999',
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 0, 3),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    actionButtonGridFinish: {
        marginTop: "20px"
    }
  }));