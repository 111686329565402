import React, { useContext, useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import '@fontsource/roboto';

import { Chip, Grid, Modal, Fade, Backdrop, Box, Switch} from '@material-ui/core';
import { GameContext } from '../entities/GameContext'
import StopIcon from '@material-ui/icons/Stop';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import GameState from './components/GameState';
import PlayerNames from './components/PlayerNames';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Countdown from 'react-countdown';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SyncIcon from '@material-ui/icons/Sync';
import FavoriteIcon from '@material-ui/icons/Favorite';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import SubjectIcon from '@material-ui/icons/Subject';

import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';

import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import AirplanemodeInactiveIcon from '@material-ui/icons/AirplanemodeInactive';

import ReactNoSleep from 'react-no-sleep';

import { syncStatus, pushTrace } from '../services/backendapi'
import Tooltip from '@material-ui/core/Tooltip';
import { v4 as uuidv4 } from 'uuid';
import SpeedIcon from '@material-ui/icons/Speed';

//const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEVEL : window.API_URL
const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEVEL : process.env.REACT_APP_API_URL
const baseReflectorURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_REFLECTOR_URL_DEVEL : process.env.REACT_APP_REFLECTOR_URL


function GameOngoing() {
    const {
        setNumber,
        setStarted,
        matchNumber,
        teamAName, 
        teamBName, 
        teamAColour,
        teamBColour,
        playerAName, 
        playerBName,
        currentMatch,
        currentGame,setCurrentGame,
        playerAPoint, 
        setPlayerAPoint,
        playerBPoint, 
        setPlayerBPoint,
        serveA,setServeA, 
        playerATimeout, setPlayerATimeout,
        playerBTimeout, setPlayerBTimeout,
        gameType,
        token, 
        cloud,
        uuid, setUuid,
        autoMode, setAutoMode,
        syncPaused, setSyncPaused,
        swapped, setSwapped, 
        expediteMode, setExpediteMode,
        playerACards, setPlayerACards,
        playerBCards, setPlayerBCards,
        toJson,version, setVersion,
        pointTracking, setPointTracking,
    } = useContext(GameContext);

    const [ lastSyncVersion, setLastSyncVersion ] = useState(0)
    const [ currentVersion, setCurrentVersion ] = useState(0)

    const [ finished, setFinished ] = useState(false)
    const [ traceOn, setTraceOn ] = useState(true)
    const [ openTimeout, setOpenTimeout ] = useState(false)
    const [ timeoutStartTime, setTimeoutStartTime ] = useState(Date.now())
    const [ timeoutDuration, setTimeoutDuration ] = useState(60)
    const [ openFinish, setOpenFinish ] = useState(false)
    const syncing = useRef(false)
    const [sync, setSync] = useState(false)
    const [dataError, setDataError] = useState(false)
    const [netError, setNetError] = useState(false)
    const [ changeMatch, setChangeMatch ] = useState(false)
    const [ autoSwapDone, setAutoSwapDone ] = useState(false)
    const [ currentTrack, setCurrentTrack ] = useState(null)

    const classes = useStyles();

    //setStarted(false)
    const [currentLeftPlayerPoint, setCurrentLeftPlayerPoint] = useState(0)
    const [currentRightPlayerPoint, setCurrentRightPlayerPoint] = useState(0)
    const [gameFinished, setGameFinished] = useState(false)

    const [playerAServeFirstThisGame, setPlayerAServeFirstThisGame] = useState(false)

    const [playerAGamePoint, setPlayerAGamePoint] = useState(0)
    const [playerBGamePoint, setPlayerBGamePoint] = useState(0)
    const [playerAGamePointUntilNow, setPlayerAGamePointUntilNow] = useState(0)
    const [playerBGamePointUntilNow, setPlayerBGamePointUntilNow] = useState(0)

    const [teamAResult, setTeamAResult] =  useState(0)
    const [teamBResult, setTeamBResult] =  useState(0)
    const [setNumberToWin, setSetNumberToWin] = useState(Math.floor(setNumber/2))
    const [finishedGames, setFinishedGames] = useState([])

    const [matchFinished, setMatchFinished] = useState(false)

    const ws = useRef(null)
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    
    useEffect(() => {
        let first = serveA[currentMatch]
        if (currentGame%2 == 1) {
            first = !first
        }
        setPlayerAServeFirstThisGame(first)
    }, [serveA, currentMatch, currentGame])

    useEffect(() => {
        setSetNumberToWin(Math.floor(setNumber/2))
    }, [setNumber])

    useEffect(() => {
        var tAResult = 0
        var tBResult = 0

        var pAGPoint = 0
        var pBGPoint = 0

        for (var match in playerAPoint){
            var aGPoint = 0
            var bGPoint = 0

            if (!(match in playerBPoint)){
                return
            }
            
            for (var game in playerAPoint[match] ) {
                if (!(game in playerBPoint[match])){
                    return
                }
                if (playerAPoint[match][game]>10 || playerBPoint[match][game] > 10){
                    if (Math.abs(playerAPoint[match][game] - playerBPoint[match][game]) >= 2){
                        if (playerAPoint[match][game] > playerBPoint[match][game]){
                            aGPoint += 1
                        }else{
                            bGPoint += 1
                        }
                    }
                }
                //console.log("Match: ", match, "Game: ", game, "A: ", aGPoint, "B: ", bGPoint, "T:", setNumberToWin)
            }
            if (aGPoint > setNumberToWin || bGPoint > setNumberToWin) {
                if (aGPoint > bGPoint) {
                    tAResult += 1
                }else{
                    tBResult += 1
                }
            }
            if (match == currentMatch) {
                pAGPoint = aGPoint
                pBGPoint = bGPoint
            }
        }
        setTeamAResult(tAResult)
        setTeamBResult(tBResult)
        setPlayerAGamePoint(pAGPoint)
        setPlayerBGamePoint(pBGPoint)
        
        var f = false;
        if (gameType == 0) {
            if (tAResult >0 || tBResult > 0) {
                f = true;
            }
        }else{
            if (tAResult + tBResult >= matchNumber) {  // TODO: need to control doubles
                //matchFinished = true
            }
        }
        setMatchFinished(f)

        // Check current Game
        let aM = playerAPoint[currentMatch] || {}
        let a = aM[currentGame] || 0
        let bM = playerBPoint[currentMatch] || {}
        let b = bM[currentGame] || 0
        let aOffset = 0
        let bOffset = 0
        
        setCurrentLeftPlayerPoint((swapped?b:a))
        setCurrentRightPlayerPoint((swapped?a:b))

        var gameFinished = false
        if (a > 10 || b > 10) {
            if (Math.abs(a-b) >= 2){
                if ((gameType === 1) && (pAGPoint > setNumberToWin || pBGPoint > setNumberToWin)) {
                    setTimeoutDuration(60)
                }else{
                    setTimeoutDuration(60)
                }
                if (a > b) {
                    aOffset = 1
                }else{
                    bOffset = 1
                }
                gameFinished = true
                setTimeoutStartTime(Date.now())
                setOpenTimeout(true)
            }
        }
        setPlayerAGamePointUntilNow(pAGPoint-aOffset)
        setPlayerBGamePointUntilNow(pBGPoint-bOffset)
        setGameFinished(gameFinished)        
    }, [swapped, playerAPoint, playerBPoint, currentMatch, setNumberToWin, matchNumber, gameType])
    

    useEffect(() => {
        var f = []
        for (let i = 0; i <= currentGame ; i++){
            if (!swapped){
                f.push({"left": playerAPoint[currentMatch][i] || 0, "right": playerBPoint[currentMatch][i] || 0})
            }else{
                f.push({"left": playerBPoint[currentMatch][i] || 0, "right": playerAPoint[currentMatch][i] || 0})
            }
        }
        setFinishedGames(f)
    }, [swapped, playerAPoint, playerBPoint, currentMatch, currentGame])


    useEffect(() => {
        let id = token['id'] || ""
        if (!cloud) {
            if (uuid === "") {
                let u = uuidv4()
                setUuid(u)
                id = u
            }else{
                id = uuid
            }
        }

        if (id == "") return;

        if (!sync) return;

        console.log("Push to cloud: ", id, version, lastSyncVersion)

        if ((lastSyncVersion >= currentVersion) && false) {
            console.log("NO NEED TO SYNC----------")
            setSync(false)
            return
        }else{
            console.log("SYNC---------------------")
            //setLastSyncVersion(currentVersion)
        }

        let status = toJson()
        let message = {}
        message["type"] = "matchStatus"
        message["content"] = status

        async function scopedAsyncFunc(id, message){
            try{
                await pushTrace(id, "scoreboard", 
                {
                    "playerA": playerAName[currentMatch] || "", 
                    "playerB": playerBName[currentMatch] || "", 
                    "match": currentMatch, 
                    "game": currentGame,
                    "pointA": playerAPoint[currentMatch] || [], 
                    "pointB": playerBPoint[currentMatch] || [],
                    "track": currentTrack,
                },
                status)
                await syncStatus(id, message)
                setNetError(false)
            }catch(e) {
                console.log("Error happend:", e)
                setNetError(true)
            }
            setSync(false);
        }
        scopedAsyncFunc(id, message);
    }, [sync, 
        uuid, 
        token, 
        cloud,
        currentGame, 
        currentMatch, 
        playerAName, 
        playerAPoint, 
        playerATimeout, 
        playerBName, 
        playerBPoint, 
        playerBTimeout, 
        serveA, 
        swapped, 
        teamAName, 
        teamBName, 
        teamAColour, 
        teamBColour, 
        pointTracking, 
        currentTrack,
        version, 
        lastSyncVersion, 
        currentVersion
        ])

    useEffect(() => {
        let id = token['id'] || ""
        let physicalId = token["phisicalId"] || ""
        if (!cloud || physicalId === "") {
            return
        }

        if (waitingToReconnect) {
            return;
        }

        var wsCurrent = null

        if (!ws.current) {
            var base = ""
            if (baseReflectorURL.substring(0, 8) === "https://"){
                base = "wss://" + baseReflectorURL.substring(8,baseReflectorURL.length);
            }else if(baseReflectorURL.substring(0, 7) === "http://"){
                base = "ws://" + baseReflectorURL.substring(7,baseReflectorURL.length);
            }else{
                base = baseReflectorURL;
            }
            console.log(base)
            ws.current = new WebSocket(base + "api/v1/reflect/match_score_"+physicalId);
            wsCurrent = ws.current

            ws.current.onopen = () => {
                setIsOpen(true)
                console.log("ws opened");
            }
            ws.current.onclose = () => {
                if (ws.current) {
                    console.log("ws closed by server")
                }else{
                    console.log("ws closed by component unmount")
                    return
                }
                if (waitingToReconnect) {
                    return
                }
                setIsOpen(false)
                console.log("ws closed");

                setWaitingToReconnect(true)

                setTimeout(() => setWaitingToReconnect(null), 5000);
            }
        }

        return () => {
            wsCurrent.close();
            ws.current = null;
        };
    }, [waitingToReconnect, cloud, token]);

    useEffect(() => {
        if (!ws.current) return;

        ws.current.onmessage = function (event) {
            const json = JSON.parse(event.data);
            
            var currentLeft = (!swapped? playerAPoint[currentMatch][currentGame]: playerBPoint[currentMatch][currentGame] ) || 0
            var currentRight = (swapped? playerAPoint[currentMatch][currentGame]: playerBPoint[currentMatch][currentGame]) || 0

            console.log("SCOREBOARD DATA", json, syncPaused, currentLeft, currentRight)
            if (json.type == "ping") {
                console.log(">>> PING in gameongoing")
                try{
                    ws.current.send(JSON.stringify({type: "pong"}))
                }catch(e){
                    console.log("Error happend:", e)
                    ws.current.close()
                }
                return
            }
            if (json.type != "control" && !syncPaused ) {
                if (currentLeft>10 || currentRight > 10){
                    if (Math.abs(currentLeft -currentRight) >= 2){
                        console.log("Game already finished")
                        setDataError(true)
                        return
                    }
                }

                if (json.left > 10 || json.right > 10) {
                    if (json.left < 10 || json.right < 10) {
                        if (json.left > 11 || json.right > 11) {
                            console.log("this is not valid: ", json)
                            setDataError(true)
                            return
                        }
                    }else{
                        if (Math.abs(json.left - json.right) > 2){
                            console.log("This is not valid: ", json)
                            setDataError(true)
                            return
                        }
                    }
                    if (currentLeft === 0 && currentRight === 0) {
                        console.log("This is not valid: ", json)
                        setDataError(true)
                        return
                    }
                    
                }

                var leftServe = false
                let leftPlayerServeFirstThisGame = swapped?!playerAServeFirstThisGame:playerAServeFirstThisGame
                if (((currentLeft < 10 || currentRight < 10)) && !expediteMode) {
                    if ((currentLeft + currentRight) % 4 == 0 || (currentLeft + currentRight) % 4 == 1) {
                        leftServe = leftPlayerServeFirstThisGame
                    }else{
                        leftServe = !leftPlayerServeFirstThisGame
                    }
                }else{
                    if ((currentLeft + currentRight) % 2 == 0) {
                        leftServe = leftPlayerServeFirstThisGame
                    }else{
                        leftServe = !leftPlayerServeFirstThisGame
                    }
                }
    
                if (currentLeft != json.left) {
                    handleSetLeftPlayerPoint(json.left, json.left - currentLeft, leftServe)
                }
                    
                if (currentRight != json.right) {
                    handleSetRightPlayerPoint(json.right, json.right - currentRight, !leftServe)
                }
                setDataError(false)
            }
        };
    }, [swapped, playerAPoint, playerBPoint, isOpen, syncPaused, pointTracking, expediteMode, currentMatch, currentGame, playerAServeFirstThisGame]);


    async function pushStatusToBackend(){
        console.log("pushStatusToBackend ", sync, currentVersion, lastSyncVersion)
        setCurrentVersion(currentVersion+1)
        setSync(true)
        setNetError(false)
    }

    const handleFinish = (event) => {
        console.log("let finish the game");
        //setStarted(false);
        setOpenFinish(true)
    };

    const handleSyncPause = (paused) => {
        setSyncPaused(paused)
    }
    const handleAutoModeOn = (on) => {
        setAutoMode(on)
    }

    const handleBestPoint = () => {
        let id = token['id'] || ""
        if (!cloud) {
            return
        }

        if (id == "") return;

        let status = toJson()
        async function scopedAsyncFunc(id, status){
            let date = new Date()
            try{
                await pushTrace(id, "bestpoint", 
                            {
                                "playerA": playerAName[currentMatch] || "", 
                                "playerB": playerBName[currentMatch] || "", 
                                "match": currentMatch, 
                                "game": currentGame,
                                "pointA": playerAPoint[currentMatch] || [], 
                                "pointB": playerBPoint[currentMatch] || [],
                                "label": "DNK",
                                "home_counter": 0,
                                "guest_counter": 0,
                                "local_time": date.toISOString(),
                                "utc_time": date.toUTCString(),
                            },
                            status)
                if ("vibrate" in navigator) {
                    navigator.vibrate([80,30,80]);
                }
                if ("clipboard" in navigator) {
                    let aPoint = playerAPoint[currentMatch] || []
                    let bPoint = playerBPoint[currentMatch] || []
                    let aPP = aPoint[currentGame] || 0
                    let bPP = bPoint[currentGame] || 0

                    let text = (playerAName[currentMatch] || "").toUpperCase() + " vs " + 
                              (playerBName[currentMatch] || "").toUpperCase() + "\n" + 
                              "Match " + (currentMatch + 1) + " Game " + (currentGame + 1) + "\n" +
                              aPP + " vs " + bPP

                    navigator.clipboard.writeText(text)
                }
    
            }catch(e) {
                console.log("Error happend:", e)
            }
        }
        scopedAsyncFunc(id, status);
    }

    const handleNext = (event) => {
        if ((gameType == 1) && (playerAGamePoint > setNumberToWin || playerBGamePoint > setNumberToWin)) {
            setStarted(false)
        }else{
            if (!gameFinished || matchFinished) {
                return
            }
            setSwapped(!swapped)
            setCurrentGame(currentGame + 1);
            setPlayerAPoint({
                ...playerAPoint,
                [currentMatch]:{
                    ...(playerAPoint[currentMatch]),
                    [currentGame+1]: 0
                }
            })
            setPlayerBPoint({
                ...playerBPoint,
                [currentMatch]:{
                    ...(playerBPoint[currentMatch]),
                    [currentGame+1]: 0
                }
            })
        }
        //setOpenTimeout(true);
        pushStatusToBackend();
    }

    const handleSwap = (event) => {
        setSwapped(!swapped);
        pushStatusToBackend();
    };

    const handleExpediteMode = (event) => {
        setExpediteMode(!expediteMode);
        pushStatusToBackend();
    };

    const handleTraceOn = (on) => {
        setTraceOn(on)
    }

    const setPlayerPoint = (isA, n, diff, serving) =>{

        var track = {
            "playerA": playerAName[currentMatch],
            "playerB": playerBName[currentMatch],
            "teamA": teamAName,
            "teamB": teamBName,
            "teamAPoint": teamAResult,
            "teamBPoint": teamBResult,
            "match": currentMatch,
            "game": currentGame,
            "pointA": isA?n:(playerAPoint[currentMatch][currentGame] || 0),
            "pointB": isA?(playerBPoint[currentMatch][currentGame] || 0):n,
            "playerAGamePoint": playerAGamePoint, 
            "playerBGamePoint": playerBGamePoint,
            "playerAWin": isA, 
            "serving": serving, 
            "diff": diff,
            "timestamp": Date.now(),
        }

        if (isA){
            setPlayerAPoint({
                ...playerAPoint,
                [currentMatch]:{
                    ...(playerAPoint[currentMatch]),
                    [currentGame]: n
                }
            })
            if (currentGame == (setNumber-1)) {
                if (n == 5) {
                    if (playerBPoint[currentMatch][currentGame] < 5 && !autoSwapDone && !expediteMode){
                        handleSwap()
                        setAutoSwapDone(true)
                    }
                }
            }
        }else{
            setPlayerBPoint({
                ...playerBPoint,
                [currentMatch]:{
                    ...(playerBPoint[currentMatch]),
                    [currentGame]: n
                }
            })
            if (currentGame == (setNumber-1)) {
                if (n == 5) {
                    if (playerAPoint[currentMatch][currentGame] < 5 && !autoSwapDone){
                        handleSwap()
                        setAutoSwapDone(true)
                    }
                }
            }
        }
        console.log("Point Tracking:", diff, ""+track.pointA+"-"+track.pointB, track.playerAWin, track.serving, pointTracking.length, track, pointTracking)
        
        if (traceOn) {
            if (diff == 1){
                const updatedArray = [...pointTracking, track];
                //setPointTracking((previous)=>[...previous, track])
                setCurrentTrack(track)
                setPointTracking(updatedArray)
            }else if(diff == -1){
                console.log("Point Tracking REMOVE:", diff, ""+track.pointA+"-"+track.pointB, pointTracking.length, track, pointTracking)
                setPointTracking(pointTracking.slice(0,-1))
            }else{
                console.log("Point Tracking: this point won't be tracked: ", diff, ""+track.pointA+"-"+track.pointB)
            }
        }else{
            console.log("Point Tracking: this point won't be tracked: ", diff, ""+track.pointA+"-"+track.pointB)
        }
        pushStatusToBackend();
    };

    const handleSetLeftPlayerPoint = (n, diff, serving) => {
        setPlayerPoint(!swapped, n, diff, serving);
    };

    const handleSetRightPlayerPoint = (n, diff, serving) => {
        setPlayerPoint(swapped, n, diff, serving);
    };

    const handleSetLeftPlayerTimeout = () => {
        if (!swapped) {
            let t = playerATimeout[currentMatch]
            setPlayerATimeout({
                ...playerATimeout,
                [currentMatch]: !t
            })
            if (!t){
                setTimeoutDuration(60)
                setTimeoutStartTime(Date.now())
                setOpenTimeout(true)
            }
        }else{
            let t = playerBTimeout[currentMatch]
            setPlayerBTimeout({
                ...playerBTimeout,
                [currentMatch]: !t
            })
            if (!t){
                setTimeoutDuration(60)
                setTimeoutStartTime(Date.now())
                setOpenTimeout(true)
            }
        }
        pushStatusToBackend();
    };

    const handleSetServerA = (serve) => {
        if (!swapped){
            setServeA({
                ...serveA,
                [currentMatch] : (currentGame%2==0)?serve:!serve
            })
        }else{
            setServeA({
                ...serveA,
                [currentMatch] : (currentGame%2==0)? !serve:serve
            })
        }
        pushStatusToBackend();
    }

    const handleSetRightPlayerTimeout = () => {
        if (swapped) {
            let t = playerATimeout[currentMatch]
            setPlayerATimeout({
                ...playerATimeout,
                [currentMatch]: !t,
            })
            if (!t){
                setTimeoutDuration(60)
                setTimeoutStartTime(Date.now())
                setOpenTimeout(true)
            }
        }else{
            let t = playerBTimeout[currentMatch]
            setPlayerBTimeout({
                ...playerBTimeout,
                [currentMatch]: !t,
            })
            if (!t){
                setTimeoutDuration(60)
                setTimeoutStartTime(Date.now())
                setOpenTimeout(true)
            }
        }
        pushStatusToBackend();
    };

    const handleSetLeftPlayerCards = () => {
        if (!swapped) {
            let c = playerACards[currentMatch]
            setPlayerACards({
                ...playerACards,
                [currentMatch]: (c+1) % 4
            })
        }else{
            let c = playerBCards[currentMatch]
            setPlayerBCards({
                ...playerBCards,
                [currentMatch]: (c+1) % 4
            })
        }
        pushStatusToBackend();
    }

    const handleSetRightPlayerCards = () => {
        if (swapped) {
            let c = playerACards[currentMatch]
            setPlayerACards({
                ...playerACards,
                [currentMatch]: (c+1) % 4
            })
        }else{
            let c = playerBCards[currentMatch]
            setPlayerBCards({
                ...playerBCards,
                [currentMatch]: (c+1) % 4
            })
        }
        pushStatusToBackend();
    }

    const handleCloseTimeout = (next) =>{
        setOpenTimeout(false)
        setChangeMatch(false)
        if (next) {
            handleNext()
        }
    }

    const handleCloseFinish = (really) =>{
        setOpenFinish(false)
        if (really) {
            setStarted(false)
        }
    }

    const timeoutRender = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            if (autoMode && openTimeout) {
                handleCloseTimeout()
                handleNext()
            }
            return <span className={classes.timeoutNumberFormat}>TIMEOUT</span>
        } else {
          // Render a countdown
            return <span className={classes.timeoutNumberFormat}>{minutes.toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false
                        })}:{seconds.toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false
                        })}</span>;
        }
    };
    
    //console.log("network:", sync, netError)
    return (
        <Grid container xs={12} className={classes.content} spacing={0} justify="center"> 
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openTimeout}
                onClose={()=>handleCloseTimeout(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 1000,
                }}
            >
                <Fade in={openTimeout}>
                    <div className={classes.timeoutDialog}>
                        <h2 style={{marginBottom: "0px"}}>Reanudar en</h2>
                        <div style={{margin: "10px"}}>
                            <Countdown date={timeoutStartTime + timeoutDuration * 1000} 
                                        renderer={timeoutRender}/>
                        </div>
                        <div>
                            <Button style={{marginRight: "5px"}}
                                variant="contained" color="secondary" onClick={()=>handleCloseTimeout(false)}
                                startIcon={<CloseIcon />}>
                                Close
                            </Button>

                            <Button style={{marginLeft: "5px"}}
                                variant="contained" color="primary" onClick={()=>handleCloseTimeout(true)}
                                startIcon={<NavigateNextIcon />}>
                                Next
                            </Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openFinish}
                onClose={()=>{handleCloseFinish(false)}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 1000,
                }}
            >
                <Fade in={openFinish}>
                    <div className={classes.finishDialog}>
                        <Grid container xs={12} className={classes.row} spacing={0} justify="center">
                            <Grid container xs={12} justify="center">
                                <h2>Confirm the result</h2>
                            </Grid>
                            <Grid item xs={12}>
                                <PlayerNames
                                    leftPlayerName={swapped?playerBName[0]:playerAName[0]}
                                    rightPlayerName={swapped?playerAName[0]:playerBName[0]}
                                    leftPlayerGamePoint={!swapped?playerAGamePoint:playerBGamePoint}
                                    rightPlayerGamePoint={swapped?playerAGamePoint:playerBGamePoint}
                                    leftPlayerColour={!swapped?teamAColour:teamBColour}
                                    rightPlayerColour={swapped?teamAColour:teamBColour}
                                    nameOnly={false}
                                    leftInputLabel="Left"
                                    rightInputLabel="Right"
                                    />
                            </Grid>

                            <Grid container justify="center" spacing={1} className={classes.actionButtonGridFinish}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={()=>{handleCloseFinish(true)}} 
                                        className={classes.actionButton}
                                        endIcon={<CheckIcon />}>
                                        Yes
                                    </Button> 
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={()=>{handleCloseFinish(false)}} 
                                        className={classes.actionButton}
                                        endIcon={<CloseIcon />}>
                                        No
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
            <Grid container xs={12} spacing={0} justify="center" className={classes.gameResult}>
                {finishedGames.map((value) => (
                    <Chip size="small" variant="outlined" label={value["left"] + "-" + value["right"]} 
                          className={(value["left"] >= value["right"])?classes.gameResultItemLeft:classes.gameResultItemRight} />
                ))}
            </Grid>
            <Grid container xs={12} className={classes.row} spacing={1} justify="center">
                <PlayerNames
                    leftPlayerName={swapped?playerBName[currentMatch]:playerAName[currentMatch]}
                    rightPlayerName={swapped?playerAName[currentMatch]:playerBName[currentMatch]}
                    leftPlayerGamePoint={!swapped?playerAGamePoint:playerBGamePoint}
                    rightPlayerGamePoint={swapped?playerAGamePoint:playerBGamePoint}
                    leftPlayerColour={!swapped?teamAColour:teamBColour}
                    rightPlayerColour={swapped?teamAColour:teamBColour}
                    leftInputLabel={gameType==0?"Left":(!swapped)?teamAName:teamBName}
                    rightInputLabel={gameType==0?"Right":swapped?teamAName:teamBName}
                    nameOnly={true}
                    />
            </Grid>
            <Grid container xs={12} className={classes.row}  spacing={1} justify="center">
                <Grid item xs={12} sm={8} md={10} lg={8} xl={6}>
                    <GameState
                        leftPlayerTimeout={!swapped?playerATimeout[currentMatch]:playerBTimeout[currentMatch]}
                        rightPlayerTimeout={swapped?playerATimeout[currentMatch]:playerBTimeout[currentMatch]}
                        setLeftPlayerTimeout={()=>handleSetLeftPlayerTimeout()}
                        setRightPlayerTimeout={()=>handleSetRightPlayerTimeout()}
                        leftPlayerCards={!swapped?playerACards[currentMatch]:playerBCards[currentMatch]}
                        rightPlayerCards={swapped?playerACards[currentMatch]:playerBCards[currentMatch]}
                        setLeftPlayerCards={()=>handleSetLeftPlayerCards()}
                        setRightPlayerCards={()=>handleSetRightPlayerCards()}
                        leftPlayerServeFirst={!swapped?serveA[currentMatch]:!serveA[currentMatch]}
                        leftPlayerServeFirstThisGame={swapped?!playerAServeFirstThisGame:playerAServeFirstThisGame}
                        leftPlayerGamePoint={!swapped?playerAGamePointUntilNow:playerBGamePointUntilNow}
                        rightPlayerGamePoint={swapped?playerAGamePointUntilNow:playerBGamePointUntilNow}
                        leftPlayerPoint={currentLeftPlayerPoint}
                        expediteMode={expediteMode}
                        setLeftPlayerPoint={(n, serving)=>{handleSetLeftPlayerPoint(n, n-currentLeftPlayerPoint, serving)}}
                        rightPlayerPoint={currentRightPlayerPoint}
                        setRightPlayerPoint={(n, serving)=>{handleSetRightPlayerPoint(n, n-currentRightPlayerPoint, serving)}}
                        setLeftServeFirst={(serve)=>{handleSetServerA(serve)}} />
                </Grid> 
                <Grid item xs={11}>
                    <Grid container justify="center" spacing={1} className={classes.actionButtonGrid}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={()=>{handleFinish()}} 
                                className={classes.actionButton}
                                endIcon={<StopIcon />}>
                                FINAL
                            </Button> 
                        </Grid>
                        <Grid item>
                            <Tooltip title="Switch the table side">
                                <Button
                                    variant="contained"
                                    color="default"
                                    onClick={handleSwap} 
                                    className={classes.actionButton}
                                    endIcon={<SwapHorizIcon />}
                                >Swap</Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={expediteMode?"Expedite Mode Activated":"Expedite Mode Disabled"}>
                                <Button
                                    variant="contained"
                                    color= {expediteMode?"secondary":"default"}
                                    onClick={handleExpediteMode} 
                                    className={classes.actionButton}
                                    endIcon={<SpeedIcon />}
                                >Expedite</Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={!gameFinished || matchFinished}
                                variant="contained"
                                color="primary"
                                onClick={handleNext} 
                                className={classes.actionButton}
                                endIcon={<NavigateNextIcon />}
                            >
                                Next
                            </Button> 
                        </Grid>
                    </Grid>
                </Grid>
                {cloud && <Grid item xs={11}>
                    <Grid container justify="center" spacing={1} className={classes.actionButtonGrid}>
                        
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                variant="outlined"
                                onClick={()=>handleSyncPause(!syncPaused)} 
                                className={classes.actionButton}
                                endIcon={syncPaused?<SyncDisabledIcon />:<SyncIcon/>}
                            >
                                {syncPaused?"UnSynced":"Synced"}
                            </Button> 
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                variant="outlined"
                                onClick={()=>handleAutoModeOn(!autoMode)} 
                                className={classes.actionButton}
                                endIcon={autoMode?<AirplanemodeActiveIcon />:<AirplanemodeInactiveIcon/>}
                            >
                                {autoMode?"Pilot-On":"Pilot-Off"}
                            </Button> 
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={()=>handleBestPoint()} 
                                className={classes.actionButton}
                                endIcon={<FavoriteIcon/>}
                            >
                                Best Point
                            </Button> 
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="success"
                                variant="outlined"
                                onClick={()=>handleTraceOn(!traceOn)} 
                                className={classes.actionButton}
                                endIcon={<SubjectIcon/>}
                            >
                                {traceOn?"Trace On":"Trace Off"}
                            </Button> 
                        </Grid>
                    </Grid>
                </Grid>}

                <Grid item xs={12}>
                    <Grid container justify="center">
                        <div className={ws.current?classes.networkConnected:classes.networkDisconnected}/>
                        <div className={dataError?classes.networkError:classes.networkConnected}/>
                        <div className={sync?classes.networkConnected:classes.networkDisconnected}/>
                        <div className={netError?classes.networkError:classes.networkConnected}/>
                    </Grid>
                    
                </Grid>

                <Grid item xs={12}>
                    <Grid container justify="center"  className={classes.copyright}>
                    &#xa9; 2023 KandoLab.com (v1.0)
                    </Grid>
                </Grid>
            </Grid>
        </Grid>)
}

export default GameOngoing;

const useStyles = makeStyles((theme) => ({
    content: {
      marginTop: "20px",
    },
    gameResult: {
        marginBottom: "10px"
    },
    gameResultItemLeft: {
        marginLeft: "2px",
        marginRight: "2px",
    },
    teamResult: {
        marginLeft: "10px",
        marginRight: "10px",
        paddingTop: "2px",
        paddingLeft: "5px",
        paddingRight: "5px",
        backgroundColor: "#000000",
        color: "white",
        fontWeight: "bold",
    },
    gameResultItemRight: {
        marginLeft: "2px",
        marginRight: "2px",
        backgroundColor: "#ff50c2",
        color: "white"
    },
    row: {
        marginTop: "5px",
        marginLeft: "10px",
        marginRight: "10px", 
        marginBottom: "0px"
    },
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    copyright: {
        color: "#BBBBBB",
        paddingTop: "0px"
    },
    setNumberGrid:{
        paddingTop: "20px",
    },
    actionButtonGrid: {
        paddingTop: "5px"
    },
    actionButtonGridFinish: {
        paddingTop: "20px"
    },
    actionButton: {
        //height: "12vw",
        //width: "40vw",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderRadius: "20px",
        fontWeight: "",
        fontSize: "11px",
        width: "10vw",
        [theme.breakpoints.up('sm')]: {
            height: "8vh",
            width: "16vw",
            fontSize: "2.2vh",
            boarderRadius: "20px"
        },
        [theme.breakpoints.down('xs')]: {
            height: "8vh",
            width: "40vw",
            fontSize: "2.2vh",
            boarderRadius: "20px"
        },
    },
    pointDownButton:{
        marginTop: "2px",
        width: "30px",
        backgroundColor: "#333333",
    },
    playerTextField: {
        heigh: "15px",
    },
    logoImg:{
        //filter: "invert(1)",
        paddingRight:"5px"
    },
    appbar: {
        background : '#000000',
    },
    formControlLabel: {
        fontSize:'12px'
    },
    currentScore: {
        backgroundColor: "black",
        color: "white",
        width: "24px",
        fontSize: "18px",
        fontWeight: "bold",
        textAlign: "center",
        paddingTop: "6px",
        paddingBottom: "6px",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    timeoutDialog: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #999',
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 7, 3),
        textAlign: "center"
    },
    finishDialog: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #999',
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 0, 3),
    },
    timeoutNumberFormat:{
        fontSize: "420%",
        fontWeight: "bold",
        paddingLeft: "10px", 
        paddingRight: "10px", 
    },
    networkConnected:{
        marginLeft: "1vw",
        marginTop: "5px",
        width: "1vw",
        height: "1vw",
        borderRadius: "1vw",
        borderColor: "#cccccc",
        borderStyle: "solid",
        borderWidth: "1px",
        backgroundColor: "#00ff00",
        textAlign:"center",
        [theme.breakpoints.up('sm')]: {
            width: "5px",
            marginLeft: "2px",
            height: "5px",
            borderRadius: "5px",
        },
        [theme.breakpoints.up('md')]: {
            width: "5px",
            marginLeft: "2px",
            height: "5px",
            borderRadius: "5px",
        },
    },
    networkDisconnected:{
        marginLeft: "1vw",
        marginTop: "5px",
        width: "1vw",
        height: "1vw",
        borderRadius: "1vw",
        borderColor: "#cccccc",
        borderStyle: "solid",
        borderWidth: "1px",
        backgroundColor: "#bbbbbb",
        textAlign:"center",
        [theme.breakpoints.up('sm')]: {
            width: "5px",
            marginLeft: "2px",
            height: "5px",
            borderRadius: "5px",
        },
        [theme.breakpoints.up('md')]: {
            width: "5px",
            marginLeft: "2px",
            height: "5px",
            borderRadius: "5px",
        },
    },
    networkError:{
        marginLeft: "1vw",
        marginTop: "5px",
        width: "1vw",
        height: "1vw",
        borderRadius: "1vw",
        borderColor: "#cccccc",
        borderStyle: "solid",
        borderWidth: "1px",
        backgroundColor: "#ff0000",
        textAlign:"center",
        [theme.breakpoints.up('sm')]: {
            width: "5px",
            marginLeft: "2px",
            height: "5px",
            borderRadius: "5px",
        },
        [theme.breakpoints.up('md')]: {
            width: "5px",
            marginLeft: "2px",
            height: "5px",
            borderRadius: "5px",
        },
    },
    
  }));
