import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
const baseURLV2 = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_V2_URL_DEVEL : process.env.REACT_APP_API_V2_URL

function Type5Scoreboard(props) {
    const localClasses = useStyles(props.config.theme);
    return (
        <div className={localClasses.videoDiv}>
           <video width="100%" autoPlay muted playsinline poster="https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Solid_green.svg/1024px-Solid_green.svg.png" style={{ backgroundColor: 'green' }}>
              <source src={props.config.videoUrl} type="video/mp4"/>
           </video>
        </div>
    )
}

export default Type5Scoreboard;

const useStyles = makeStyles((theme) => ({
    videoDiv: {
        position: "fixed",
        left: 0, //'30px',
        top: 0, //'30px',
        width: ( props ) => props.width,
        height: ( props ) => props.height,
    }
}));
