import React, {useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Chip, Avatar, Button} from '@material-ui/core';


import '@fontsource/roboto';
import PlayerNames from './PlayerNames';
import ExtendedNameFild from './ExtendedNameFild';
import changePersonIcon from '../../statics/images/change_person.png'

function ControlPhotoView(props) {
    let fullWidth = 1920
    let fullHeight = 1080
    

    console.log("config:", props.config)

    var theme = {
        "width": fullWidth,
        "height": fullHeight,
        "columnWidth": parseInt(fullWidth / 14),
        "teamMarginTop": parseInt(fullHeight/16*3),
        "contentMarginTop": fullHeight/16*5,
        "contentMarginLeft": parseInt(fullWidth / 14 * 2.5),
        "contentWidth": parseInt(fullWidth / 14 * 9),
        "contentHeight": fullHeight/16*8,
        "rowHeight": fullHeight/16,
        "playerResultRowHeight": fullHeight/16*7/props.matchNumber,
        "borderDebug": props.config["border"]?"1px solid #000000":"0px solid #000000",
        "config": props.config,
        "backgroundColor": props.config["backgroundColor"],
        "resultColor": props.config["resultColor"],
    }
    const classes = useStyles(theme);
    const [changed, setChanged] = useState({});

    const handleChanged = (index) => {
        setChanged({...changed, [index]: changed[index] ? false : true});
    }

    var gameId = props.currentMatch * 10 + props.currentGame

    return (
        <div className={classes.pageContent}>  
            <div className={classes.backGround}>
                <img className={classes.backgroundImg} src={props.imageUrl+ "/background_image?random="+props.seed}/>
            </div>   

            <div className={classes.teamResultRow}>
                <div className={classes.teamLogoAndName}>
                    <div className={classes.teamAvatarDiv}>
                        <Button
                            color="secondary"
                            component="label"
                            >
                            <Avatar
                                alt={props.teamAName}
                                src={props.imageUrl+ "/team_a_logo?random="+props.seed}
                                className={classes.teamAvatar}
                            />
                            <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "team_a_logo"); e.target.value=''} } />
                        </Button>  
                    </div> 
                    <div className={classes.teamAName}>
                        <ExtendedNameFild name={props.teamAName} fontSize={theme.rowHeight * 0.5}/>
                    </div>
                </div>
                <div className={classes.teamResult}>
                    {"" + props.teamAResult + " - " + props.teamBResult}
                </div>
                <div className={classes.teamLogoAndName} style={{justifyContent: "flex-end"}}>
                    <div className={classes.teamBName}>
                        <ExtendedNameFild name={props.teamBName} fontSize={theme.rowHeight * 0.5}/>
                    </div>
                    <div className={classes.teamAvatarDiv}>
                        <Button
                            color="secondary"
                            component="label"
                            >
                            <Avatar
                                alt={props.teamBName}
                                src={props.imageUrl+ "/team_b_logo?random="+props.seed}
                                className={classes.teamAvatar}
                            />
                            <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "team_b_logo"); e.target.value=''} } />
                        </Button>  
                    </div> 
                </div>
            </div>

            <div className={classes.resultContent}>
            {
                props.matches.map((m, i) => {
                    console.log(i, " match number:", props.matchNumber)
                    if (i < props.matchNumber) {
                        let gameResult = []
                        for (let g=0 ; g<props.setNumber ; g++) {
                            if (i*10+0 <= props.gameId) {
                                let a = m["points"][g]["playerA"]
                                let b = m["points"][g]["playerB"]
                                if (a>0 || b>0){
                                    gameResult.push("" + a + "-" + b)
                                }
                            }
                        }
                        let playerAUrl = props.imageUrl+ "/player_a_logo_"+i+"?random="+props.seed
                        let playerBUrl = props.imageUrl+ "/player_b_logo_"+i+"?random="+props.seed
                        return (
                            <div className={classes.resultContentRow} >
                                <div className={classes.playerAvatarAndName}>
                                    <div className={classes.playerName} style={{textAlign: "right"}} onClick={()=>handleChanged(i *2)}>
                                        <ExtendedNameFild name={props.playerAName[i]} fontSize={theme.rowHeight * 0.4}/>
                                    </div>
                                    <div className={classes.playerAvatarDiv}>
                                        <Button
                                            color="secondary"
                                            component="label"
                                            >
                                            <Avatar
                                                alt={props.playerAName[i]}
                                                src={playerAUrl}
                                                className={classes.playerAvatar}
                                            />
                                            <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "player_a_logo_"+i); e.target.value=''} } />
                                        </Button>
                                        <div className={classes.playerChangedDiv}>
                                            {changed[i*2] && <img src={changePersonIcon} className={classes.playerChanged}/>}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.playerResult} style={{color: (i<=props.currentMatch)?"#000000":"#bbbbbb"}}>
                                    <div className={classes.playerResultHighline}>
                                        {i<=props.currentMatch?"" + props.matchSummary[i][0] + " - " + props.matchSummary[i][1] : " VS "}
                                    </div>
                                    {i<=props.currentMatch && gameResult.length > 0 && props.config["gameDetail"] &&
                                        <div className={classes.setResultStyle} >
                                            {gameResult.map((v) => {
                                                return (
                                                    <div className={classes.setResultItemStyle}>
                                                        {v + " "}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                </div>
                                <div className={classes.playerAvatarAndName} style={{justifyContent: "flex-start"}}>
                                    <div className={classes.playerAvatarDiv}>
                                        <div className={classes.playerChangedDiv}>
                                            {changed[i*2+1] && <img src={changePersonIcon} className={classes.playerChanged}/>}
                                        </div>
                                        <Button
                                            color="secondary"
                                            component="label"
                                            >
                                            <Avatar
                                                alt={props.playerBName[i]}
                                                src={playerBUrl}
                                                className={classes.playerAvatar}
                                            />
                                            <input type="file" accept="image/*" hidden onChange={(e) => {props.uploadAsset(e, "player_b_logo_"+i); e.target.value=''} } />
                                        </Button>
                                    </div>
                                    <div className={classes.playerName} style={{textAlign: "left"}} onClick={()=>handleChanged(i *2+1)}>
                                        <ExtendedNameFild name={props.playerBName[i]} fontSize={theme.rowHeight * 0.4}/>
                                    </div>
                                </div>
                            </div>

                        )
                    }else{
                        return (<></>)
                    }
                })
            }
            </div>
        </div>
    )
}

export default ControlPhotoView;


const useStyles = makeStyles({
    pageContent: props => ({
        //margin: "0px",
        position: "relative",
        //backgroundSize: 'contain',
        //backgroundImage: 'url("https://api.kandolab.com/api/v2/cameras/6dee49ec-4b10-4029-97a4-7da47a21e67b/layer/1?preview=true&filename=c9l0t1665854829_all_patrocinadores.gif")',
        width:  props.width,
        height: props.height, 
    }),
    backGround: props => ({
        position: "absolute",
        left: 0,
        top: 0,
        width: props.width,
        height: props.height 
    }),
    backgroundImg: props => ({
        width: props.width,
        height: props.height,
        display: props.config["backgroundImage"]?"block":"none",
    }),
    resultContent: props => ({
        position: "absolute",
        left: props.contentMarginLeft,
        top: props.contentMarginTop,
        width: props.contentWidth,
        height: props.contentHeight,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    }),
    resultContentRow: props => ({
        display: "flex",
        flexDirection: "row",
        height: props.playerResultRowHeight,
        border: props.borderDebug,
        alignItems: "center",

        backgroundColor: props.config["backgroundFilled"]?props.backgroundColor:"transparent"
    }),
    playerAvatarDiv: props => ({
        width: props.rowHeight * 1.5,
        height: props.rowHeight,
        marginRight: props.rowHeight/5,
        marginLeft: props.rowHeight/5,
        flexDirection: "row",
        alignItems: "flex-end",
        display: props.config["playerPhoto"]?"flex":"none",
    }),
    playerAvatar: props => ({
        width: props.rowHeight*5/6,
        height: props.rowHeight*5/6,
    }),
    playerAvatarAndName: props => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: props.columnWidth * 3.5,
    }),
    playerChanged: props => ({
        width: props.rowHeight*2.5/6,
        height: props.rowHeight*2.5/6,
    }),
    playerChangedDiv: props => ({
        marginBottom: props.rowHeight/12,
        width: props.rowHeight*2.5/6,
        height: props.rowHeight*2.5/6,
    }),
    playerName: props => ({
        fontFamily: "Arial, Helvetica, sans-serif",
        marginLeft: props.rowHeight/5,
        fontSize: props.rowHeight * 0.4,
        width: props.columnWidth * 2.5,
        fontWeight: "bold",
        textTransform: 'uppercase',
        display: props.config["playerName"]?"block":"none",
    }),
    playerResult: props => ({
        width: props.columnWidth*2,
    }),
    playerResultHighline: props => ({
        textAlign: "center",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontWeight: "bold",
        fontSize: props.rowHeight * 0.5,
        width: props.columnWidth,
        marginLeft: props.columnWidth/2,
        marginRight: props.columnWidth/2,
        backgroundColor: props.config["backgroundFilled"]?props.resultColor:"transparent"
    }),
    teamResultRow: props => ({
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        left: props.contentMarginLeft,
        top: props.teamMarginTop,
        textAlign: "center",
        
        justifyContent: "space-between",
        alignItems: "center",
        height: props.rowHeight * 1.5,
        width: props.contentWidth,
        border: props.borderDebug,
        backgroundColor: props.config["backgroundFilled"]?props.backgroundColor:"transparent"
    }),
    teamLogoAndName: props => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: props.columnWidth * 4,
        height: props.rowHeight * 1.5,
    }),
    teamAName: props => ({
        textAlign: "left",
        fontFamily: "Arial, Helvetica, sans-serif",
        marginLeft: props.rowHeight/5,
        fontSize: props.rowHeight * 0.40,
        width: props.columnWidth * 4 - props.rowHeight*1.25,
        fontWeight: "bold",
        textTransform: 'uppercase',
        display: props.config["teamName"]?"block":"none",
    }),
    teamBName: props => ({
        textAlign: "right",
        fontFamily: "Arial, Helvetica, sans-serif",
        marginRight: props.rowHeight/5,
        marginRight: props.rowHeight/10,
        fontSize: props.rowHeight * 0.4,
        width: props.columnWidth * 4 - props.rowHeight*1.25,
        fontWeight: "bold",
        textTransform: 'uppercase',
        display: props.config["teamName"]?"block":"none",
    }),
    teamAvatarDiv: props => ({
        width: props.rowHeight*1.5,
        height: props.rowHeight*1.5,
        display: props.config["teamLogo"]?"block":"none",
    }),
    teamAvatar: props => ({
        width: props.rowHeight*1.5*5/6,
        height: props.rowHeight*1.5*5/6,
        marginTop: "3px",
        backgroundColor: "#ffffff",
    }),
    teamResult: props => ({
        textAlign: "center",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontWeight: "bold",
        fontSize: props.rowHeight * 0.6,
        width: props.columnWidth,
        backgroundColor: props.config["backgroundFilled"]?props.resultColor:"transparent"
    }),
    setResultStyle: props => ({
        fontFamily: "Arial, Helvetica, sans-serif",
        fontWeight: "",
        color: "#ffffff", 
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: -5,
        paddingTop: 0,
        fontSize: props.rowHeight * 0.2
    }),

    setResultItemStyle: {
        backgroundColor: "#333",
        width: 40,
        padding: 4,
        borderRadius: "30px",
        marginLeft: "1px",
        marginRight: "1px"
    }

});