import React, { useContext } from 'react';
import '@fontsource/roboto';
import { GameContext } from '../entities/GameContext'
import GroupSetup from './GroupSetup';
import GameOngoing from './GameOngoing';
import GroupOngoing from './GroupOngoing';

function Group() {
    const { groupStarted, started } = useContext(GameContext);

    //console.log("Game started:", groupStarted)
    
    if (groupStarted) {
        if (started) {
            return (<GameOngoing />);            
        }else{
            return (<GroupOngoing />);
        }
    }else{
        return (<GroupSetup />);
    }
    
}

export default Group;
