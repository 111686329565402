import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '@fontsource/roboto';
import { GameContext } from '../entities/GameContext'
import logo from '../statics/images/kandolab-logo_v1.png';
import { Grid, Button, Tooltip } from '@material-ui/core';

import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';

function GameSelect() {
    const { setGameType, 
            setPlayerAPoint, 
            setPlayerBPoint,
            setCurrentMatch,
            setCurrentGame,
            setStarted,
            setPlayerATimeout,
            setPlayerBTimeout,
            setPlayerAName, 
            setPlayerBName,
            setDoubleMatchNumber,
            setDoubleInitialConfig,
            setGroupTeamConfig,
            setPlayerLabel,setMatchNumber,
            setGameTypeLabel, 
            stageStatus, setStageStatus,
            setPlayerACards, setPlayerBCards,
            setPointTracking
        } = useContext(GameContext);
    const classes = useStyles();

    const reset = (playerANameStart, playerBNameStart, doubleMatch, doubleConfig) => {
        setPlayerAPoint({0:{0:0}})
        setPlayerBPoint({0:{0:0}})
        setCurrentMatch(0)
        setCurrentGame(0);
        setStarted(false);
        setStageStatus("")
        setPlayerATimeout({0:false});
        setPlayerBTimeout({0:false});
        setPlayerACards({0:0});
        setPlayerBCards({0:0});
        setPointTracking([])
        setPlayerAName(playerANameStart)
        setPlayerBName(playerBNameStart)
        setDoubleMatchNumber(doubleMatch)
        setDoubleInitialConfig(doubleConfig)
    };

    const handleGroupModificado = (description) => {
        setGroupTeamConfig([["A1","B2",[0,3],[0,4]],["A2","B1",[1,4],[1,3]],["A3","B3",[2],[2]]])
        setPlayerLabel({
            0:["A1","B2"],
            1:["A2","B1"],
            2:["A3","B3"],
            3:["A1","B1"],
            4:["A2","B2"],
        })
        setGameTypeLabel(description)
        setMatchNumber(5)
        setGameType(1);
        reset({},{},-1,null);
    };

    const handleGroupNoDouble= (description) => {
        setGroupTeamConfig([["A","Y",[0,3],[0,4]],["B","X",[1,5],[1,3]],["C","Z",[2,4],[2,5]]])
        setPlayerLabel({
            0:["A","Y"],
            1:["B","X"],
            2:["C","Z"],
            3:["A","X"],
            4:["C","Y"],
            5:["B","Z"],
        })
        setGameTypeLabel(description)
        setMatchNumber(6)
        setGameType(1);
        reset({},{},-1,null);
    }

    const handleGroupDouble4thGame = (description) => {
        setGroupTeamConfig([["A","Y",[0,4],[0,5]],["B","X",[1,6],[1,4]],["C","Z",[2,5],[2,6]]])
        setPlayerLabel({
            0:["A","Y"],
            1:["B","X"],
            2:["C","Z"],
            3:["",""],
            4:["A","X"],
            5:["C","Y"],
            6:["B","Z"],
        })
        setGameTypeLabel(description)
        setMatchNumber(7)
        setGameType(1);
        reset({3:"Dobles ABC"},{3:"Dobles XYZ"},3, null);
    };

    const handleGroupDouble7thGame = (description) => {
        // ["A","Y",[0,3],[2,4]] => [LEFT_LABEL, RIGHT_LABEL, LEFT_PLAYER_MATCHES, RIGHT_LAYER_MATCHES]
        // Basically, means A (which is player 0) will play game 0 and 3, Y (which is player 2 of B) will player game 2 and 4
        setGroupTeamConfig([["A","Y",[0,3],[0,4]],["B","X",[1,5],[1,3]],["C","Z",[2,4],[2,5]]])
        setPlayerLabel({
            0:["A","Y"],
            1:["B","X"],
            2:["C","Z"],
            3:["A","X"],
            4:["C","Y"],
            5:["B","Z"],
            6:["",""],
        })
        setGameTypeLabel(description)
        setMatchNumber(7)
        setGameType(1);
        reset({6:"Dobles ABC"},{6:"Dobles XYZ"}, 6, null);
    };

    const handleGroup2Persons = (description) => {
        setGroupTeamConfig([["A","X",[0,3],[0,4]],["B","Y",[1,4],[1,3]]])
        setPlayerLabel({
            0:["A","X"],
            1:["B","Y"],
            2:["A|B","X|Y"],
            3:["A","Y"],
            4:["B","X"],
        })
        setGameTypeLabel(description)
        setMatchNumber(5)
        setGameType(1);
        reset({2:"AB"},{2:"XY"},2,{teamA: [0,1], teamB: [0,1]});
    }

    const handleIndividual = (description) => {
        setGameTypeLabel(description)
        setGameType(0);
        reset({},{}, -1, null);
    };

    const handleWoldCup = (description) => {
        setGroupTeamConfig([["A","X",[0,3],[0,4]],["B","Y",[1,4],[1,3]],["C","Z",[2,2],[2,2]]])
        setPlayerLabel({
            0:["A","X"],
            1:["B","Y"],
            2:["C","Z"],
            3:["A","Y"],
            4:["B","X"],
        })
        setGameTypeLabel(description)
        setMatchNumber(5)
        setGameType(1);
        reset({},{},-1, null);
    }

    const handleOlympicGame = (description) => {
        setGroupTeamConfig([["A","X",[1,3],[1,4]],["B","Y",[4,4],[3,3]],["C","Z",[2,2],[2,2]]])
        setPlayerLabel({
            0:["B|C","Y|Z"],
            1:["A","X"],
            2:["C","Z"],
            3:["A","Y"],
            4:["B","X"],
        })
        setGameTypeLabel(description)
        setMatchNumber(5)
        setGameType(1);
        reset({0:"BC"},{0:"YZ"}, 0, {teamA: [4,2], teamB: [3,2]});
    }
    const handleTeamScoreOnly = (description) => {
        setGameTypeLabel(description)
        setGameType(2);
        reset({},{}, -1, null);
    }
    const gameConfig = [
        {
            name: "Singles",
            description: "Individual Competitions",
            handler: handleIndividual,
            help: "Individual Competitions",
            icon: <PersonIcon />,
            color: "secondary"
        },
        {
            name: "Team Result",
            description: "Team Result",
            handler: handleTeamScoreOnly,
            help: "Team Result",
            icon: <GroupIcon />,
            color: "disabled"
        },
        {
            name: "Olympic Team Format",
            description: "Olympic Team Format",
            handler: handleOlympicGame,
            help: "B|C-Y|Z, A-X, C-Z, A-Y, B-X",
            icon: <GroupIcon />,
            color: "secondary"
        },
        {
            name: "World Cup",
            description: "World Cup Format",
            handler: handleWoldCup,
            help: "A-X, B-Y, C-Z, A-Y, B-X",
            icon: <GroupIcon />,
            color: "secondary"
        },
        {
            name: "2 People Team with Doubles",
            description: "2 People Team with Doubles",
            handler: handleGroup2Persons,
            help: "A-X, B-Y, A|B-X|Y, A-Y, B-X",
            icon: <GroupIcon />,
            color: "secondary"
        },
        {
            name: "Swaythling Without Doubles",
            description: "Swaythling Without Doubles - Spanish Super Format",
            handler: handleGroupNoDouble,
            help: "A-Y, B-X, C-Z, A-X, C-Y, B-Z",
            icon: <GroupIcon />,
            color: "primary"
        },
        {
            name: "Swaythling With Doubles in 4th Match",
            description: "Swaythling With Doubles in 4th Match",
            handler: handleGroupDouble4thGame,
            help: "A-Y, B-X, C-Z, D, A-X, C-Y, B-Z",
            icon: <GroupIcon />,
            color: "primary"
        },
        {
            name: "Swaythling With Doubles in 7th Match",
            description: "Swaythling With Doubles in 4th Match - Spanish Honor Format",
            handler: handleGroupDouble7thGame,
            help: "A-Y, B-X, C-Z, A-X, C-Y, B-Z, D",
            icon: <GroupIcon />,
            color: "primary"
        },
        {
            name: "Modified Swaythling",
            description: "Modified Swaythling Format",
            handler: handleGroupModificado,
            help: "A1-B2, A2-B1, A3-B3, A1-B1, A2-B2",
            icon: <GroupIcon />,
            color: "disabled"
        },
    ]
    return (
        <div className={classes.pageContent}  >     
            <div style={{margin: "0", height: "100%", paddingLeft: "20px",  paddingRight: "20px", paddingTop: "20px", backgroundColor: "white"}}>
                <Grid item xs={12} className={classes.content}>
                    <div style={{textAlign:"center", display:"flex", direction:"row", justifyContent:"center"}}>
                        <div><img className={classes.logoImg} src={logo} width="40px"/></div>
                        <div style={{paddingTop: "5px", height: "60px", fontSize: "30px", fontWeight: "bold"}}>KandoLAB</div>
                    </div>
                </Grid>
                <Grid container className={classes.content} spacing={0} >
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={1}  alignItems="center">
                        {
                            gameConfig.map((config, index) => {
                                return (
                                    <Grid item xs={6} sm={4} md={4} lg={2} >
                                        <Tooltip title={<h1>{config.help}</h1>}>
                                            <Button
                                                variant="contained"
                                                color={config.color}
                                                size="large"
                                                className={classes.button}
                                                onClick={()=>{config.handler(config.description)}} 
                                                endIcon={config.icon} fullWidth style={{height: "70px"}} >
                                                {config.name}
                                            </Button>
                                        </Tooltip>
                                    </Grid>) 
                            })
                        }
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify="center"  className={classes.copyright}>
                        &#xa9; 2023 KandoLab.com
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>)
}

export default GameSelect;

const useStyles = makeStyles((theme) => ({
    pageContent:{
        //padding: theme.spacing(0, 2),
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    content: {
        marginRight: "0px",
        marginLeft: "0px",
        textAlign:"center",
    },
    logoImg:{
        paddingRight:"5px",
        marginBottom:"-15px"
    },
    copyright: {
        color: "#BBBBBB",
        paddingTop: "10px"
    },
    buttonGrid: {
        paddingTop: "10px"
    },
    button: {
        borderRadius: "10px",
        paddingLeft:"10%",
        paddingRight:"10%",
        fontSize: "10px",
        width: "100%",
    }
}));