import html2canvas from "html2canvas";

const exportAsImageAndUpload = async (element, url) => {
    const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        backgroundColor: null,
    });
    const image = canvas.toDataURL("image/png", 0.5);
    uploadImageBlob(image, url);
};

const uploadImageBlob = (blob, url) => {
    console.log("DATA: ", blob, "->", url)
    const headers = {
        'Accept': 'application/json, */*',
        'Content-type': 'application/json',
    }
  
    return fetch(url, {
      method: 'POST',
      headers: headers,
      body : JSON.stringify({
                  image: blob,
                  type: "image/png"
                })
    }).then((res) => {
        console.log(res)
        if (res.ok && res.status === 200) {
          return res.json()
        }else{
          return null
        }
      }
    ).catch(() => {
      return null
    })
}

export default exportAsImageAndUpload