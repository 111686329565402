import React, { useContext, useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import '@fontsource/roboto';
import Grid from '@material-ui/core/Grid';
import { GameContext } from '../entities/GameContext';

import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import PlayerNames from './components/PlayerNames';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import StopIcon from '@material-ui/icons/Stop';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, TextField } from '@material-ui/core';

import Countdown from 'react-countdown';

import MuiAlert from '@material-ui/lab/Alert';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import AirplanemodeInactiveIcon from '@material-ui/icons/AirplanemodeInactive';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      paddingLeft: "3px",paddingRight: "3px",
      paddingTop: "2px",paddingBottom: "2px",
    },
    body: {
      fontSize: 12,
      borderWidth: 1, borderColor: '#DDDDDD',borderStyle: 'solid',
      marginLeft: "1px",marginRight: "1px",
      paddingLeft: "3px",paddingRight: "3px",
      paddingTop: "2px",paddingBottom: "2px",
      textTransform: 'uppercase',
    },
  }))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

function GroupOngoing() {
    const classes = useStyles();

    const {
        setNumber,matchNumber,setCurrentMatch,
        setStarted,teamAName, teamBName, setTeamAName, setTeamBName,
        teamAColour, teamBColour,
        setTeamAColour, setTeamBColour, 
        playerAName, setPlayerAName,
        playerBName, setPlayerBName,
        playerAPoint, playerBPoint, 
        setPlayerAPoint,setPlayerBPoint,
        setCurrentGame, currentGame, currentMatch, 
        playerATimeout, setPlayerATimeout,
        playerBTimeout, setPlayerBTimeout,
        setGroupStarted, playerLabel,
        doubleMatchNumber,
        autoMode, setAutoMode,
        playerACards, setPlayerACards,
        playerBCards, setPlayerBCards,
        cloud, setExpediteMode
    } = useContext(GameContext);
    
    const [ openFinish, setOpenFinish ] = useState(false)
    const [ openDoubleTeam, setOpenDoubleTeam ] = useState(false)
    const [ openDeleteResult, setOpenDeleteResult ] = useState(false)
    const [ matchNumberToChange, setMatchNumberToChange ] = useState(0)
    const [ gameNumberToChange, setGameNumberToChange ] = useState(0)
    const [ currentResult, setCurrentResult ] = useState("")
    const [ currentResultA, setCurrentResultA ] = useState(0)
    const [ currentResultB, setCurrentResultB ] = useState(0)

    const [ newResultA, setNewResultA ] = useState(0)
    const [ newResultB, setNewResultB ] = useState(0)
    
    const [ openChangeName, setOpenChangeName ] = useState(false)
    const [ openAskingNewResult, setOpenAskingNewResult ] = useState(false)
    const [ teamAResult,setTeamAResult ] = useState(0)
    const [ teamBResult,setTeamBResult ] = useState(0)
    const [ matchPoints, setMatchPoints ] = useState({})
    const [ matchResult, setMatchResult ] = useState({})
    const [ teamPoints, setTeamPoints ] = useState({})
    const [ finishedMatch, setFinishedMatch ] = useState({})
    const [ finishedGame, setFinishedGame ] = useState({})

    const [ editing, setEditting ] = useState(false)

    const [ timeoutStartTime, setTimeoutStartTime ] = useState(Date.now())
    const [ timeoutDuration, setTimeoutDuration ] = useState(60)

    //console.log('current-match: ', currentMatch, 'current-game:', currentGame);

    const timeoutRender = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            if (autoMode) {
                handleNext()
            }
            return <span className={classes.timeoutNumberFormat}>TIMEOUT</span>
        } else {
          // Render a countdown
            if (autoMode) {
                return <span className={classes.timeoutNumberFormat}> ({minutes.toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false
                        })}:{seconds.toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false
                        })})</span>;
            }else{
                return (<></>)
            }
        }
    };

    useEffect(() => {
        if(autoMode) {
            setTimeoutStartTime(Date.now())
        }
    }, [])

    useEffect(() => {

    }, [currentResult])

    useEffect(() => {
        console.log("useEffect")
        var setNumberToWin = Math.floor(setNumber/2)
        var mPoint = {}
        var mResult = {}
        var tPoint = {}
        var tAResult = 0
        var tBResult = 0
        var fMatch = {}
        var fGame = {}

        for (let i=0 ; i<matchNumber; i++){
            mPoint[i] = []
            var aGPoint = 0
            var bGPoint = 0
            fGame[i] = {}
            for (let g=0 ; g<setNumber; g++){
                let matchAP = playerAPoint[i] || {}
                let matchBP = playerBPoint[i] || {}
                let a  = matchAP[g] || 0
                let b = matchBP[g] || 0
                fGame[i][g] = false
                //console.log(i,"-", g, ":", a, b)
                if (a>0 || b>0){
                    mPoint[i].push(""+a+"-"+b)
                    if (a>10 || b > 10){
                        if (Math.abs(a - b) >= 2){
                            fGame[i][g] = true
                            if (a > b){
                                aGPoint += 1
                            }else{
                                bGPoint += 1
                            }
                        }
                    }
                }else{
                    mPoint[i].push("")
                }
            }
            if (aGPoint > 0 || bGPoint>0){
                mResult[i] = aGPoint  + "-" + bGPoint
            }else{
                mResult[i] = ""
            }
            
            if (aGPoint > setNumberToWin || bGPoint > setNumberToWin) {
                fMatch[i] = true
                if (aGPoint > bGPoint) {
                    tAResult += 1
                }else{
                    tBResult += 1
                }
            }else{
                fMatch[i] = false
            }
            if (i <= currentMatch){
                tPoint[i] = "" + tAResult + "-" + tBResult;
            }else{
                tPoint[i] = ""
            }
            
        }
        setMatchPoints(mPoint)
        setMatchResult(mResult)
        setTeamPoints(tPoint)
        setTeamAResult(tAResult)
        setTeamBResult(tBResult)
        setFinishedMatch(fMatch)
        setFinishedGame(fGame)
    }, [setNumber, matchNumber, currentMatch, playerAPoint, playerBPoint])

    const handleFinish = (event) => {
        setOpenFinish(true)
    }
    const handleCloseFinish = (really) =>{
        setOpenFinish(false)
        if (!really) {
            setOpenFinish(false)
        }else{
            setGroupStarted(false);
        }
    }

    const handleDoubleTeamConfig = (event) => {
        setOpenDoubleTeam(true)
    }
    const handleDoubleTeamConfigFinish = () =>{
        setOpenDoubleTeam(false)
    }

    const handleChangeName = (event) => {
        setMatchNumberToChange(currentMatch)
        setOpenChangeName(true)
    }

    const handleChangeNameAtMatch = (match) => {
        setMatchNumberToChange(match)
        setOpenChangeName(true)
    }

    const handleChangeNameFinish = () =>{
        setOpenChangeName(false)
    }

    const handleChangeDeleteMatch = (match, game) => {
        setMatchNumberToChange(match)
        setGameNumberToChange(game)
        setOpenDeleteResult(true)
    }

    const handleChangeResult = (match, game, result) => {
        setMatchNumberToChange(match)
        setGameNumberToChange(game)
        setCurrentResult(result)
        setCurrentResultA(parseInt(result.split("-")[0]))
        setNewResultA(parseInt(result.split("-")[0]))
        setCurrentResultB(parseInt(result.split("-")[1]))
        setNewResultB(parseInt(result.split("-")[1]))
        setOpenAskingNewResult(true)
    }

    const handleChangeResultFinish = (confirm) =>{
        setOpenDeleteResult(false)
        setOpenAskingNewResult(false)
        if (confirm && openDeleteResult) {
            let newGame = currentGame - 1
            let newMatch = currentMatch
            if (newGame < 0) {
                newMatch = newMatch - 1
                if (newMatch < 0) {
                    newMatch = 0
                    newGame = 0
                }else{
                    newGame = 0
                    Object.keys(finishedGame[newMatch]).map((g,i) => {
                        if (finishedGame[newMatch][g]) {
                            newGame += 1
                        }
                    })
                    if (newGame > 0){
                        newGame = newGame - 1
                    }
                    //console.log("newGame:", newGame, finishedGame, Object.keys(finishedGame[newMatch]))
                }
            }
            setPlayerAPoint({
                ...playerAPoint,
                [currentMatch]:
                {
                    ...playerAPoint[currentMatch],
                    [currentGame]: 0
                }
            })
            setPlayerBPoint({
                ...playerBPoint,
                [currentMatch]:
                {
                    ...playerBPoint[currentMatch],
                    [currentGame]: 0
                }
            })
            setCurrentGame(newGame)
            setCurrentMatch(newMatch)
        }
        if (confirm && openAskingNewResult) {
            setPlayerAPoint({
                ...playerAPoint,
                [matchNumberToChange]:
                {
                    ...playerAPoint[matchNumberToChange],
                    [gameNumberToChange]: newResultA
                }
            })
            setPlayerBPoint({
                ...playerBPoint,
                [matchNumberToChange]:
                {
                    ...playerBPoint[matchNumberToChange],
                    [gameNumberToChange]: newResultB
                }
            })
        }
    }

    const handleSetTeamAPlayers = (n) => {
        setPlayerAName({
            ...playerAName, 
            [doubleMatchNumber]: n
        })
    }

    const handleSetTeamBPlayers = (n) => {
        setPlayerBName({
            ...playerBName, 
            [doubleMatchNumber]: n
        })
    }

    const handleSetTeamAName = (n) => {
        setTeamAName(n)
    }

    const handleSetTeamBName = (n) => {
        setTeamBName(n)
    }

    const handleSetTeamAPlayerName = (n, match) => {
        setPlayerAName({
            ...playerAName, 
            [match]: n
        })
    }

    const handleSetTeamBPlayerName = (n, match) => {
        setPlayerBName({
            ...playerBName, 
            [match]: n
        })
    }


    const handleAutoModeOn = (on) => {
        if (on) {
            setTimeoutStartTime(Date.now())
        }
        setAutoMode(on)
    }

    const handleEditActivate = () => {
        setEditting(!editing)
    }

    const handleNext = (event) => {
        console.log(currentMatch, currentGame, "match-finished:", finishedMatch[currentMatch], " game-finished:", finishedGame[currentMatch][currentGame])
        if (finishedMatch[currentMatch]) {
            if (currentMatch + 1 < matchNumber) {
                setCurrentMatch(currentMatch + 1)
                setCurrentGame(0)
                setExpediteMode(false)
                setPlayerAPoint({
                    ...playerAPoint,
                    [currentMatch+1]:{
                        0: 0
                    }
                })
                setPlayerBPoint({
                    ...playerBPoint,
                    [currentMatch+1]:{
                        0: 0
                    }
                })
                setPlayerATimeout({
                    ...playerATimeout,
                    [currentMatch+1]: false
                })
                setPlayerBTimeout({
                    ...playerBTimeout,
                    [currentMatch+1]: false
                })
                setPlayerACards({
                    ...playerACards,
                    [currentMatch+1]: 0
                })
                setPlayerBCards({
                    ...playerBCards,
                    [currentMatch+1]: 0
                })
                setStarted(true)
            }else{
                console.log("match ended")
            }
        }else{
            if (finishedGame[currentMatch][currentGame]) {
                setCurrentGame(currentGame+1)
            }
            setStarted(true)
        }
    }



    let h = {backgroundColor: '#ff50c2', color: 'white'}

    return (
        <Grid container className={classes.root} justify="center">
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openFinish}
                onClose={()=>{handleCloseFinish(false)}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 1000,
                }}
            >
                <Fade in={openFinish}>
                    <div className={classes.finishDialog}>
                        <Grid container xs={12} className={classes.row} spacing={0} justify="center">
                            <Grid container xs={12} justify="center">
                                <h2>Confirm the result</h2>
                            </Grid>
                            <Grid item xs={12}>
                                <PlayerNames 
                                        leftPlayerName={teamAName}
                                        leftInputLabel="Team ABC"
                                        rightPlayerName={teamBName}
                                        rightInputLabel="Team XYZ"
                                        leftPlayerColour={teamAColour}
                                        rightPlayerColour={teamBColour}
                                        nameOnly={false}
                                        leftPlayerGamePoint={teamAResult}
                                        rightPlayerGamePoint={teamBResult}
                                    />   
                            </Grid>
                            <Grid container justify="center" spacing={1} className={classes.actionButtonGridFinish}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={()=>{handleCloseFinish(true)}} 
                                        className={classes.actionButton}
                                        endIcon={<CheckIcon />}>
                                        Yes
                                    </Button> 
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={()=>{handleCloseFinish(false)}} 
                                        className={classes.actionButton}
                                        endIcon={<CloseIcon />}>
                                        No
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openDoubleTeam}
                onClose={()=>{handleDoubleTeamConfigFinish()}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 1000,
                }}
            >

                <Fade in={openDoubleTeam}>
                    <div className={classes.finishDialog}>
                        <Grid container xs={12} className={classes.row} spacing={0} justify="center">
                            <Grid container xs={12} justify="center">
                                <h2>Jugadores de los Dobles</h2>
                            </Grid>
                            <Grid item xs={12}>
                                <PlayerNames 
                                    leftPlayerName={playerAName[doubleMatchNumber]}
                                    leftInputLabel="Team ABC"
                                    rightPlayerName={playerBName[doubleMatchNumber]}
                                    rightInputLabel="Team XYZ"
                                    nameOnly={true}
                                    hideTShirt={false}
                                    leftPlayerColour={teamAColour}
                                    rightPlayerColour={teamBColour}
                                    leftInputHandle={(n)=>{handleSetTeamAPlayers(n)}}
                                    rightInputHandle={(n)=>{handleSetTeamBPlayers(n)}}
                                />
                            </Grid>

                            <Grid container justify="center" spacing={1} className={classes.actionButtonGridFinish}>
                                <Button 
                                    variant="contained" color="secondary" onClick={handleDoubleTeamConfigFinish}
                                    startIcon={<CloseIcon />}>
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openChangeName}
                onClose={()=>{handleChangeNameFinish()}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 1000,
                }}
            >

                <Fade in={openChangeName}>
                    <div className={classes.finishDialog}>
                        <Grid container xs={12} className={classes.row} spacing={0} justify="center">
                            <Grid container xs={12} justify="center">
                                <h2>Jugadores</h2>
                            </Grid>
                            <Grid item xs={12}>
                                <PlayerNames 
                                    leftPlayerName={playerAName[matchNumberToChange]}
                                    leftInputLabel="Team ABC"
                                    rightPlayerName={playerBName[matchNumberToChange]}
                                    rightInputLabel="Team XYZ"
                                    nameOnly={true}
                                    hideTShirt={false}
                                    leftPlayerColour={teamAColour}
                                    rightPlayerColour={teamBColour}
                                    leftInputHandle={(n)=>{handleSetTeamAPlayerName(n, matchNumberToChange)}}
                                    rightInputHandle={(n)=>{handleSetTeamBPlayerName(n, matchNumberToChange)}}
                                />
                            </Grid>

                            <Grid container justify="center" spacing={1} className={classes.actionButtonGridFinish}>
                                <Button 
                                    variant="contained" color="secondary" onClick={handleChangeNameFinish}
                                    startIcon={<CloseIcon />}>
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openDeleteResult}
                onClose={()=>{handleChangeResultFinish(false)}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 1000,
                }}
            >

                <Fade in={openDeleteResult}>
                    <div className={classes.finishDialog}>
                        <Grid container xs={12} className={classes.row} spacing={0} justify="center">
                            <Grid container xs={12} justify="center">
                                <h2>Delete the result of Game {parseInt(matchNumberToChange)+1} Set {parseInt(gameNumberToChange)+1}</h2>
                            </Grid>
                

                            <Grid container justifyContent="center" spacing={2} className={classes.actionButtonGridFinish}>
                                <Grid item>
                                    <Button 
                                        variant="contained" color="secondary" onClick={()=>{handleChangeResultFinish(false)}}
                                        startIcon={<CloseIcon />}>
                                        No
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        variant="contained" color="primary" onClick={()=>{handleChangeResultFinish(true)}}
                                        startIcon={<CloseIcon />}>
                                        Yes
                                    </Button>
                                    </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openAskingNewResult}
                onClose={()=>{handleChangeResultFinish(false)}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 1000,
                }}
            >

                <Fade in={openAskingNewResult}>
                    <div className={classes.finishDialog}>
                        <Grid container justifyContent="center" xs={12} className={classes.row} spacing={0} justify="center">
                            <Grid container xs={12} justify="center">
                                <h2>Change the current result: {currentResult}</h2>
                            </Grid>
                            <Grid container xs={12} justifyContent="center" spacing={2}>
                                <Grid item>
                                    <TextField inputProps={{ type: 'number'}} variant="outlined" 
                                        id="playera_new_result" 
                                        label={playerAName[matchNumberToChange]} 
                                        defaultValue={currentResultA}
                                        onChange={(e)=>{setNewResultA(parseInt(e.target.value))}}/>
                                </Grid>
                                <Grid item>VS</Grid>
                                <Grid item>
                                    <TextField inputProps={{ type: 'number'}}  variant="outlined" 
                                        id="playerb_new_result" 
                                        label={playerBName[matchNumberToChange]} 
                                        defaultValue={currentResultB}
                                        onChange={(e)=>{setNewResultB(parseInt(e.target.value))}}/>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" spacing={3} className={classes.actionButtonGridFinish}>
                                <Grid item>
                                    <Button 
                                        variant="contained" color="secondary" onClick={()=>{handleChangeResultFinish(false)}}
                                        startIcon={<CloseIcon />}>
                                        No
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        variant="contained" color="primary" onClick={()=>{handleChangeResultFinish(true)}}
                                        startIcon={<CloseIcon />}>
                                        Yes
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
            <Grid container xs={12} className={classes.pageContent} spacing={0} justify="center" >  
                <PlayerNames 
                        leftPlayerName={teamAName}
                        leftInputLabel="Team ABC"
                        rightPlayerName={teamBName}
                        rightInputLabel="Team XYZ"
                        leftPlayerColour={teamAColour}
                        rightPlayerColour={teamBColour}
                        nameOnly={editing}
                        leftPlayerGamePoint={teamAResult}
                        rightPlayerGamePoint={teamBResult}
                        leftColorHandle={(c)=>{setTeamAColour(c)}}
                        rightColorHandle={(c)=>{setTeamBColour(c)}}
                        leftInputHandle={(n)=>{handleSetTeamAName(n)}}
                        rightInputHandle={(n)=>{handleSetTeamBName(n)}}
                    /> 
            </Grid>

            {Object.keys(matchPoints).length > 0 &&
            <Grid container xs={12} className={classes.pageContent} spacing={0} justify="center" >     
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table} size="small" aria-label="customized table">
                        <colgroup>
                            <col width="3%" />
                            <col width="15%" />
                            <col width="3%" />
                            <col width="15%" />
                            <col width="9%" />
                            <col width="9%" />
                            <col width="9%" />
                            <col width="9%" />
                            <col width="9%" />
                            <col width="9%" />
                            <col width="9%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left"></StyledTableCell>
                                <StyledTableCell align="left">Name</StyledTableCell>
                                <StyledTableCell align="right"></StyledTableCell>
                                <StyledTableCell align="left">Name</StyledTableCell>
                                <StyledTableCell align="center">G1</StyledTableCell>
                                <StyledTableCell align="center">G2</StyledTableCell>
                                <StyledTableCell align="center">G3</StyledTableCell>
                                <StyledTableCell align="center">G4</StyledTableCell>
                                <StyledTableCell align="center">G5</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center">Total</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(playerLabel).map((g,i) => (
                                    <StyledTableRow key={playerLabel[g][0]+playerLabel[g][1]}>
                                        <StyledTableCell align="right">
                                            {editing && 
                                                <Button
                                                    color="black"
                                                    size="small"
                                                    onClick={()=>{handleChangeNameAtMatch(g)}} 
                                                    className={classes.editSmallButton}
                                                    startIcon={<EditIcon />}>
                                                {playerLabel[g][0]}
                                                </Button> 
                                            }
                                            
                                            {!editing && playerLabel[g][0]}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{playerAName[g]}</StyledTableCell>
                                        <StyledTableCell align="right">{playerLabel[g][1]}</StyledTableCell>
                                        <StyledTableCell align="left">{playerBName[g]}</StyledTableCell>
                                        {[0,1,2,3,4].map((s,j) => {
                                            let allowRemove = editing && g == currentMatch && s==currentGame 
                                            let allowEdit = editing && ((g == currentMatch && s<=currentGame) || (g < currentMatch))
                                            return (
                                                <StyledTableCell align="center" 
                                                    style={(g==currentMatch&&currentGame==s)?h:{}}>
                                                        {allowEdit && 
                                                            <div>
                                                                {matchPoints[g][s]}
                                                                <IconButton color="black" size="small" className={classes.editSmallButton} 
                                                                    onClick={()=>{handleChangeResult(g, s,  matchPoints[g][s])}} >
                                                                    <EditIcon />
                                                                </IconButton> 
                                                                {allowRemove && 
                                                                    <IconButton color="black" size="small" className={classes.editSmallButton}
                                                                        onClick={()=>{handleChangeDeleteMatch(g, s)}} >
                                                                        <DeleteIcon />
                                                                    </IconButton> 
                                                                }
                                                            </div>
                                                        }
                                                        

                                                        {!editing && matchPoints[g][s]}

                                                </StyledTableCell>
                                            )}
                                            )
                                        }
                                        
                                        <StyledTableCell align="center">{matchResult[g]}</StyledTableCell>
                                        <StyledTableCell align="center">{teamPoints[g]}</StyledTableCell>
                                    </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>}
            <Grid container xs={12} className={classes.content} justify="center" direction="column">
                <Grid container className={classes.content} spacing={0} justify="center">
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="center" className={classes.startButtonGrid}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={()=>{handleFinish()}} 
                                className={classes.actionButton}
                                endIcon={<StopIcon />}>
                                Final
                            </Button> 
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext} 
                                className={classes.actionButton}
                                endIcon={<NavigateNextIcon />}>
                                {(currentMatch==0 && currentGame==0)? "Start": "Continue"}
                            </Button> 
                            {doubleMatchNumber>=0 &&<Button
                                variant="outlined"
                                color="primary"
                                onClick={()=>{handleDoubleTeamConfig()}} 
                                className={classes.actionButton}
                                endIcon={<PeopleAltIcon />}>
                                Configure Doubles
                            </Button> }
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={()=>{handleChangeName()}} 
                                className={classes.actionButton}
                                endIcon={<PeopleAltIcon />}>
                                Change Players
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={()=>{handleEditActivate()}} 
                                className={classes.actionButton}
                                endIcon={editing?<SaveIcon/>:<EditIcon />}>
                                {editing?"Save":"Edit"}
                            </Button>

                            {cloud &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    variant="outlined"
                                    onClick={()=>handleAutoModeOn(!autoMode)} 
                                    className={classes.actionButton}
                                    endIcon={autoMode?<AirplanemodeActiveIcon />:<AirplanemodeInactiveIcon/>}
                                >
                                    {autoMode?"AutoPilot-On":"AutoPilot-Off"}
                                    <Countdown date={timeoutStartTime + timeoutDuration * 1000} 
                                        renderer={timeoutRender}/>
                                </Button> 
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container xs={12} className={classes.content} justify="center" direction="column">
                <Grid container className={classes.content} spacing={0} justify="center">
                    <Grid item xs={12}>
                        <Grid container justify="center"  className={classes.copyright}>
                        &#xa9; 2023 KandoLab.com
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    );
}

export default GroupOngoing;

const useStyles = makeStyles((theme) => ({
    pageContent:{
        //margin: "0px",
        marginTop: "15px",
    },
    appBar: { 
        background: '#000' 
    },
    content: {
        marginRight: "0px",
        marginLeft: "0px",
        textAlign:"center",
        marginTop: "0px"
    },
    root: {
        flexGrow: 1,
        marginTop: "10px",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    copyright: {
        color: "#BBBBBB",
        paddingTop: "10px"
    },
    setNumberGrid:{
        paddingTop: "20px",
    },
    startButtonGrid: {
        paddingTop: "20px"
    },
    startButton: {
        borderRadius: "20px",
        paddingLeft:"30px",
        paddingRight:"30px",
    },
    editSmallButton: {
    },
    playerTextField: {
        heigh: "15px",
    },
    logoImg:{
        filter: "invert(1)",
        paddingRight:"5px"
    },
    appbar: {
        background : '#000000',
    },
    formControlLabel: {
        fontSize:'12px'
    },
    actionButton: {
        //height: "12vw",
        //width: "40vw",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderRadius: "20px",
        fontWeight: "",
        marginLeft: "1vw",
        marginRight: "1vw",
        marginBottom: "5px",
        [theme.breakpoints.up('sm')]: {
            height: "40px",
            width: "25vw",
            fontSize: "10px",  
            boarderRadius: "20px",
        },
        [theme.breakpoints.down('xs')]: {
            height: "40px",
            width: "40vw",
            fontSize: "10px",
            boarderRadius: "20px",
        },
    },
    tableContainer : {
        marginLeft: "20px",
        marginRight: "20px",
    },
    finishDialog: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #999',
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 0, 3),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    actionButtonGridFinish: {
        marginTop: "20px"
    }
  }));