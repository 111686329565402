import React, { useContext, useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import GameState from './components/GameState';

import ReactNoSleep from 'react-no-sleep';

import '@fontsource/roboto';

import StopIcon from '@material-ui/icons/Stop';

import { Chip, Grid, Modal, Fade, Backdrop, Box, Switch} from '@material-ui/core';
import { GameContext } from '../entities/GameContext'

import PlayerNames from './components/PlayerNames';

import { v4 as uuidv4 } from 'uuid';

import { syncStatus, pushTrace } from '../services/backendapi'

function TeamScoreboardOngoing() {
    const {
        setNumber,
        setStarted,
        matchNumber,setMatchNumber,
        teamAName, 
        teamBName, 
        teamAColour,
        teamBColour,
        playerAName, setPlayerAName, 
        playerBName, setPlayerBName, 
        currentMatch, setCurrentMatch,
        currentGame,setCurrentGame,
        playerAPoint, 
        setPlayerAPoint,
        playerBPoint, 
        setPlayerBPoint,
        serveA,setServeA, 
        playerATimeout, setPlayerATimeout,
        playerBTimeout, setPlayerBTimeout,
        gameType,
        token, 
        cloud,
        uuid, setUuid,
        autoMode, setAutoMode,
        syncPaused, setSyncPaused,
        swapped, setSwapped, 
        toJson,version, setVersion,
    } = useContext(GameContext);

    const classes = useStyles();
    const currentPlayerADiv = useRef(null)
    const currentPlayerBDiv = useRef(null)


    const [sync, setSync] = useState(false)
    const [ leftTeamPoint, setLeftTeamPoint ] = useState(0)
    const [ rightTeamPoint, setRightTeamPoint ] = useState(0)

    const buildTeamResult = (left, right, lastGameForA) => {
        let leftPlayer = {}
        let rightPlayer = {}
        let leftPlayerName = {}
        let rightPlayerName = {}

        for(let i=0; i<=(left+right); i++){
            leftPlayerName[i] = "Player A" + i
            rightPlayerName[i] = "Player B" + i
        }

        for(let i=0; i<(left+right); i++){
            leftPlayer[i] = {}
            rightPlayer[i] = {}
            for (let s=0; s<setNumber; s++){
                if (lastGameForA){
                    if (i<right){
                        leftPlayer[i][s] = i
                        rightPlayer[i][s] = 11                    
                    }else{
                        leftPlayer[i][s] = 11
                        rightPlayer[i][s] = i
                    }
                }else{
                    if (i<left){
                        leftPlayer[i][s] = 11
                        rightPlayer[i][s] = i                    
                    }else{
                        leftPlayer[i][s] = i
                        rightPlayer[i][s] = 11
                    }
                }
            }
        }
        leftPlayer[left+right] = {}
        rightPlayer[left+right] = {}

        setPlayerAPoint(leftPlayer)
        setPlayerBPoint(rightPlayer)
        setPlayerAName(leftPlayerName)
        setPlayerBName(rightPlayerName)
        setCurrentGame(0)
        if (left+right <= 1){
            setCurrentMatch(0)
        }else{
            if (left+right < 19){
                setCurrentMatch(left+right-1)
            }else{
                setCurrentMatch(6)
            }
        }
        
    }

    async function pushStatusToBackend(){
        setSync(true)
    }

    const handleSetLeftTeamPoint = (e, diff) => {
        if ((e.clientY-currentPlayerADiv.current.offsetTop) > currentPlayerADiv.current.offsetHeight*0.5){
            diff = 0-Math.abs(diff)
        }

        const result = leftTeamPoint + diff
        if (result < 0) {
            return
        }
        if (result + rightTeamPoint > (matchNumber)) {
            return
        }
        setLeftTeamPoint(result)
        buildTeamResult(result, rightTeamPoint, true)

        pushStatusToBackend()
    }
    const handleSetRightTeamPoint = (e, diff) => {
        if ((e.clientY-currentPlayerADiv.current.offsetTop) > currentPlayerADiv.current.offsetHeight*0.5){
            diff = 0-Math.abs(diff)
        }

        const result = rightTeamPoint + diff
        if (result < 0) {
            return
        }
        if (result + leftTeamPoint > (matchNumber)) {
            return
        }
        setRightTeamPoint(result)
        buildTeamResult(leftTeamPoint, result, false)

        pushStatusToBackend()
    }
    const handleFinishGame = () => {
        setStarted(false)
    }

    useEffect(() => {
        let id = token['id'] || ""
        if (!cloud) {
            if (uuid === "") {
                let u = uuidv4()
                setUuid(u)
                id = u
            }else{
                id = uuid
            }
        }

        if (id == "") return;

        if (!sync) return;

        console.log("Push to cloud: ", id)

        let status = toJson()
        let message = {}
        message["type"] = "matchStatus"
        message["content"] = status

        async function scopedAsyncFunc(id, message){
            try{
                await syncStatus(id, message)
                await pushTrace(id, "scoreboard", 
                            {
                                "playerA": playerAName[currentMatch] || "", 
                                "playerB": playerBName[currentMatch] || "", 
                                "match": currentMatch, 
                                "game": currentGame,
                                "pointA": playerAPoint[currentMatch] || [], 
                                "pointB": playerBPoint[currentMatch] || []
                            },
                            status)
            }catch(e) {
                console.log("Error happend:", e)
            }
            setSync(false);
        }
        scopedAsyncFunc(id, message);
    }, [sync, uuid, token, cloud, currentGame, currentMatch, playerAName, playerAPoint, playerATimeout, playerBName, playerBPoint, playerBTimeout, serveA, swapped, teamAName, teamBName, teamAColour, teamBColour, version])


    return (
        <Grid container xs={12} className={classes.pageContent} spacing={0} justify="center" direction="column" > 
            <Grid container xs={12} className={classes.content} spacing={10}>
                <PlayerNames 
                        leftPlayerName={teamAName}
                        leftInputLabel="Team ABC"
                        rightPlayerName={teamBName}
                        rightInputLabel="Team XYZ"
                        leftPlayerColour={teamAColour}
                        rightPlayerColour={teamBColour}
                        nameOnly={false}
                        leftPlayerGamePoint={leftTeamPoint}
                        rightPlayerGamePoint={rightTeamPoint}
                    />   
            </Grid>  
            <Grid container spacing={10} xs={12} className={classes.content} justify="center" direction="row">
                <Grid item>
                    <ReactNoSleep>
                        {({ isOn, enable, disable }) => (
                        <div style={{textAlign: "center"}}>
                            <div ref={currentPlayerADiv} 
                                className={classes.gameScore} 
                                onClick={(e)=>{ handleSetLeftTeamPoint(e, 1); if(!isOn){enable()}}}>
                                {leftTeamPoint}
                            </div>
                        </div>)}
                    </ReactNoSleep>
                </Grid>
                <Grid item>
                    <ReactNoSleep>
                        {({ isOn, enable, disable }) => (
                            <div style={{textAlign: "center"}}>
                                <div ref={currentPlayerBDiv} 
                                    className={classes.gameScore} 
                                    onClick={(e)=>{ handleSetRightTeamPoint(e, 1); if(!isOn){enable()}}}>
                                    {rightTeamPoint}
                                </div>
                            </div>)}
                    </ReactNoSleep>
                </Grid>
            </Grid> 
            <Grid container spacing={10} xs={12} className={classes.content} justify="center" direction="row">
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={()=>{handleFinishGame()}} 
                        className={classes.actionButton}
                        endIcon={<StopIcon />}>
                        FINAL
                    </Button> 
                </Grid>
            </Grid>
        </Grid>
    )
}
export default TeamScoreboardOngoing;

const useStyles = makeStyles((theme) => ({
    pageContent: {
        marginTop: "10vh",
    },
    content: {
      marginTop: "20px",
    },
    gameScore: {
        background: "linear-gradient(0deg, #ff50c2 50%, #ff4092 50%)", //"#ff4092",
        color: "white",
        width: "28vw",
        fontSize: "20vw",
        fontWeight: "bold",
        textAlign: "center",
        paddingTop: "3vw",
        paddingBottom: "3vw",
        marginLeft: "2px",
        marginRight: "2px",
        [theme.breakpoints.up('sm')]: {
            width: "120px",
            fontSize: "90px",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        [theme.breakpoints.up('md')]: {
            width: "240px",
            fontSize: "180px",
            paddingTop: "20px",
            paddingBottom: "20px",
        },
    },
    actionButton: {
        //height: "12vw",
        //width: "40vw",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderRadius: "20px",
        fontWeight: "bold",
        [theme.breakpoints.up('sm')]: {
            height: "40px",
            width: "180px",
            boarderRadius: "20px"
        },
    },
}));